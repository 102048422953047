import {
  find,
  map,
  omit,
  path,
  pipe,
  propOr,
  toLower,
  toPairs,
} from 'ramda'
import React, {useEffect, useMemo, useState} from 'react'
import {Icon, Tabs} from 'frontcore'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useSelector} from 'react-redux'
import Configuration from '../../../components/configuration'
import {
  EMPTY_OBJECT,
  EMPTY_STRING,
} from '../../../constants'
import getTable from '../../../api/table/getTable'
import ColumnGraphView from '../../Lineage/ColumnGraphView'
import TableGraphView from '../../Lineage/TableGraphView'
import DataExploration from './DataExploration'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'

const TableDetails = () => {

  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )

  const {name, kind} = chosenItem

  const getTableFuture = useFuture(getTable)

  const [tab, setTab] = useState('schema')

  const details = useSelector(
    path(['data', 'data', 'table', 'response'])
  )

  const extendedDetailsData = pipe(
    omit([
      'parameters',
      'metastoreId',
      'catalogId',
      'catalogName',
      'lineage',
      'schema',
    ]),
    toPairs,
    map((a) =>
      a[0] === 'type'
        ? {
            key: a[0],
            value: a[1],
            Component: Icon,
            componentProps: {icon: a[1], size: 20},
          }
        : {key: a[0], value: a[1]}
    )
  )(details || [])

  const tabsContent = (
    <Tabs
      value={tab}
      onChange={setTab}
      options={[
        {
          id: 'schema',
          children: 'Schema',
        },
        {
          id: 'tableFlow',
          children: 'Lineage',
        },
        {
          id: 'columnFlow',
          children: 'Column Lineage',
        },
        {
          id: 'dataExploration',
          children: 'Data Exploration',
        },
      ]}
    />
  )

  const type = pipe(
    find(({key}) => key === 'type'),
    propOr(EMPTY_STRING, 'value'),
    toLower
  )(extendedDetailsData)

  useEffect(() => {
    fork(() => {})(() => {})(
      getTableFuture({
        params: [
          {
            key: 'metaStoreId',
            value: chosenItem.metaStoreId,
          },
          {key: 'catalogId', value: chosenItem.catalogId},
          {key: 'databaseId', value: chosenItem.databaseId},
          {key: 'id', value: chosenItem.id},
        ],
      })
    )
  }, [chosenItem])

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'table'} />}
      name={name}
      id={type}
      pending={false}
      data={extendedDetailsData}
    />
  )

  const schema = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'schema'),
        toPairs,
        map((a) => ({
          name: a?.[0],
          type: a?.[1]?.type,
          comment: a?.[1]?.comment,
          nullable: a?.[1]?.nullable ? 'true' : 'false',
        }))
      )(details),
    [details]
  )

  const listContent = (
    <>
      {tab === 'schema' && (
        <Configuration
          schema={[
            {
              id: 'name',
              label: 'Name',
              copy: true,
            },
            {
              id: 'type',
              label: 'Type',
            },
            {
              id: 'comment',
              label: 'Comment',
            },
            {
              id: 'nullable',
              label: 'nullable',
            },
          ]}
          data={schema}
        />
      )}
      {tab === 'tableFlow' && (
        <TableGraphView
          nodeType={'dataset'}
          namespace={details?.lineage?.namespace}
          nodeId={details?.lineage?.table}
        />
      )}
      {tab === 'columnFlow' && (
        <ColumnGraphView
          nodeType={'dataset'}
          namespace={details?.lineage?.namespace}
          nodeId={details?.lineage?.table}
        />
      )}
      {tab === 'dataExploration' && <DataExploration />}
    </>
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={tabsContent}
      bottomContent={listContent}
    />
  )
}

export default TableDetails
