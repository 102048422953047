import { memo, useCallback, useMemo } from 'react'
import { Button } from 'frontcore'
import ToolTip from '../../../components/toolTip'
import {useDispatch, useSelector} from 'react-redux'
import {isEmpty, path} from 'ramda'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import useFuture from '../../../hooks/useFuture'
import createSQLStatement from '../../../api/sql/createSQLStatement'
import saveSQLStatement from '../../../api/sql/saveSQLStatement'
import {fork} from 'fluture'
import {sidebarQueryMakeRemote} from '../../../reducers/sql/sidebar'

const EditorSaveQuery = () => {
  const chosenQuery = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryDetails = useMemo(() => {
    return queries.find(({ id }) => id === chosenQuery)
  }, [chosenQuery, queries])

  const { createNotification } = useNotificationContext()
  const handleError = useCallback(({ message }) => {
    createNotification({
      title: 'Error',
      message,
      variant: 'error',
      autoHide: true,
    })
  }, [])

  const dispatch = useDispatch()

  const chosenEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const maxRows = useSelector(path(['sqlViewer', 'editor', 'maxRows']))

  const createSQLStatementFuture = useFuture(createSQLStatement)
  const createQuery = useCallback(() => {
    const future = createSQLStatementFuture({
      name: queryDetails.name,
      statement: queryDetails.statement,
      engine: chosenEngine,
      maxRows,
    })
    fork(handleError)(remoteDetails => {
      createNotification({
        title: 'Success',
        message: 'Saved query on server',
        variant: 'success',
        autoHide: true,
      })
      dispatch(sidebarQueryMakeRemote({
        localId: queryDetails.id,
        remote: remoteDetails,
      }))
    })(future)
  }, [queryDetails, chosenEngine, maxRows])

  const saveSQLStatementFuture = useFuture(saveSQLStatement)
  const saveQuery = useCallback(() => {
    if (queryDetails.local) return
    const future = saveSQLStatementFuture({
      params: [{ key: 'queryId', value: queryDetails.id }],
      ...queryDetails,
      engine: chosenEngine,
      maxRows,
    })
    fork(handleError)(() => {
      createNotification({
        title: 'Success',
        message: 'Updated query on the server',
        variant: 'success',
        autoHide: true,
      })
    })(future)
  }, [queryDetails, chosenEngine, maxRows])

  const handleGeneralSave = useCallback(() => {
    /* REMOVED FOR QUALITY OF LIFE
    if (!queryDetails['name'] || !queryDetails['statement']) {
      createNotification({
        title: 'Error',
        message: 'To save query, both "name" and "statement" should be present. ' +
          'You must not leave them empty.',
        variant: 'error',
        autoHide: true,
      })
      return
    }
    */
    if (queryDetails.local) {
      createQuery()
    } else {
      saveQuery()
    }
  }, [queryDetails])

  return (
    <ToolTip
      config={{position: 'bottom_right'}}
      content={
        <div style={{padding: 8}}>
          Query will be saved to remote database
        </div>
      }
    >
      <Button onClick={handleGeneralSave} variant="outlined">
        Save
      </Button>
    </ToolTip>
  )
}

export default memo(EditorSaveQuery)
