import styled from 'styled-components';
import {Button, Icon, Input} from 'frontcore';
import {useState} from 'react';

const Root = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const TitleContainer = styled.div`
  color: #3E4651;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
`;

const MenuHeader = () => {

  const [value,setValue] = useState('')
  return (
    <Root>
      <TopContainer>
        <TitleContainer>Resources</TitleContainer>
        <div style={{display: 'flex', gap: 8}}>
          <Button Component={Icon}
                  componentProps={{icon: 'question', size: 16}}
                  square
                  justifyContent={'center'}
                  variant={'text'}
                  disabled
                  size={'small'} />
          <Button Component={Icon}
                  componentProps={{icon: 'more', size: 16}}
                  square
                  justifyContent={'center'}
                  variant={'text'}
                  disabled
                  size={'small'} />
        </div>
      </TopContainer>

      <Input
        placeHolder={'Search'}
        fullWidth
        value={value}
        disabled
        onChange={setValue}
      />
    </Root>
  );
};

export default MenuHeader;






