import {
  map,
  find,
  append,
  reject,
  equals,
  length,
  pipe,
  prop,
  uniq,
  add,
  times,
  join,
  sortWith,
  ascend,
  path,
} from 'ramda'
import {CheckBox} from 'frontcore'

import {EMPTY_ARRAY} from '../../constants'
import styled from 'styled-components'


const Root = styled.div`
    display: grid;
    background:  ${({theme}) => theme.palette['surface-secondary']};
    grid-template-columns: ${({$gridTemplateColumns}) =>
            $gridTemplateColumns};
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-color: ${({theme}) => theme.palette.line};
    font-family: Roboto, serif;
    color: ${({theme}) => theme.palette.neutral[500]};
    font-size: 12px;
    overflow: hidden;
    > div {
        padding-left: 5px;
        padding-right: 5px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid black;
        border-color: ${({theme}) => theme.palette.line};
    }
`

const PermissionsSelect = ({
  value = EMPTY_ARRAY,
  onChange,
  options,
}) => {


  const extendedOptions = pipe(
    sortWith([ascend(path(['resource']))])
  )(options)

  const titleKeys = pipe(
    map(prop('action')),
    uniq
  )(extendedOptions)

  const resourceKeys = pipe(
    map(prop('resource')),
    uniq
  )(extendedOptions)

  const gridTemplateColumns = pipe(
    length,
    add(1),
    times(() => 'auto'),
    join(' ')
  )(titleKeys)

  return (
    <Root $gridTemplateColumns={gridTemplateColumns}>
      <div></div>
      {map(
        (a) => (
          <div
            style={{
              fontWeight: 500,
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {a}
          </div>
        ),
        titleKeys
      )}
      {map(
        (kind) => (
          <>
            <div style={{fontWeight: 500}}>{kind}</div>
            {map((title) => {
              const result = find(
                (option) =>
                  option.resource === kind &&
                  option.action === title,
                options
              )
              return result ? (
                <div>
                  <CheckBox
                    value={find((a) => a === result, value)}
                    onChange={(v) => {
                      if (v) {
                        onChange(append(result, value))
                      } else {
                        onChange(
                          reject(equals(result), value)
                        )
                      }
                    }}
                  />
                </div>
              ) : (
                <div></div>
              )
            }, titleKeys)}
          </>
        ),
        resourceKeys
      )}
    </Root>
  )
}

export default PermissionsSelect
