import styled, {css} from 'styled-components'

const sizeSmallCss = css`
    width: 20px;
    height: 20px;
`

const sizeMediumCss = css`
    width: 30px;
    height: 30px;
`

const sizeLargeCss = css`
    width: 50px;
    height: 50px;
    font-size: 32px;
`

const sizeCssMap = {
  small: sizeSmallCss,
  medium: sizeMediumCss,
  large: sizeLargeCss
}

const Root = styled.div`
    background: ${({$color}) => $color};
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    ${({$size}) => sizeCssMap[$size]}
`

const UserIcon = ({color, size = 'medium', children}) => {

   return <Root $color={color} $size={size}>{children}</Root>
}

export default UserIcon;