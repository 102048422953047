import styled, {css} from 'styled-components'

const errorCss = css`
  background: #ffebee;
  color: #f44336;
  border-color: #ef9a9a;
`

const successCss = css`
    background: rgba(76, 175, 80, 0.1);
    color: #4caf50;
    border-color: #4caf50;
`

const warningCss = css`
  background: #fff3e0;
  color: #ff9800;
  border-color: #ffcc80;
`

const informationCss = css`
    background: rgba(63, 81, 181, 0.01);
    color: #3f51b5;
    border-color: #3f51b5;
`

const defaultCss = css`
    background: rgba(96, 125, 139, 0.1);
    color: #607d8b;
    border-color: #607d8b;
`

const variantMapCss = {
  default: defaultCss,
  success: successCss,
  warning: warningCss,
  information: informationCss,
  error: errorCss,
  red: errorCss,
  yellow: warningCss,
  green: successCss,
}

const Root = styled.div`
  font-size: 10px;
  border: 1px solid black;
  font-weight: 500;
  padding: 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  ${({$variant}) => variantMapCss[$variant?.toLowerCase()]}
`

const StatusIndicator = ({
  variant = 'default',
  value = '',
  onClick,
}) => <Root onClick={onClick} $variant={variant}>{value}</Root>

export default StatusIndicator
