import ListLayout from '../../../../../components/layouts/listLayout'
import Filters from '../../../filters'
import RequestsList from '../../../Components/RequestsList'
import React from 'react'
import {useListRefreshFuture} from '../../../hooks'
import getRequests from '../../../../../api/workloads/requests/getRequests'
import {EMPTY_OBJECT} from '../../../../../constants'
import schema from './schema'
import usePending from '../../../../../hooks/usePending'

const JobDetailsRequestsList = ({jobId}) => {
  const pending = usePending(
    ['workloads', 'data'],
    ['requestsDetails', 'killJob', 'deleteJob']
  )

  const listHandler = useListRefreshFuture(
      getRequests,
    'workloads',
    'requests',
    {
      page: 0,
      size: 10,
      sort: {
        startTime: 'desc',
      },
      filter: EMPTY_OBJECT,
    },
    {jobIds: [jobId]}
  )

  return (
    <ListLayout
      filtersNode={
        <Filters
          name={'requestsDetails'}
          schema={schema}
          pending={pending}
        />
      }
      contentNode={
        <RequestsList
          listHandler={listHandler}
          borderTop={true}
          pending={pending}
        />
      }
    />
  )
}

export default JobDetailsRequestsList
