import {
  Button,
  CheckBox,
  Input,
  Select,
  SliderV3,
} from 'frontcore'
import {any, equals, isEmpty, map, path} from 'ramda'
import RadioButtons from '../../../../components/RadioButtons'
import Parameters from '../../../../components/parameters'
import {onSubmitSparkParamsFn} from '../../../../helpers'
import memorySettings from '../../memorySettings'
import resourcesSchema from '../../resourcesSchema'
import requiredValidator from "../../../../validators/requiredValidator";

const schema = (data, options) => {
  const {pending, navigate, cluster} = options
  return {
    fields: [
      {
        section: 'General',
        id: 'name',
        name: 'Name',
        description: 'Name of the service.',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'clusterName',
        name: 'Cluster',
        defaultValue:
          cluster || path(['clusters', 0, 'name'], options),
        description:
          'Cluster on which group and jobs will be launched.',
        Component: Select,
        componentProps: {
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          disabled: !!cluster,
          fullWidth: true,
          options: map(
            (cluster) => cluster.name,
            options?.clusters || []
          ),
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'description',
        name: 'Description',
        description: 'Group description.',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'kind',
        defaultValue: 'job',
        name: 'Type',
        description: 'Type of the group.',
        Component: RadioButtons,
        componentProps: {
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'job', label: 'Job'},
            {id: 'code', label: 'Code'},
          ],
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'language',
        name: 'Language',
        description: 'Language of the group.',
        defaultValue: 'scala',
        Component: RadioButtons,
        componentProps: {
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'scala', label: 'Scala'},
            {id: 'python', label: 'Python'},
          ],
        },
      },
      {
        section: 'General',
        id: 'scale',
        defaultValue: 1,
        name: 'Scale',
        description:
          'Number of replicas handled by loadbalancer.',
        Component: SliderV3,
        componentProps: {
          skeleton: pending,
          min: 1,
          max: 12,
          disabled: data.kind === 'code',
          fullWidth: true,
          size: 'small',
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'autoPause',
        defaultValue: false,
        name: 'Auto Pause',
        description:
          'Automatically scales the group to zero after the inactivity time specified in the configuration',
        Component: CheckBox,
        componentProps: {
          skeleton: pending,
          min: 1,
          max: 12,
          disabled: data.kind === 'code',
          fullWidth: true,
          size: 'small',
        },
        validators: [],
      },
      {
        section: 'Configuration',
        id: 'jobConfig',
        name: 'Parameters',
        description: 'Key/Value configuration of the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [
          (value) => {
            const result = map(
              (a) => isEmpty(a.value),
              value || []
            )
            return any(equals(true), result)
              ? 'This field is required'
              : ''
          },
        ],
      },
      {
        section: 'Configuration',
        id: 'tags',
        name: 'Tags',
        description: 'Label application with a tag.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [
          (value) => {
            const result = map(
              ({key}) => isEmpty(key),
              value || []
            )
            return any(equals(true), result)
              ? 'This field is required'
              : ''
          },
        ],
      },
      ...resourcesSchema(data, options),
      ...memorySettings(data, options),
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
