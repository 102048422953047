import {fork} from 'fluture'
import {memo, useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {EMPTY_FUNCTION} from '../../../../constants'
import List from '../../../../components/list'
import schema from './schema'
import HeaderComponent from '../../../../components/list/headerComponent'
import ItemComponent from '../../../../components/list/itemComponent'
import {useNavigate} from 'react-router-dom'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import ScheduleTooltip from './ScheduleTooltip'
import ScheduleSingleButtons from '../../Components/ScheduleSingleButtons'

const Root = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const idParams = (scheduleId) =>
  Object.freeze({
    params: [{key: 'scheduleId', value: scheduleId}],
  })

const SchedulesList = ({pending = false, listHandler}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    selected,
    setSelected,
  } = listHandler

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    fork(({message}) => {
      createNotification({
        title: 'Failed to fetch Schedules',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const extendedData = useMemo(() => {
    return data
      .filter(Boolean)
      .map((data) => Object.freeze({data}))
  }, [data])

  const navigate = useNavigate()
  const handleOnDetails = useCallback(
    ({id}) => {
      navigate(`/workloads/details/schedule/${id}`)
    },
    [navigate]
  )

  return (
    <Root>
      <List
        schema={schema}
        data={extendedData}
        HeaderComponent={HeaderComponent}
        headerComponentProps={{
          buttonsWidth: '128px',
          sort,
          onSort: handleOnSort,
          skeleton: pending,
        }}
        selected={selected}
        onSelect={setSelected}
        ItemComponent={ItemComponent}
        itemComponentProps={{
          toFn: ({id}) =>
            `/workloads/details/schedule/${id}`,
          buttonsWidth: '128px',
          skeleton: pending,
          onDetails: handleOnDetails,
          ToolTipComponent: ScheduleTooltip,
          typeComponentProps: {
            type: 'schedule',
          },
          ButtonsComponent: ScheduleSingleButtons,
          buttonsComponentProps: {
            pending,
            texts: false,
            getFuture,
            redirect: false,
          },
        }}
        pagerProps={{
          size,
          page,
          totalPages,
          onChangeSize: handleOnSizeChange,
          onChangePage: handleOnPageChange,
        }}
      />
    </Root>
  )
}

export default memo(SchedulesList)
