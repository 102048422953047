import {
  filter,
  isNil,
  map,
  path,
  pathOr,
  pipe,
  toPairs,
} from 'ramda'
import React, {useEffect} from 'react'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useSelector} from 'react-redux'
import DetailsInfo from '../../WorkloadsView/detailsInfo'
import {EMPTY_ARRAY} from '../../../constants'
import getCatalog from '../../../api/table/getCatalog'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'

const CatalogDetails = () => {
  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )

  const {name,kind} = chosenItem

  const getCatalogFuture = useFuture(getCatalog)

  const details = useSelector(
    pathOr(EMPTY_ARRAY, [
      'data',
      'data',
      'catalog',
      'response',
    ])
  )

  const extendedDetailsData = pipe(
    toPairs,
    filter(([_, value]) => !isNil(value)),
    map((a) => ({key: a[0], value: a[1]}))
  )(details)

  useEffect(() => {
    fork(() => {})(() => {})(
      getCatalogFuture({
        params: [
          {
            key: 'metaStoreId',
            value: chosenItem.metaStoreId,
          },
          {key: 'catalogId', value: chosenItem.id},
        ],
      })
    )
  }, [chosenItem])

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'catalog'} />}
      name={name}
      id={kind}
      pending={false}
      data={extendedDetailsData}
    />
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={null}
      filtersContent={null}
      bottomContent={null}
    />
  )
}

export default CatalogDetails
