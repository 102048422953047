import React, {useState} from 'react'
import {Tabs} from 'frontcore'
import Line from '../../../../../components/line'
import DataSetFields from '../DataSetFields'
import DataSetVersions from '../DatasetVersions'
import Raw from '../Raw'

const TabComponent = ({active, type, value, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
  </div>
)
const DataSetDetails = () => {
  const [tab, setTab] = useState('fields')
  return (
    <div style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
      <div style={{padding: '8px 8px 0 8px'}}>
        <Tabs
          value={tab}
          onChange={setTab}
          options={[
            {
              id: 'fields',
              Component: TabComponent,
              componentProps: {
                label: 'Fields',
                value: 'fields',
              },
            },
            {
              id: 'versions',
              Component: TabComponent,
              componentProps: {
                label: 'Versions History',
                value: 'versions',
              },
            },
            {
              id: 'raw',
              Component: TabComponent,
              componentProps: {
                label: 'Raw',
                value: 'raw',
              },
            },
          ]}
        />
      </div>
      <Line />
      <div
        style={{
          overflow: 'hidden',
          flex: 1,
        }}
      >
        {tab === 'fields' && <DataSetFields/>}
        {tab === 'raw' && <Raw/>}
        {tab === 'versions' && <DataSetVersions />}
      </div>
    </div>
  )
}

export default DataSetDetails
