import {sql} from '@codemirror/lang-sql'
import {linter} from '@codemirror/lint'
import CodeMirror from '@uiw/react-codemirror'
import {vim} from '@replit/codemirror-vim'
import {Resizer} from 'frontcore'
import {isNil, path, pathOr, prop} from 'ramda'
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import useDebounce from '../../../hooks/useDebounce'
import {Parser} from 'node-sql-parser'
import {EMPTY_ARRAY} from '../../../constants'
import EditorFontController from './EditorFontController'
import {sidebarMutateQuery} from '../../../reducers/sql/sidebar'
import {editorSetStatementError} from '../../../reducers/sql/editor'
import {SparkSQL} from './sqlSupport'
import {StandardSQL} from '@codemirror/lang-sql'

const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const FontModifier = styled.div`
  .cm-content, .cm-gutters {
    font-size: ${({ $fontSize }) => $fontSize}px;
  }
`

const EXTENSION_MAPPING = Object.freeze({
  SPARK_SQL: SparkSQL,
})

const EditorArea = () => {
  const dispatch = useDispatch()
  
  const queryId = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryCode = useMemo(() => {
    const query = queries.find(({ id }) => id === queryId)
    return query?.statement
  }, [queryId, queries])
  const setQueryCode = useCallback((newCode) => {
    dispatch(sidebarMutateQuery({
      id: queryId,
      statement: newCode,
    }))
  }, [queryId])

  const vimEnabled = useSelector(path(['sqlViewer', 'editor', 'vimEnabled']))
  const linterEnabled = useSelector(path(['sqlViewer', 'editor', 'linterEnabled']))

  const tabError = useSelector(path(['sqlViewer', 'editor', 'statementErrors', queryId]))
  const linterDiagnostics = useMemo(() => {
    if (!linterEnabled) {
      return EMPTY_ARRAY
    }
    const message = prop('message', tabError)
    const location = prop('location', tabError)
    if (isNil(message) || isNil(location)) {
      return EMPTY_ARRAY
    }
    const {start, end} = location
    return [
      {
        from: start.offset,
        to: end.offset,
        severity: 'error',
        message,
      },
    ]
  }, [tabError, linterEnabled])

  const tables = useSelector(pathOr([], ['sqlViewer', 'sidebar', 'data', 'tables', 'response', 'rows']))
  const schemas = useSelector(pathOr({}, ['sqlViewer', 'sidebar', 'schemas']))
  const sqlSchema = useMemo(() => {
    return Object.fromEntries(tables.map(([_, tableName]) => {
      if (!schemas[tableName]) {
        return [tableName, []]
      }
      return [tableName, schemas[tableName].map(({name}) => name)]
    }))
  }, [tables, schemas])

  const currentEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const extensions = useMemo(() => {
    const dialect = EXTENSION_MAPPING[currentEngine] ?? StandardSQL
    return [
      sql({
        upperCaseKeywords: true, 
        dialect,
        schema: sqlSchema,
      }),
      linter(() => linterDiagnostics),
      ...(vimEnabled ? [vim({status: true})] : []),
    ]
  }, [ vimEnabled, linterDiagnostics, currentEngine, sqlSchema])

  const debouncedTabCode = useDebounce(queryCode, 500)
  const parser = useRef(new Parser())
  useEffect(() => {
    if (!linterEnabled) {
      dispatch(editorSetStatementError({ id: queryId, error: null }))
      return
    }
    try {
      parser.current.astify(debouncedTabCode)
      dispatch(editorSetStatementError({ id: queryId, error: null }))
    } catch (error) {
      dispatch(editorSetStatementError({ id: queryId, error }))
    }
  }, [debouncedTabCode, linterEnabled])

  const fontSize = useSelector(path(['sqlViewer', 'editor', 'fontSize']))

  return (
    <Root>
      <EditorFontController />
      <Resizer>
        {({width, height}) => {
          const editorWidth = `${width}px`
          const editorHeight = `${height}px`

          return (
            <FontModifier $fontSize={fontSize}>
              <CodeMirror
                value={queryCode}
                onChange={setQueryCode}
                width={editorWidth}
                height={editorHeight}
                extensions={extensions}
              />
            </FontModifier>
          )
        }}
      </Resizer>
    </Root>
  )
}

export default memo(EditorArea)
