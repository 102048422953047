import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {path} from 'ramda'
import ExplorerBreadcrumbs from './components/ExplorerBreadcrumbs'
import StorageDetails from '../CatalogDetails'
import ClusterDetails from '../MetaStoreDetails'
import DatabaseDetails from '../DatabaseDetails'
import React from 'react'
import TableDetails from '../TableDetails'

const Root = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`

const componentMap = {
  metastore: ClusterDetails,
  catalog: StorageDetails,
  database: DatabaseDetails,
  table: TableDetails,
}

const ExplorerWindow = () => {
  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )

  const type = chosenItem?.kind

  const Component = componentMap[type]

  return (
    <Root>
      <div
        style={{
          padding: '16px 16px 0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
        }}
      >
        <ExplorerBreadcrumbs />
      </div>
      {Component && <Component />}
    </Root>
  )
}
export default ExplorerWindow
