

import DropDownInput from '../../../../components/dropDownInput'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {fork} from 'fluture'
import {isNotEmpty, mapWithKey} from '../../../../helpers'
import {useDispatch, useSelector} from 'react-redux'
import {isEmpty, pathOr, pipe, propOr, take} from 'ramda'
import {setDetails} from '../../../../reducers/workloads'
import {LetterMiniature} from '../../../../components/miniatures'
import getElements from '../../../../api/getElements'
import useDebounce from '../../../../hooks/useDebounce'
import {Button, Icon} from 'frontcore'
import {EMPTY_STRING} from '../../../../constants'
import NoItemsFound from '../../../../components/NoResultFound'
import StyledLink from '../../../../components/StyledLink'
import RButton from '../../../../components/RButton'

const typeMap = {
  cluster: 'cluster',
  group: 'service',
  job: 'job',
  request: 'request',
  schedule: 'schedule',
}
export const ButtonWrapper = (a) => {
  const {type, name, id} = a

  const end = type === 'cluster' ? '/' + name : ''

  return (
    <StyledLink
      to={'/workloads/details/' + type + '/' + id + end}
    >
      <RButton
        name={name || id}
        IconComponent={LetterMiniature}
        iconComponentProps={{type}}
        type={typeMap[type]}
      />
    </StyledLink>
  )
}

const ContentComponent = ({closeFn, data, pending}) => {
  const clusters = () =>
    mapWithKey(
      (props, index) => (
        <ButtonWrapper
          key={index}
          {...props}
          pending={pending}
        />
      ),
      data
    )

  const result = isEmpty(data) ? (
    <NoItemsFound />
  ) : (
    <div
      style={{
        width: 212,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {clusters({closeFn})}
    </div>
  )

  return (
    <div
      style={{
        userSelect: pending ? 'none' : 'auto',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        padding: 4,
      }}
    >
      {result}
    </div>
  )
}

const SecurityGlobalSearch = () => {
  const [search, setSearch] = useState(EMPTY_STRING)

  const getElementsFuture = useFuture(getElements)

  const data = useSelector(
    pathOr({}, ['workloads', 'data', 'elements'])
  )

  const pending = useMemo(
    () => propOr(false, 'pending', data),
    [data]
  )

  const dispatch = useDispatch()

  const handleOnDetails = useCallback((value) => {
    dispatch(setDetails(value))
  }, [])

  const extendedData = useMemo(
    () => pipe(propOr(false, 'response'), take(20))(data),
    [data]
  )

  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    fork(() => {})(() => {})(
      getElementsFuture({
        search: debouncedSearch,
        size: 5,
        page: 0,
      })
    )
  }, [debouncedSearch])

  const open = isNotEmpty(search)

  return (
    <DropDownInput
      inputProps={{
        topLabelProps: {
          children: 'Global Search',
        },
        placeHolder: 'Global Search',
        StartComponent: Icon,
        startComponentProps: {size: 16, icon: 'search'},
        EndComponent: Button,
        paddingRight: '4px',
        endComponentProps: {
          square: true,
          size: 'small',
          onClick: (event) => {
            event.stopPropagation()
            setSearch(EMPTY_STRING)
          },
          variant: 'text',
          justifyContent: 'center',
          tabIndex: -1,
          Component: Icon,
          componentProps: {
            size: 16,
            icon: 'trash',
          },
        },
      }}
      value={search}
      onChange={setSearch}
      ContentComponent={ContentComponent}
      contentProps={{
        data: extendedData,
        onDetails: handleOnDetails,
        pending,
      }}
    />
  )
}

export default SecurityGlobalSearch
