import React, {useMemo} from 'react'
import styled from 'styled-components'
import {pathOr, pipe, propOr} from 'ramda'
import {EMPTY_OBJECT} from '../../constants'
import SingleMetricBarChart from '../../components/charts/singleMericBarChart'
import {useSelector} from 'react-redux'
import {formatBytes} from '../../helpers'
import formatNumber from '../../helpers/formatNumber'

const Root = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding-top: 8px;
  flex-direction: column;
`

const ClusterMetrics = ({name}) => {

  const data = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'clustersMetrics', 'response', 'metrics', name, 'metric']))
  const error = useSelector(pathOr(EMPTY_OBJECT, ['workloads', 'data', 'clustersMetrics', 'response', 'metrics', name, 'error']))

  const {usedCpu, availableCpu, requestedCpu,availableMemory, usedMemory, requestedMemory } = data

  return (
    <Root>
      <SingleMetricBarChart
        label={'CPU'}
        values={[
          {
            key: 'USED',
            label: formatNumber(usedCpu, 2),
            value: formatNumber(usedCpu, 2),
          },
          {
            key: 'REQUESTED',
            label: formatNumber(requestedCpu, 2),
            value: formatNumber(requestedCpu, 2),
          },
        ]}
        max={{
          kay: 'AVAILABLE',
          label: availableCpu,
          value: availableCpu,
        }}
      />
      <SingleMetricBarChart
        label={'CPU'}
        values={[
          {
            key: 'USED',
            label: formatBytes(usedMemory),
            value: usedMemory
          },
          {
            key: 'REQUESTED',
            label: formatBytes(requestedMemory),
            value: requestedMemory
          },
        ]}
        max={{
          kay: 'AVAILABLE',
          label: formatBytes(availableMemory),
          value: availableMemory,
        }}
      />

    </Root>
  )
}

export default ClusterMetrics
