import colors from "./colors";

const theme = {
  shape: {
    borderRadius: "8px",
    borderRound: "50%",
  },
  palette: {
    ...colors,
    white: "#fff",
    black: "#000",
    transparent: 'transparent',
    yellow: {
      100: "#FFF6C3",
      200: "#FFED9B",
      300: "#FFE16A",
      400: "#FFD545",
      500: "#FFC107",
      600: "#CC9B06",
      700: "#997405",
      800: "#806104",
      900: "#4C3B08",
    },

    red: {
      100: "#FAE1E3",
      200: "#F7B9BE",
      300: "#FF8B95",
      400: "#ED626D",
      500: "#E52535",
      600: "#CD2F3D",
      700: "#A11C28",
      800: "#80141D",
      900: "#4C080D",
    },

    green: {
      100: "#DAF2E7",
      200: "#C9F5E0",
      300: "#96EBC3",
      400: "#51DB9B",
      500: "#14CC76",
      600: "#10A35F",
      700: "#0E8F53",
      800: "#0A663B",
      900: "#004C29",
    },


    //ok
    blue: {
      100: "#E5ECFF",
      200: "#BECFFF",
      300: "#8DACFF",
      400: "#5280FF",
      500: "#2660FF",
      600: "#1650EF",
      700: "#0134C0",
      800: "#022481",
      900: "#000E33",
    },

    //okCCD2DB
    default: {
      0: "#FFFFFF",
      100: "#EEF0F3",
      200: "#CCD2DB",
      300: "#ADB6C3",
      400: "#828E9F",
      500: "#687587",
      600: "#535E6C",
      700: "#3E4651",
      800: "#2A2F36",
      900: "#0A0C0E",
    },

    neutral2: {
      0: "#FFFFFF",
      100: "#EEF0F3",
      200: "#CCD2DB",
      300: "#ADB6C3",
      400: "#828E9F",
      500: "#687587",
      600: "#535E6C",
      700: "#3E4651",
      800: "#2A2F36",
      900: "#0A0C0E",
    },

    primary: {
      0: "#E5ECFF",
      100: "#E5ECFF",
      200: "#BECFFF",
      300: "#8DACFF",
      400: "#5280FF",
      500: "#2660FF",
      600: "#1650EF",
      700: "#0134C0",
      800: "#022481",
      900: "#000E33",
    },

    secondary: {
      0: "#F2E8FE",
      100: "#EDDBFF",
      200: "#BBA2FF",
      300: "#8A6AFF",
      400: "#7156F5",
      500: "#694FE7",
      600: "#5A44E0",
      700: "#5133C7",
      800: "#4025AD",
      900: "#2A1A8D",
    },

    neutral1: {
      0: '#FAFAFA',
      50: '#f5f6f8',
      100: '#EEF0F3',
      200: '#CCD2DB',
      300: '#ADB6C3',
      400: '#828E9F',
      500: '#687587',
      600: '#535E6C',
      700: '#3E4651',
      800: '#2A2F36',
      900: "#14171c",
      1900: '#0A0C0E'
    },

    neutral: {
      0: '#f8f8f9',
      50: '#e9ebee',
      100: '#dbdee3',
      200: '#bec4cd',
      300: '#a1aab7',
      400: '#8590a1',
      500: '#697789',
      600: '#535e6c',
      700: '#3d454f',
      800: '#272c32',
      900: "#111316",
      950: '#060607'
    },
  },
};



export const darkTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    streamColor: "#5081FF",
    batchColor: "#8A6AFF",
    connectionColor: "#000C4F",
    background: "#1a1a1a",
    fontColor: "white",
    navBarBackground: "#000C4F",
    panelFontColor: "#c9c3ef",
    panelHover: "#333333",
    panelDragBorder: "#404040",
    panelTitle: "#668cff",
    nodeText: "#EFF2F8",
    edgeColor: "#EFF2F8",
    flowBackground: "#353e5f",
    flowGrid: "#333333",
    projectsBackground: "#262626",
    projectsHover: "#333333",
    projectsActive: "#262626",
    projectsBorders: "#404040",
    flowThumbNailHover: "#353E5F",
    appBackground: "#000",
    button: {
      primary: {
        main: colors.primaryDark[500],
        hover: colors.primaryDark[600],
        active: colors.primaryDark[700],
      },
      default: {
        main: colors.defaultLight[0],
        hover: colors.defaultLight[100],
        active: colors.defaultLight[300],
        defaultFont: colors.defaultLight[500],
      },
      //toDo: update dark mode of textSecondary (secondary Button in Figma) when ready in Figma, below only test colors:
      textSecondary: {
        main: colors.defaultDark[900],
        hover: colors.defaultDark[600],
        active: colors.defaultLight[700],
      },
    },
  },
};

export default theme;
