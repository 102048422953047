import {Select, SliderV3} from 'frontcore'
import {
  match,
  propOr,
  toPairs,
  pipe,
  prop,
  find,
} from 'ramda'

const unitsMap = {
  m: 'MB',
  g: 'GB',
  t: 'TB',
}

const MemorySlider = ({value = '0g', onChange}) => {
  const parsedValue = match(/(\d+)(\w)/, value)
  const numberValue = propOr(0, 1, parsedValue)
  const unitValue = propOr(0, 2, parsedValue)
  return (
    <div style={{display: 'flex', gap: 16}}>
      <SliderV3
        min={0}
        max={1024}
        value={numberValue}
        onChange={(value) => {
          const result = value + unitValue
          onChange(result)
        }}
      />
      <Select
        label={'Unit'}
        value={unitsMap[unitValue]}
        onChange={(value) => {
          const unit = pipe(
            toPairs,
            find((a) => a[1] === value),
            prop(0)
          )(unitsMap)
          const result = numberValue + unit
          onChange(result)
        }}
        options={['MB', 'GB', 'TB']}
      />
    </div>
  )
}

export default MemorySlider
