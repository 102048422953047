import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect} from 'react'
import DetailsHeader from '../../../../components/DetailsHeader'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {fork} from 'fluture'
import getUser from '../../../../api/security/getUser'
import {useParams} from 'react-router-dom'
import {join, pathOr, toUpper} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import ReactJson from 'react-json-view'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import UserIcon from '../../../../components/Icons/UserIcon'
import {Tabs} from 'frontcore'
import GroupSingleButtons from '../../Components/GroupButtons/GroupSingleButtons'
import UserSingleButtons from '../../Components/UserButtons/UserSingleButtons'
import {CountMiniature} from '../../../../components/miniatures'
import getActivity from '../../../../api/security/getActivity'
import LabelIcon from '../../../../components/Icons/LabelIcon'

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

const SecurityActivityDetailsView = () => {
  const getActivityFuture = useFuture(getActivity)

  const {activityId, clusterName} = useParams()

  const dispatch = useDispatch()

  const activity = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'activity',
      'response',
    ])
  )

  const {
    id,
    userId,
    username,
    groups,

    method,
    endpoint,
    timestamp,
  } = activity

  useEffect(() => {
    if (activityId) {
      fork(() => {})(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getActivityFuture({
          params: [{key: 'id', value: activityId}],
        })
      )
    }
  }, [activityId])

  const topContent = (
    <DetailsHeader
      icon={
        <LabelIcon color={'#444444'} size={'large'}>
          {method}
        </LabelIcon>
      }
      name={username}
      id={userId}
      pending={false}
      data={[
        {
          key: 'id',
          value: id,
        },
        {
          key: 'method',
          value: method,
        },
        {
          key: 'endpoint',
          value: endpoint,
        },
        {
          key: 'groups',
          value: groups,
        },
        {
          key: 'timestamp',
          value: timestamp,
          Component: DateCellRow,
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={'permissions'}
        options={[
          {
            id: 'permissions',
            Component: TabComponent,
            componentProps: {
              label: 'Permissions',
            },
          },
        ]}
      />
      <UserSingleButtons
        texts={true}
        getFuture={getActivityFuture({
          params: [{key: 'id', value: activityId}],
        })}
        pending={false}
        data={activity}
        redirect={false}
      />
    </div>
  )
  const bottomContent = <ReactJson src={activity} />

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityActivityDetailsView
