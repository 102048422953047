import {Icon, NavigationButton} from 'frontcore'
import React from 'react'
import styled from 'styled-components'
import {createTransitions, toBoolean} from '../../helpers'
import {
  ILUM_AIRFLOW_ENABLED,
  ILUM_DATA_ENABLED,
  ILUM_JUPYTER_ENABLED,
  ILUM_MLFLOW_ENABLED,
  ILUM_SUPERSET_ENABLED,
} from '../../helpers/runtimeEnv'
import {Link, useLocation} from 'react-router-dom'
import {path, startsWith} from 'ramda'
import {useSelector} from 'react-redux'
import UserDropdown from '../userDropdown'
import {HORIZONTAL} from '../../constants'
import VerticalLayout from './VerticalLayout'
import HorizontalLayout from './HorizontalLayout'

const isExpanded = (width, foldable = true) =>
  foldable ? width > 200 : true

const subMenuButtonProps = {
  fullWidth: true,
  rounding: false,
  size: 'large',
}

const menuButtonProps = {
  size: 'large',
  fullWidth: true,
}

const Text = styled.div`
  color: ${({theme}) => theme.palette.text.tertiary};
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 16.5px */
    letter-spacing: 0.4px;
`

const MenuButton = ({
  to,
  icon,
  configuration,
  label,
  buttonProps,
  activeLabel,
  ContextContentComponent,
  expanded = true,
  textWrap = true,
}) => {
  const {pathname} = useLocation()

  return (
    <StyledLink to={to}>
      <NavigationButton
        configuration={configuration}
        ContextContentComponent={ContextContentComponent}
        active={startsWith(activeLabel || to, pathname)}
        buttonProps={{
          StartComponent: Icon,
          startComponentProps: {icon, size: 24},
          justifyContent: expanded
            ? 'flex-start'
            : 'center',
          square: !expanded,
          ...buttonProps,
        }}
      >
        {expanded && label}
      </NavigationButton>
      {!expanded &&
        <div style={{display: 'flex', justifyContent: 'center', width: 48}}>
        <Text
          style={{
            textDecoration: 'none',
            fontSize: 11,
            fontFamily: 'Roboto',
            textAlign: 'center',
             textWrap: textWrap ? 'auto' : 'nowrap'
          }}
        >
          {label}
        </Text></div>}

    </StyledLink>
  )
}

const Buttons = ({expanded = true, configuration ,textWrap = true}) => {
  const {pathname} = useLocation()
  return (
    <>
      <MenuButton
        to={'/workloads/list/services'}
        icon={'home'}
        label={'Workloads'}
        expanded={expanded}
        buttonProps={menuButtonProps}
        activeLabel={'/workloads'}
        configuration={configuration}
        textWrap={textWrap}
        ContextContentComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MenuButton
              to={'/workloads/list/clusters'}
              label={'Clusters'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/services'}
              label={'Services'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/schedules'}
              label={'Schedules'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/jobs'}
              label={'Jobs'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/requests'}
              label={'Requests'}
              buttonProps={subMenuButtonProps}
            />
          </div>
        )}
      />
      <MenuButton
        to={'/history'}
        icon={'history'}
        label={'History Server'}
        expanded={expanded}
        textWrap={textWrap}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        to={'/data'}
        icon={'table-2'}
        label={'Table Explorer'}
        expanded={expanded}
        textWrap={textWrap}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        to={'/file-explorer'}
        icon={'directory-2'}
        label={'File Explorer'}
        expanded={expanded}
        textWrap={textWrap}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        to={'/sql'}
        icon={'database'}
        label={'SQL'}
        expanded={expanded}
        textWrap={textWrap}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        to={'/ilum-lineage/list/jobs'}
        icon={'Vector'}
        label={'Lineage'}
        expanded={expanded}
        buttonProps={menuButtonProps}
        textWrap={textWrap}
        activeLabel={'/ilum-lineage'}
      />
      <MenuButton
        to={'/documentation'}
        icon={'info'}
        label={'User Guides'}
        expanded={expanded}
        textWrap={textWrap}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        to={'/security/list/users'}
        icon={'shield-lock'}
        label={'Security'}
        expanded={expanded}
        activeLabel={'/security'}
        buttonProps={menuButtonProps}
        configuration={configuration}
        textWrap={textWrap}
        ContextContentComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MenuButton
              to={'/security/list/users'}
              label={'Users'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/security/list/groups'}
              label={'Groups'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/security/list/roles'}
              label={'Roles'}
              buttonProps={subMenuButtonProps}
            />
          </div>
        )}
      />
      <MenuButton
        to={'/settings/tooltip'}
        icon={'gear'}
        label={'Settings'}
        textWrap={textWrap}
        buttonProps={menuButtonProps}
        expanded={expanded}
        activeLabel={'/settings'}
        configuration={configuration}
        ContextContentComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MenuButton
              to={'/settings/tooltip'}
              label={'Tooltip'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/settings/theme'}
              label={'Theme'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/settings/license'}
              label={'License'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/settings/metrics'}
              label={'Metrics'}
              buttonProps={subMenuButtonProps}
            />
          </div>
        )}
      />

      <MenuButton
        to={'/modules'}
        icon={'modules'}
        label={'Modules'}
        textWrap={textWrap}
        configuration={configuration}
        buttonProps={menuButtonProps}
        expanded={expanded}
        ContextContentComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {toBoolean(ILUM_DATA_ENABLED) && (
              <MenuButton
                to={'/minio'}
                label={'Minio'}
                icon={'minio'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_JUPYTER_ENABLED) && (
              <MenuButton
                to={'/notebook'}
                label={'Jupyter'}
                icon={'jupyter'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_AIRFLOW_ENABLED) && (
              <MenuButton
                to={'/airflow'}
                label={'Airflow'}
                icon={'apacheairflow'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_MLFLOW_ENABLED) && (
              <MenuButton
                to={'/mlflow'}
                label={'MLflow'}
                icon={'mlflow'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_SUPERSET_ENABLED) && (
              <MenuButton
                to={'/superset'}
                label={'Superset'}
                icon={'superset'}
                buttonProps={subMenuButtonProps}
              />
            )}
            <MenuButton
              to={'/modules'}
              label={'Overview'}
              icon={'project'}
              buttonProps={subMenuButtonProps}
            />
          </div>
        )}
      />
    </>
  )
}

const StyledLink = styled(Link)`
  outline: none;
  text-decoration: none;
`

const Root = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({theme}) =>
    theme.palette['surface-primary']};
  display: flex;
  padding: 16px 8px;
  box-sizing: border-box;
`

const LogoRoot = styled.div`

    display: flex;
    align-items: center;
  width: ${({expanded}) => (expanded ? '200px' : '30px')};
  transition: ${createTransitions(['width'])};
`

const logoMap = {
  dark: '/logoWhite.svg',
  light: '/logo.svg',
}

const Logo = ({expanded = true}) => {
  const mode = useSelector(
    path(['settings', 'settings', 'darkMode'])
  )
  return (
    <LogoRoot expanded={expanded}>
      <img
        src={logoMap[mode ? 'dark' : 'light']}
        alt="ilum"
      />
    </LogoRoot>
  )
}

const Navbar = ({orientation}) => {
  const aaa = (
    <div
      style={{
        opacity: 0.6,
        fontSize: 12,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {isExpanded(200)
        ? 'Copyright ©2024 Ilum ver. 6.2.0'
        : 'v6.2.0'}
    </div>
  )

  return orientation === HORIZONTAL ? (
    <VerticalLayout
      LogoComponent={Logo}
      ButtonsComponent={Buttons}
      UserComponent={UserDropdown}
    />
  ) : (
    <HorizontalLayout
      LogoComponent={Logo}
      ButtonsComponent={Buttons}
      UserComponent={UserDropdown}
    />
  )
}

export default Navbar
