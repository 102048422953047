import {useListRefreshFuture} from '../../../WorkloadsView/hooks'
import ListLayout from '../../../../components/layouts/listLayout'
import Filters from '../../../WorkloadsView/filters'
import {Button, Icon} from 'frontcore'
import React from 'react'
import RolesList from './RolesList'
import getRoles from '../../../../api/security/getRoles'
import {useNavigate} from 'react-router-dom'
import usePending from "../../../../hooks/usePending";

const SecurityRolesListView = () => {
    const listHandler = useListRefreshFuture(
        getRoles,
        'security',
        'roles',
        {
            page: 0,
            size: 10,
            sort: {
                createdTime: 'desc',
            },
            filter: {
                groupStates: ['ACTIVE'],
            },
        }
    )

    const navigate = useNavigate()

    const pending = usePending(
        ['security', 'data'],
        ['roles', 'createRole', 'deleteRole', 'enableRole', 'disableRole']
    )
    return (
        <ListLayout
            filtersNode={
                <Filters
                    name={'groups'}
                    schema={[]}
                    pending={pending}
                />
            }
            createButtonNode={
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => navigate('/security/create/role')}
                        EndComponent={Icon}
                        endComponentProps={{
                            icon: 'plus',
                            size: 18,
                            color: 'white',
                        }}
                    >
                        Add Role
                    </Button>
                </div>
            }
            contentNode={
                <RolesList
                    pending={pending}
                    listHandler={listHandler}
                />
            }
        />
    )
}

export default SecurityRolesListView
