// global libraries
import { createSlice } from '@reduxjs/toolkit'

// global imports
import { EMPTY_STRING } from '../../../constants'

//==========SLICE==========

const initialState = {
  treeSearch:     EMPTY_STRING,
  explorerSearch: EMPTY_STRING
}

const filters = createSlice({
  name: 'filters',
  initialState, 
  reducers: {
    setTreeSearch: (state, { payload }) => {
      state.treeSearch = payload
    },
    setExplorerSearch: (state, { payload }) => {
      state.explorerSearch = payload
    }
  }
})

export default filters.reducer
export const {
  setTreeSearch,
  setExplorerSearch
} = filters.actions
