import FadeTransition from '../transitions/fadeTransition'
import React, {useCallback} from 'react'
import styled from 'styled-components'
import {Button, Icon} from 'frontcore'
import ToolTip from '../toolTip'
import ToolTipContentLayout from '../layouts/toolTip/toolTipContentLayout'

const HeaderButtonToolTipLayout = ({
  name,
  description,
  ToolTipComponent,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        maxWidth: 640,
      }}
    >
      <div
        style={{
          fontSize: 12,
          fontWeight: 700,
          textTransform: 'uppercase',
        }}
      >
        {name}
      </div>
      {ToolTipComponent && <ToolTipComponent />}
      {description}
    </div>
  )
}

const ArrowContainer = styled.div`
  fill: ${({theme, active}) =>
    active
      ? theme.palette.blue[600]
      : theme.palette.text.tertiary} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({transform}) => transform};
`

const DirectionAscending = () => (
  <ArrowContainer
    transform={'rotate(270deg)'}
    active={true}
  >
    <Icon icon={'arrow-right-contained'} size={16} />
  </ArrowContainer>
)

const DirectionDescending = () => (
  <ArrowContainer
    transform={'rotate(270deg) scaleX(-1)'}
    active={true}
  >
    <Icon icon={'arrow-right-contained'} size={16} />
  </ArrowContainer>
)

const DirectionNone = () => (
  <ArrowContainer transform={'rotate(0deg)'} active={false}>
    <Icon icon={'arrow-sort-contained'} size={16} />
  </ArrowContainer>
)

const HeaderLabel = styled.div`
  color: ${({theme}) => theme.palette.text.tertiary};
  font-size: 12px;
  font-style: normal;
  font-family: Inter, serif;
  font-weight: 600;
  letter-spacing: 0.25px;
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const HeaderButton = ({
  label,
  size,
  sort,
  value,
  description = '',
  Component,

  ToolTipComponent,
  onSortChange,
  enableSort = true,
  skeleton = false,
  lockable,
}) => {
  const handleOnClick = useCallback(() => {
    if (enableSort) {
      if (sort === 'asc') {
        onSortChange({name: value, direction: 'desc'})
      } else if (sort === 'desc') {
        onSortChange({name: value})
      } else {
        onSortChange({name: value, direction: 'asc'})
      }
    }
  }, [enableSort, sort, value, onSortChange])

  return (
    <ToolTip
      lockable={lockable}
      content={
        <ToolTipContentLayout>
          <HeaderButtonToolTipLayout
            name={label}
            description={description}
            ToolTipComponent={ToolTipComponent}
            lockable={lockable}
          />
        </ToolTipContentLayout>
      }
    >
      <Button
        variant={'text'}
        tabIndex={-1}
        fullWidth
        onClick={handleOnClick}
        skeleton={skeleton}
        Component={() => (
          <div
            style={{
              margin: '0 -8px',
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            <HeaderLabel>{label}</HeaderLabel>
            {enableSort && (
              <div>
                <FadeTransition trigger={sort}>
                  {enableSort && sort === 'asc' && (
                    <DirectionAscending />
                  )}
                  {enableSort && sort === 'desc' && (
                    <DirectionDescending />
                  )}
                  {enableSort &&
                    sort !== 'asc' &&
                    sort !== 'desc' && <DirectionNone />}
                </FadeTransition>
              </div>
            )}
          </div>
        )}
      />
    </ToolTip>
  )
}

export default HeaderButton
