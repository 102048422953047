import {EMPTY_ARRAY} from '../../constants';
import {append, assocPath, remove} from 'ramda';
import {createTransitions, mapWithKey} from '../../helpers';
import styled from 'styled-components';
import {useCallback, useMemo} from 'react';
import Row from './row';
import {Button} from 'frontcore';

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`

const LabelBottom = styled.div`
  position: absolute;
  color: ${({theme}) => `${theme.palette.red[500]}`};
  padding: 0;
  left: 0;
  height: 24px;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 10px;
  white-space: nowrap;
  transform: translate(0, 100%);
  transition: ${createTransitions([
    'top',
    'left',
    'right',
    'bottom',
    'border-color',
    'font-size',
    'padding',
    'color',
  ])};
`;

const Parameters = (props) => {
  const {
    value = EMPTY_ARRAY,
    skeleton = false,
    onChange,
    errors,
    fields = [
      {label: 'Key', name: 'key'},
      {label: 'Value', name: 'value'},
    ],
  } = props;

  const parameters = value;

  const extendedValue = useMemo(() => value, [value]);

  const handleOnChange = useCallback(({index, name, value}) => {
    const result = assocPath([index, name], value, extendedValue);
    onChange(result);
  }, [extendedValue, onChange]);

  const handleOnDelete = useCallback(({index}) => {
    const result = remove(index, 1, extendedValue);
    onChange(result);
  }, [extendedValue, onChange]);

  const handleOnAdd = useCallback(() =>
      onChange(append({key: '', value: ''}, extendedValue)),
    [extendedValue, onChange]);

  return (
    <Root>
      <LabelBottom> {errors?.[0]}</LabelBottom>
      {mapWithKey(
        ({key, value}, index) => (
          <Row
            key={index}
            index={index}
            value={{key, value}}
            skeleton={skeleton}
            fields={fields}
            parameters={parameters}
            onChange={handleOnChange}
            onDelete={handleOnDelete}
          />
        ),
        extendedValue,
      )}
      <Button
        skeleton={skeleton}
        tabIndex={-1}
        color={'primary'}
        onClick={handleOnAdd}
      >
        Add Parameter
      </Button>
    </Root>
  );
};

export default Parameters;
