import {Input} from 'frontcore'
import ContextMenu from '../contextMenu'
import React, {useCallback, useRef, useState} from 'react'
import styled from 'styled-components'

const Root = styled.div`
  width: fit-content;
`

const DropDownInput = ({
  value,
  onChange,
  ContentComponent,
  contentProps,
  inputProps,
}) => {
  const ref = useRef();

  const [open, setOpen] = useState(false);

  const handleOnClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setOpen(false);
  }, []);


  return (
    <Root>
      <div onClick={handleOnClick}>
        <Input
          {...inputProps}
          value={value}
          onChange={onChange}
          ref={ref}
        />
      </div>
      <ContextMenu
        open={open}
        ref={ref}
        onBlur={handleOnBlur}
        topPadding={4}
        bottomPadding={4}
      >
        {ContentComponent && (
          <ContentComponent {...contentProps} closeFn={() => setOpen(false)} />
        )}
      </ContextMenu>
    </Root>
  );
};

export default DropDownInput;
