import NameCell from '../../nameCell'
import {CountMiniature} from '../../../../components/miniatures'

const schema = [
  {
    value: 'name',
    label: 'Name',
    size: 3,
    Component: NameCell,
  },
  {
    value: 'id',
    label: 'Id',
    size: 3,
    Component: NameCell,
  },
  {
    value: 'type',
    label: 'Type',
    size: 1,
    enableSort: false,
  },
  {
    value: 'groupsCount',
    label: 'Services',
    size: 1,
    enableSort: false,
    Component: CountMiniature,
    componentProps: {
      type: 'service',
    },
  },
  {
    value: 'jobsCount',
    label: 'Jobs',
    size: 1,
    enableSort: false,
    Component: CountMiniature,
    componentProps: {
      type: 'job',
    },
  },
  {
    value: 'requestsCount',
    label: 'Requests',
    size: 1,
    enableSort: false,
    Component: CountMiniature,
    componentProps: {
      type: 'request',
    },
  },
]

export default schema