import styled from 'styled-components'
import {Link} from 'react-router-dom'

const StyledLink = styled(Link)`
    outline: none;
    text-decoration: none;
    width: 100%;
    color: inherit;
    cursor: inherit;
    pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
`

export default StyledLink
