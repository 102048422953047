import {ILUM_MLFLOW_PATH} from "../../helpers/runtimeEnv";

const MlflowView = () => {
    return (

        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_MLFLOW_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    );
};

MlflowView.dispayName = "MlflowView";

export default MlflowView;

