import {isNil, path, pathOr} from "ramda"
import {memo, useCallback, useMemo} from "react"
import {useSelector} from "react-redux"
import {EMPTY_FUNCTION, EMPTY_OBJECT} from "../../../constants"
import {Icon, Select} from "frontcore"
import useFuture from "../../../hooks/useFuture"
import {useNotificationContext} from "../../../hooks/useNotificationsContext"
import {fork} from "fluture"
import getSQLEngines from "../../../api/sql/getSQLEngines"
import styled from 'styled-components'
import createSQLEngine from "../../../api/sql/createSQLEngine"
import deleteSQLEngine from "../../../api/sql/deleteSQLEngine"
import EngineState from "../EngineState"

const EngineActionContainer = styled.div`
  padding: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.1s background-color ease-in-out;
  &:hover {
    background-color: #ddd;
  }
`

const EngineActions = Object.freeze({
  START: 'START',
  STOP: 'STOP',
})

const EngineActionsLabels = Object.freeze({
  [EngineActions.START]: 'Start engine',
  [EngineActions.STOP]: 'Stop engine',
})

const EngineActionsIcons = Object.freeze({
  [EngineActions.START]: 'arrow-right-contained',
  [EngineActions.STOP]: 'close',
})

const EditorEngineStatus = () => {
  const chosenCluster = useSelector(
    pathOr(EMPTY_OBJECT, [
      'sqlViewer',
      'sidebar',
      'chosenCluster',
    ])
  )

  const {createNotification} = useNotificationContext()
  const getSQLEnginesFuture = useFuture(getSQLEngines)
  const handleRefreshEngines = useCallback(() => {
    if (isNil(chosenCluster)) return
    const future = getSQLEnginesFuture({
      params: [{key: 'clusterId', value: chosenCluster.id}],
    })
    fork(({message}) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(future)
  }, [chosenCluster])

  const sqlEngines = useSelector(
    pathOr(
      [],
      [
        'sqlViewer',
        'editor',
        'data',
        'liveEngines',
        'response',
      ]
    )
  )

  const chosenEngineType = useSelector(
    path(['sqlViewer', 'editor', 'chosenEngine'])
  )

  const chosenEngineInfo = useMemo(() => {
    return sqlEngines.find(engine => {
      return engine.type === chosenEngineType
    })
  }, [sqlEngines, chosenEngineType])

  const engineActions = useMemo(() => {
    switch (chosenEngineInfo?.state) {
      case EngineState.RUNNING:
        return [ EngineActions.STOP ]
      case EngineState.STARTING:
        return [ EngineActions.STOP ]
      default:
        return [ EngineActions.START ]
    }
  }, [chosenEngineInfo])

  return (
    <div onClick={handleRefreshEngines}>
      <Select
        label='Engine Status'
        ItemComponent={EngineAction}
        value={chosenEngineInfo?.state ?? 'OFF'}
        options={engineActions}
      />
    </div>
  )
}

export default memo(EditorEngineStatus)

const EngineAction = memo(({ value, closeFn }) => {
  const chosenClusterId = useSelector(
    pathOr(EMPTY_OBJECT, [
      'sqlViewer',
      'sidebar',
      'chosenCluster',
      'id',
    ])
  )

  const sqlEngines = useSelector(
    pathOr(
      [],
      [
        'sqlViewer',
        'editor',
        'data',
        'liveEngines',
        'response',
      ]
    )
  )

  const chosenEngineType = useSelector(
    path(['sqlViewer', 'editor', 'chosenEngine'])
  )

  const chosenEngineInfo = useMemo(() => {
    return sqlEngines.find(engine => {
      return engine.type === chosenEngineType
    })
  }, [sqlEngines, chosenEngineType])

  const { createNotification } = useNotificationContext()
  const createSQLEngineFuture = useFuture(createSQLEngine)
  const deleteSQLEngineFuture = useFuture(deleteSQLEngine)

  const getSQLEnginesFuture = useFuture(getSQLEngines)
  const refreshEngines = useCallback(() => {
    if (isNil(chosenClusterId)) return
    const future = getSQLEnginesFuture({
      params: [{ key: 'clusterId', value: chosenClusterId, }]
    })
    fork(({message}) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(future)
  }, [chosenClusterId])

  const createEngine = useCallback(() => {
    if (isNil(chosenClusterId)) return
    const future = createSQLEngineFuture({
      params: [{ key: 'clusterId', value: chosenClusterId }],
      async: true,
    })
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to start SQL engine',
        variant: 'error',
        autoHide: true,
      })
    })(refreshEngines)(future)
  }, [chosenClusterId, refreshEngines])

  const deleteEngine = useCallback(() => {
    if (isNil(chosenEngineInfo)) return
    const future = deleteSQLEngineFuture({
      params: [{ key: 'engineId', value: chosenEngineInfo.id }],
    })
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to stop SQL engine',
        variant: 'error',
        autoHide: true,
      })
    })(refreshEngines)(future)
  }, [chosenEngineInfo, refreshEngines])

  const handleBadgeClick = useCallback(() => {
    switch (value) {
      case EngineActions.START:
        createEngine()
        closeFn()
        break
      case EngineActions.STOP:
        deleteEngine()
        closeFn()
        break
    }
  }, [value, closeFn, createEngine, deleteEngine])

  return (
    <EngineActionContainer onClick={handleBadgeClick}>
      <span>{EngineActionsLabels[value]}</span>
      <Icon
        icon={EngineActionsIcons[value]}
        size={18}
      />
    </EngineActionContainer>
  )
})
