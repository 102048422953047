import React, {useEffect, useMemo, useRef} from 'react'
import * as d3 from 'd3'
import {
  append,
  assoc, clamp,
  flatten,
  includes, isEmpty,
  keys,
  map,
  max,
  min,
  pipe,
  prop,
  reduce, reject,
  toPairs,
  values,
} from 'ramda'
import styled, {useTheme} from 'styled-components'
import {Resizer} from 'frontcore'
import NoResultFound from '../../NoResultFound'

const NoDataText = styled.div`
    font-family: Inter, serif;
    font-size: 16px;
    color: ${({theme}) => theme.palette.neutral[300]};

`

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  fill: black;
  stroke: black;
  color: black;

  text {
    color: ${({theme}) => theme.palette.text.tertiary};
    stroke: transparent;
    font-family: Inter, serif;
    font-size: 11px;
  }

  path {
    stroke: ${({theme}) => theme.palette.line};
  }

  line {
    stroke: ${({theme}) => theme.palette.line};
  }
`

const closeTooltipFn = () => {
  const tooltip = d3.select('.tooltip')
  tooltip.transition().duration(200).style('opacity', 0)
}

const openTooltipFn = (template) => (event, data) => {
  const tooltip = d3.select('.tooltip')
  tooltip.transition().duration(200).style('opacity', 1)
  tooltip
    .html(template(data))
    .style('top', event.pageY + 'px')
    .style('left', event.pageX + 'px')
}

const withResize = (Component) => (props) => (
  <Resizer>
    {({width, height}) => (
      <Component width={width} height={height} {...props} />
    )}
  </Resizer>
)

const marginTop = 32
const marginBottom = 32
const marginLeft = 1
const marginRight = 1

const TimeLineChart = ({
  data,
  width,
  height,
  onClick = () => {},
  onOver = () => {},
  onOut = () => {},
  options = {},
  toolTipTemplate = () => '',
}) => {
  const colors = options?.colors || []
  const toolTipTemplates = options?.toolTipTemplates || []
  const ref = useRef()
  let svg = d3.select(ref.current)

  const xValues = pipe(
    values,
    flatten,
    map(({x1, x2}) => [x1, x2]),
    flatten
  )(data)

  const minX = reduce(min, Infinity, xValues)
  const maxX = reduce(max, -Infinity, xValues)

  const yRange = [marginTop, height - marginBottom]

  const yScale = d3
    .scaleBand()
    .domain(keys(data))
    .range(yRange)

  const extendedData = useMemo(
    () =>
      pipe(
        reject(isEmpty),
        map(
          pipe(
            reduce((acc, value) => {
              let result = 0
              const colliding = map(({x1, x2, y}) => {
                if (
                  (value.x1 > x1 && value.x1 < x2) ||
                  (value.x2 > x1 && value.x2 < x2) ||
                  (value.x1 < x1 && value.x2 > x2)
                ) {
                  return y
                }
              }, acc)
              while (includes(result, colliding)) {
                result += 1
              }
              return append({...value, y: result}, acc)
            }, []),
            (data) => {
              const yValues = map(prop('y'), data)

              const scale = d3
                .scaleBand()
                .domain(yValues)
                .range([0, yScale.bandwidth()])
                .padding(0.5)

              const result = map(
                assoc('scale', scale),
                data
              )

              return {data: result, scale}
            }
          )
        ),
        toPairs,
        map(([key, value]) => ({label: key, ...value}))
      )(data),
    [data]
  )

  const xDomain = [minX, maxX]

  const yDomain = map(({label}) => label, extendedData)

  const xRange = [marginLeft, width - marginRight]

  const xScale = d3
    .scaleLinear()
    .domain(xDomain)
    .range(xRange)

  const xAxisTime = d3
    .axisBottom(xScale)
    .tickFormat(d3.timeFormat('%H:%M:%S'))

  const xAxisDate = d3
    .axisTop(xScale)
    .tickFormat(d3.timeFormat('%d/%m/%Y'))
    .ticks(4)

  const graphXAxisBottom = svg
    .select('.x-axis-top')
    .call(xAxisTime)
    .attr(
      'transform',
      `translate(${0}, ${height - marginBottom})`
    )

  const graphXAxisTop = svg
    .select('.x-axis-bottom')
    .call(xAxisDate)
    .attr('transform', `translate(${0}, ${marginTop})`)

  const handleZoom = ({transform}) => {
    graph.attr(
      'transform',
      `translate(${transform.x}) scale(${transform.k},1)`
    )
    graph.attr(
      'transform',
      `translate(${transform.x}) scale(${transform.k},1)`
    )
    graphXAxisTop.call(
      xAxisDate.scale(transform.rescaleX(xScale))
    )
    graphXAxisBottom.call(
      xAxisTime.scale(transform.rescaleX(xScale))
    )
  }

  const {palette} = useTheme()

  const zoom = d3.zoom().on('zoom', handleZoom)

  const graph = svg.call(zoom).select('.graph')

  useEffect(() => {
    d3.select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('font-size', '12px')
      .style('border-radius', '3px')
      .style('border-style', 'solid')
      .style('border-width', '1px')
      .style('border-color', palette.line)
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('color', palette.text.tertiary)
      .style('padding', '12px')
      .style(
        'background-color',
        palette['surface-secondary']
      )
  }, [])

  const colorFn = (data, index, array) => {
    const active = data?.active
    const parentIndex =
      array[index]?.parentNode?.getAttribute('index')
    return active
      ? colors[parentIndex].default
      : palette.neutral[300]
  }

  const transformFn = ({label}) =>
    `translate(0,${yScale(label)})`

  const indexFn = (data, index) => index

  const xFn = ({x1}) => xScale(x1)

  const yFn = ({y, scale}) => scale(y)
  const widthFn = ({x1, x2}) => xScale(x2) - xScale(x1)

  const heightFn = ({scale}) => scale.bandwidth()

  const joinArgs = [
    (enter) =>
      enter
        .append('rect')
        .on('mouseover', mouseOverFn)
        .on('mouseout', mouseOutFn)
        .on('mousedown', mouseDownFn)
        .on('click', clickFn),
    (update) => update,
  ]

  const clickFn = function (event, data) {
    const parentIndex = d3
      .select(this)
      .node()
      ?.parentNode?.getAttribute('index')
    onClick({index: parentIndex, data})
  }

  const mouseOverFn = function (event, data) {
    const parentIndex = d3
      .select(this)
      .node()
      ?.parentNode.getAttribute('index')

    const active = data?.active

    const color = active
      ? colors[parentIndex].hover
      : palette.neutral[400]

    d3.select(this).attr('fill', color)
    const toolTipTemplate = toolTipTemplates[parentIndex]
    onOver({index: parentIndex, data})
    openTooltipFn(toolTipTemplate)(event, data)
  }

  const mouseOutFn = function (event, data) {
    const parentIndex = d3
      .select(this)
      .node()
      ?.parentNode.getAttribute('index')
    const active = data?.active
    const color = active
      ? colors[parentIndex].default
      : palette.neutral[300]
    d3.select(this).attr('fill', color)
    closeTooltipFn()
    onOut()
  }

  const mouseDownFn = function (event, data) {
    const parentIndex = d3
      .select(this)
      .node()
      ?.parentNode.getAttribute('index')
    const active = data?.active
    const color = active
      ? colors[parentIndex].active
      : palette.neutral[500]

    d3.select(this).attr('fill', color)
  }

  const childrenFn = (children) =>
    children
      .transition()
      .duration(150)
      .attr('fill', colorFn)
      .attr('y', yFn)
      .attr('x', xFn)
      .attr('width', widthFn)
      .attr('height', heightFn)
      .attr('stroke',colorFn)
      .attr('stroke-width', 1)
      .attr('vector-effect', 'non-scaling-stroke')

  graph
    .selectAll('g')
    .data(extendedData)
    .join(
      (enter) =>
        enter
          .append('g')
          .attr('transform', transformFn)
          .attr('index', indexFn)
          .selectAll('rect')
          .data(({data}) => data)
          .join(...joinArgs)
          .call(childrenFn),

      (update) =>
        update
          .attr('transform', transformFn)
          .selectAll('rect')
          .data(({data}) => data)
          .join(...joinArgs)
          .on('mouseover', mouseOverFn)
          .on('mouseout', mouseOutFn)
          .on('mousedown', mouseDownFn)
          .on('click', clickFn)
          .call(childrenFn),

      (exit) => exit.remove()
    )

  return (
    <div style={{display: 'flex', position: 'relative', overflow: 'hidden', height: '100%', width: '100%'}}>
      <Svg ref={ref}>
        <path
          fill={palette.neutral[50]}
          d={`M${marginLeft} ${marginTop}  L${width - marginRight} ${marginTop} l0 ${height - marginBottom - marginTop} l ${-width + marginLeft + marginRight} 0 Z`}
        />
        <defs>
          <clipPath id="myClip">
            <path
              d={`M${marginLeft} ${marginTop}  L${width - marginRight} ${marginTop} l0 ${height - marginBottom - marginTop} l ${-width + marginLeft + marginRight} 0 Z`}
            />
          </clipPath>
        </defs>
        <g className={'graph'} />
        <g className={'x-axis-top'} />
        <g className={'x-axis-bottom'} />
      </Svg>
      <div
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isEmpty(extendedData) && (
          <NoResultFound title={'No Data Found'} text={' There is no TableExplorerView for current timeline'}/>
        )}
      </div>
    </div>
  )
}

export default withResize(TimeLineChart)
