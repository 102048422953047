import asyncThunk from '../index';

export const getClusters = asyncThunk({
  method: "GET",
  type: "clusters",
  path: "/clusters/minor",
  api: true,
});

export default getClusters;
