import {createSlice} from '@reduxjs/toolkit'
import {assocPath, times} from 'ramda'
import {extraReducersMapper} from '../../api'
import {EMPTY_OBJECT} from '../../constants'
import getUsers from '../../api/security/getUsers'
import getGroups from '../../api/security/getGroups'
import getRoles from '../../api/security/getRoles'
import getUser from '../../api/security/getUser'
import getGroup from '../../api/security/getGroup'
import getRole from '../../api/security/getRole'
import getActivities from '../../api/security/getActivities'
import getActivity from '../../api/security/getActivity'

const defaultData = {
  pending: true,
  error: false,
}

const initialList = {
  page: 0,
  size: 10,
  filter: {},
  sort: {},
}

const initialState = {
  breadCrumbs: EMPTY_OBJECT,
  lists: {
    users: EMPTY_OBJECT,
    groups: EMPTY_OBJECT,
    roles: EMPTY_OBJECT,
    activities: EMPTY_OBJECT,
  },
}

export const securitySlice = createSlice({
  name: 'workloads',
  initialState,
  reducers: {
    init: () => {
      return initialState
    },
    initList: (state, {payload: {name, size}}) => {
      const content = times(() => {}, size)
      const result = {
        pending: true,
        error: false,
        response: {
          content,
        },
      }

      return assocPath(['data', name], result, state)
    },
    setList: (state, {payload: {key, value}}) => {
      return assocPath(['lists', key], value, state);
    },
    resetList: (state, {payload: {key}}) => {
      return assocPath(['lists', key], initialList, state);
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getUsers,
      name: 'users',
    },
    {
      asyncThunk: getGroups,
      name: 'groups',
    },
    {
      asyncThunk: getRoles,
      name: 'roles',
    },
    {
      asyncThunk: getUser,
      name: 'user',
    },
    {
      asyncThunk: getGroup,
      name: 'group',
    },
    {
      asyncThunk: getRole,
      name: 'role',
    },
    {
      asyncThunk: getActivities,
      name: 'activities',
    },
    {
      asyncThunk: getActivity,
      name: 'activity',
    }
  ]),
})

export const {
  init,
  setDetails,
  setList,
  resetList,
  setBreadCrumbs,
  initList,
  initExecute,
  initCluster,
  initGroup,
  initJob,
  initRequest,
} = securitySlice.actions

export default securitySlice.reducer
