import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  assoc,
  equals,
  evolve,
  flatten,
  includes,
  isEmpty,
  lensProp,
  map,
  mergeLeft,
  over,
  pathOr,
  pick,
  pipe,
  propOr,
  reject,
} from 'ramda'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  HORIZONTAL,
} from '../../../../../constants'
import useFuture from '../../../../../hooks/useFuture'
import getStagesMetrics from '../../../../../api/workloads/jobs/getStagesMetrics'
import React, {useEffect, useMemo, useState} from 'react'
import {fork} from 'fluture'
import * as d3 from 'd3'

import MetricChart from '../../../../../components/charts/MetricChart'
import Line from '../../../../../components/line'
import styled, {useTheme} from 'styled-components'
import {
  Input,
  ResizeLayout,
  VirtualizedList,
} from 'frontcore'
import TimeLineChart2 from '../../../../../components/charts/TimeLineChart2'
import {
  Border,
  DetailsContainer,
  RightContainer,
  SearchContainer,
  Title,
} from '../styles'
import StatusIndicator from '../../../../../components/StatusIndicator'
import NoItemsFound from '../../../../../components/NoResultFound'
import formatBigNumber from '../../../../../helpers/formatBigNumber'
import formatBytes from '../../../../../helpers/formatBytes'
import RButton from '../../../../../components/RButton'
import {LetterMiniature} from '../../../../../components/miniatures'
import DetailsHeader from '../../../../../components/DetailsHeader'
import ResourceIcon from '../../../../../components/Icons/ResourceIcon'
import {isNotNull} from '../../../../../helpers'

const timeFormat = d3.timeFormat('%d/%m/%Y %H:%M:%S:%L')
const durationFormat = (value) => value / 1000

const jobToolTipTemplate = ({data = EMPTY_OBJECT}) => {
  const {jobId, completionTime, submissionTime} = data

  return `<div style='display: flex; flex-direction: column; gap: 8px'>
  <div style='font-weight: 800'>JOB-${jobId}</div>
  <div style='display: flex; gap: 16px'>
    <div style='display: flex; flex-direction: column; gap: 4px'>
      <div>Submission Time</div>
      <div>Completion Time</div>
      <div>Duration</div>
    </div>
    <div style='display: flex; flex-direction: column; gap: 4px'>
      <div>${timeFormat(submissionTime)}</div>
      <div>${timeFormat(completionTime)}</div>
      <div>${durationFormat(completionTime - submissionTime)}</div>
    </div>
  </div>
</div>`
}

const stageToolTipTemplate = ({data = EMPTY_OBJECT}) => {
  const {stageId, completionTime, submissionTime} = data

  return `
<div style='display: flex; flex-direction: column; gap: 8px'>
  <div style='font-weight: 800'>STAGE-${stageId}</div>
  <div style='display: flex; gap: 16px'>
    <div style='display: flex; flex-direction: column; gap: 4px'>
      <div>Submission Time</div>
      <div>Completion Time</div>
      <div>Duration</div>
    </div>
    <div style='display: flex; flex-direction: column; gap: 4px'>
       <div>${timeFormat(submissionTime)}</div>
      <div>${timeFormat(completionTime)}</div>
      <div>${durationFormat(completionTime - submissionTime)}</div>
    </div>
  </div>
</div>`
}
const blue = '#9575CD'

const yellow = '#F06292'

const timelineTypeMap = {
  job: 'timelineJob',
  stage: 'timelineStage',
}

const ListButton = (props) => {
  const {data, type, onClick, active} = props

  return (
    <TabContainer leftShift={paddingMap[type]}>
      <RButton
        name={isNotNull(data.jobId) ? data.jobId : data.stageId}
        onClick={() => onClick({type, data})}
        IconComponent={LetterMiniature}
        iconComponentProps={{type: timelineTypeMap[type]}}
        fullWidth={true}
        active={active}
        type={type}
      >
        <StatusIndicator
          value={data.status}
          variant={
            data.status === 'SKIPPED'
              ? 'default'
              : 'success'
          }
        />
      </RButton>
    </TabContainer>
  )
}

const JobMetrics = ({data = EMPTY_OBJECT}) => {
  const formattedData = pipe(
    (object) => ({
      ...object,
      duration: data.completionTime - data.submissionTime,
    }),
    evolve({
      duration: durationFormat,
      completionTime: timeFormat,
      submissionTime: timeFormat,
    })
  )(data)

  const {submissionTime, completionTime, duration} =
    formattedData

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 8,
      }}
    >
      <Border>
        <Title>General</Title>
        <div
          style={{
            flex: 1,
            display: 'flex',
            gap: 4,
          }}
        >
          <MetricChart
            name={'Submission Time'}
            value={submissionTime}
            highlightChanges={false}
          />
          <MetricChart
            name={'Completion Time'}
            value={completionTime}
            highlightChanges={false}
          />
          <MetricChart
            name={'Duration'}
            value={duration}
            unit={'S'}
            highlightChanges={false}
          />
        </div>
      </Border>
    </div>
  )
}

const StageMetrics = ({data = EMPTY_OBJECT}) => {
  const formattedData = pipe(
    (object) => ({
      ...object,
      ...object.data,
      duration: data.completionTime - data.submissionTime,
    }),
    evolve({
      duration: durationFormat,
      completionTime: timeFormat,
      submissionTime: timeFormat,
    })
  )(data)

  const {
    submissionTime,
    completionTime,
    duration,
    inputBytes,
    inputRecords,
    outputBytes,
    outputRecords,
  } = formattedData

  const peakMemoryMetrics = useMemo(
    () => propOr(EMPTY_OBJECT, 'peakMemoryMetrics', data),
    [data]
  )

  const peakProcessTreeMemoryMetrics = useMemo(
    () =>
      pipe(
        propOr(
          EMPTY_OBJECT,
          'peakProcessTreeMemoryMetrics'
        ),
        over(lensProp('ProcessTreeJVMRSSMemory'), (value) =>
          formatBigNumber(value)
        ),
        over(lensProp('ProcessTreeJVMVMemory'), (value) =>
          formatBigNumber(value)
        ),
        over(
          lensProp('ProcessTreeOtherRSSMemory'),
          (value) => formatBytes(value, 2)
        ),

        over(lensProp('ProcessTreeOtherVMemory'), (value) =>
          formatBytes(value, 2)
        ),
        over(
          lensProp('ProcessTreePythonRSSMemory'),
          (value) => formatBytes(value, 2)
        ),
        over(
          lensProp('ProcessTreePythonVMemory'),
          (value) => formatBytes(value, 2)
        )
      )(data),
    [data]
  )

  const extendedPeakMemoryMetrics = map(
    (value) => formatBytes(value, 2),
    peakMemoryMetrics
  )

  const {
    DirectPoolMemory,
    JVMHeapMemory,
    JVMOffHeapMemory,
    MappedPoolMemory,
    OffHeapExecutionMemory,
    OffHeapUnifiedMemory,
    OnHeapExecutionMemory,
    OnHeapStorageMemory,
    OnHeapUnifiedMemory,
  } = extendedPeakMemoryMetrics

  const {
    ProcessTreeJVMRSSMemory,
    ProcessTreeJVMVMemory,
    ProcessTreeOtherRSSMemory,
    ProcessTreeOtherVMemory,
    ProcessTreePythonRSSMemory,
    ProcessTreePythonVMemory,
  } = peakProcessTreeMemoryMetrics

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 8,
        gap: 8,
      }}
    >
      <Border>
        <Title>General</Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 4,
            }}
          >
            <MetricChart
              name={'Submission Time'}
              value={submissionTime}
              highlightChanges={false}
            />
            <MetricChart
              name={'Completion Time'}
              value={completionTime}
              highlightChanges={false}
            />
            <MetricChart
              name={'Duration'}
              value={duration}
              unit={'S'}
              highlightChanges={false}
            />
          </div>
          <div style={{display: 'flex', gap: 4}}>
            <MetricChart
              name={'Input Bytes'}
              {...formatBigNumber(inputBytes)}
              highlightChanges={false}
            />
            <MetricChart
              name={'Input Records'}
              {...formatBigNumber(inputRecords)}
              highlightChanges={false}
            />
            <MetricChart
              name={'Output Bytes'}
              {...formatBigNumber(outputBytes)}
              highlightChanges={false}
            />
            <MetricChart
              name={'Output Records'}
              {...formatBigNumber(outputRecords)}
              highlightChanges={false}
            />
          </div>
        </div>
      </Border>
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          gap: 8,
        }}
      >
        <div style={{flex: 3}}>
          <Border>
            <Title>Peak Memory Metrics</Title>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: 4,
              }}
            >
              <MetricChart
                name={'Direct Pool Memory'}
                {...DirectPoolMemory}
              />
              <MetricChart
                name={'JVM Heap Memory'}
                {...JVMHeapMemory}
              />
              <MetricChart
                name={'JVM Off Heap Memory'}
                {...JVMOffHeapMemory}
              />
              <MetricChart
                name={'Mapped Pool Memory'}
                {...MappedPoolMemory}
              />

              <MetricChart
                name={'Off Heap Execution Memory'}
                {...OffHeapExecutionMemory}
              />
              <MetricChart
                name={'Off Heap Unified Memory'}
                {...OffHeapUnifiedMemory}
              />
              <MetricChart
                name={'On Heap Execution Memory'}
                {...OnHeapExecutionMemory}
              />
              <MetricChart
                name={'On Heap Storage Memory'}
                {...OnHeapStorageMemory}
              />
              <MetricChart
                name={'On Heap Unified Memory'}
                {...OnHeapUnifiedMemory}
              />
            </div>
          </Border>
        </div>
        <div style={{flex: 2}}>
          <Border>
            <Title>Peak Process Tree Memory Metrics</Title>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr ',
                gap: 4,
              }}
            >
              <MetricChart
                name={'Process Tree JVM RSS Memory'}
                {...ProcessTreeJVMRSSMemory}
                unit={'GB'}
              />
              <MetricChart
                name={'Process Tree JVM VMemory'}
                {...ProcessTreeJVMVMemory}
              />
              <MetricChart
                name={'Process Tree Other RSS Memory'}
                {...ProcessTreeOtherRSSMemory}
              />
              <MetricChart
                name={'Process Tree Other VMemory'}
                {...ProcessTreeOtherVMemory}
              />
              <MetricChart
                name={'Process Tree Python RSS Memory'}
                {...ProcessTreePythonRSSMemory}
              />
              <MetricChart
                name={'Process Tree Python VMemory'}
                {...ProcessTreePythonVMemory}
              />
            </div>
          </Border>
        </div>
      </div>
    </div>
  )
}

const paddingMap = {
  job: 4,
  stage: 32,
}
export const TabContainer = styled.div`
  display: flex;
  gap: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  width: 100%;
  padding-left: ${({leftShift = 0}) => leftShift + 'px'};
`

const JobDetailsTimeline = () => {
  const [hovered, setHovered] = useState()

  const [job, setJob] = useState()

  const [stage, setStage] = useState()

  const {jobId} = useParams()

  const extendedJob =
    !stage && !job
      ? hovered?.index === '0' && hovered?.data?.data
      : job

  const extendedStage =
    !stage && !job
      ? hovered?.index === '1' && hovered?.data?.data
      : stage

  const jobMetrics = useSelector(
    pathOr(EMPTY_ARRAY, [
      'workloads',
      'data',
      'stagesMetrics',
      'response',
      'jobs',
    ])
  )

  const getStagesMetricsFuture = useFuture(getStagesMetrics)

  useEffect(() => {
    fork(() => {})(() => {})(
      getStagesMetricsFuture({
        params: [{key: 'jobId', value: jobId}],
      })
    )
  }, [jobId])

  const result = map((job) => {
    const stages = map((stage) => {
      const peakMemoryMetrics = stage.peakMemoryMetrics
      const peakProcessTreeMemoryMetrics =
        stage.peakProcessTreeMemoryMetrics

      const data = pipe(
        () => [],
        mergeLeft(peakMemoryMetrics),
        mergeLeft(peakProcessTreeMemoryMetrics)
      )(stage)
      return assoc('data', data, stage)
    }, job.stages)

    return assoc('stages', stages, job)
  }, jobMetrics)

  const selected = []

  const data = useMemo(() => {
    if (isEmpty(selected)) {
      return result
    } else {
      return map((job) => {
        const stages = map((stage) => {
          const data = pick(selected, stage.data)
          return assoc('data', data, stage)
        }, job.stages)
        return assoc('stages', stages, job)
      }, result)
    }
  }, [result, selected])

  const dataJobs = map(
    (data) => ({
      x1: data.submissionTime,
      x2: data.completionTime,
      data: data,
      selected: equals(data, job),
      active:
        (!job && !stage) ||
        equals(data, job) ||
        includes(stage, data.stages),
    }),
    data
  )

  const dataStages = pipe(
    map(({stages}) => stages),
    flatten,
    reject(({submissionTime}) => submissionTime === null),
    map((data) => ({
      x1: data.submissionTime,
      x2: data.completionTime,
      data,
      active:
        (!job && !stage) ||
        equals(data, stage) ||
        includes(data, job?.stages || []),
      selected: equals(data, stage),
    }))
  )(data)

  const {palette} = useTheme()

  const flattenData = pipe(
    map((data) => {
      const stages = map(
        (data) => ({
          type: 'stage',
          data,
          active: equals(data, stage),
        }),
        data?.stages || []
      )
      return [
        {
          type: 'job',
          data: data,
          active: equals(data, job),
        },
        ...stages,
      ]
    }),
    flatten
  )(data)

  const timeline = (
    <div
      style={{
        height: 32 * 4,
        paddingLeft: 24,
        paddingRight: 24,
        boxSizing: 'border-box',
      }}
    >
      <TimeLineChart2
        data={{
          jobs: dataJobs,
          stages: dataStages,
        }}
        onOver={(item) => setHovered(item)}
        onOut={() => setHovered()}
        onClick={({index, data: {data}}) => {
          if (index === '0') {
            if (equals(data, job)) {
              setJob()
            } else {
              setJob(data)
              setStage()
            }
          } else if (index === '1') {
            if (equals(data, stage)) {
              setStage()
            } else {
              setJob()
              setStage(data)
            }
          }
        }}
        options={{
          colors: [
            {
              default: blue,
              hover: palette.green[400],
              active: palette.green[500],
            },
            {
              default: yellow,
              hover: palette.yellow[400],
              active: palette.yellow[500],
            },
          ],
          toolTipTemplates: [
            jobToolTipTemplate,
            stageToolTipTemplate,
          ],
        }}
        toolTipTemplate={() => ''}
      />
    </div>
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div>{timeline}</div>
      <Line />
      <div style={{flex: 1}}>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ResizeLayout
            firstNode={
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <SearchContainer>
                  <Input
                    fullWidth
                    disabled
                    topLabelProps={{
                      children: 'Search Timeline',
                    }}
                  />
                </SearchContainer>
                <Line />
                {isEmpty(flattenData) ? (
                  <NoItemsFound
                    text={
                      'There is no elements for current timeline'
                    }
                    title={'No Data Found'}
                  />
                ) : (
                  <VirtualizedList
                    items={flattenData}
                    itemHeight={32}
                    gap={4}
                    ItemComponent={ListButton}
                    itemComponentProps={{
                      onClick: ({type, data}) => {
                        if (type === 'job') {
                          setJob(data)
                          setStage()
                        }
                        if (type === 'stage') {
                          setJob()
                          setStage(data)
                        }
                      },
                    }}
                  />
                )}
              </div>
            }
            secondNode={
              <RightContainer>
                <DetailsContainer>
                  {extendedJob && (
                    <DetailsHeader
                      icon={<ResourceIcon type={'timelineJob'} />}
                      id={extendedJob?.jobId}
                      name={extendedJob?.name}
                      pending={false}
                      data={[
                        {
                          key: 'state',
                          value: data?.state,
                          Component: StatusIndicator,
                          componentProps: {
                            value: extendedJob?.status,
                            variant:
                              extendedJob.status ===
                              'SKIPPED'
                                ? 'default'
                                : 'success',
                          },
                        },
                      ]}
                    />
                  )}
                  {extendedStage && (
                    <DetailsHeader
                      icon={<ResourceIcon type={'timelineStage'} />}
                      id={extendedStage?.stageId}
                      name={extendedStage?.name}
                      pending={false}
                      data={[
                        {
                          key: 'state',
                          value: data?.state,
                          Component: StatusIndicator,
                          componentProps: {
                            value: extendedStage?.status,
                            variant:
                              extendedStage.status ===
                              'SKIPPED'
                                ? 'default'
                                : 'success',
                          },
                        },
                      ]}
                    />
                  )}
                </DetailsContainer>
                <Line />

                <div
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  {extendedJob && (
                    <JobMetrics data={extendedJob} />
                  )}
                  {extendedStage && (
                    <StageMetrics data={extendedStage} />
                  )}
                </div>
              </RightContainer>
            }
            configuration={{
              orientation: HORIZONTAL,
              firstNode: {
                measurement: 'px',
                value: 260,
              },
              secondNode: {
                measurement: 'flex',
                value: 1,
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default JobDetailsTimeline
