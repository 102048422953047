import React from 'react'
import { Button, Icon } from 'frontcore'
import DropDownButtonContentLayout from '../../../../components/layouts/dropDownButtonContentLayout'

const DeleteContentComponent = ({
  closeFn,
  name,
  onDelete,
}) => {
  return (
    <DropDownButtonContentLayout>
      <div style={{ fontSize: 16, color: 'red', fontWeight: 'bold' }}>
        <Icon icon="info" size={20} color="red" />
        Delete Cluster: {name}
      </div>
      <div style={{ fontSize: 12, color: 'red' }}>
        Are you absolutely sure you want to delete this cluster? This action cannot be undone!
      </div>
      <div
        style={{
          display: 'flex',
          gap: 8,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color={'error'}
          tabIndex={-1}
          onClick={() => {
            onDelete()
            closeFn()
          }}
        >
          Yes, Delete
        </Button>

        <Button
          tabIndex={-1}
          variant={'outlined'}
          onClick={() => {
            closeFn()
          }}
        >
          No, Cancel
        </Button>
      </div>
    </DropDownButtonContentLayout>
  )
}

export default DeleteContentComponent;