import {useDispatch} from 'react-redux'
import React, {useCallback, useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'

import {forEach, pipe, reduce, toPairs,} from 'ramda'
import {isArray} from '../../../../helpers'
import useFetch from '../../../../hooks/useFetch'
import getClustersNamesFetch from '../../../../api/workloads/clusters/getClustersNamesFetch'
import createService from '../../../../api/workloads/services/createService'
import usePending from '../../../../hooks/usePending'
import {useNavigate} from 'react-router-dom'
import TabsFormLayout from '../../../../components/tabsFormLayout'
import schema from './schema'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import Form from '../../../../components/form'
import useFuture from '../../../../hooks/useFuture'

const useQueryParams = () => {
  const {search} = window.location
  return new URLSearchParams(search)
}

const WorkloadsCreateServiceView = () => {
  const createServiceFuture = useFuture(createService)
  const {createNotification} = useNotificationContext()

  const navigate = useNavigate()

  const {data, error} = useFetch(getClustersNamesFetch)
  const dispatch = useDispatch()

  const pending = usePending(
    ['workloads', 'data'],
    ['createService']
  )

  const query = useQueryParams()

  const handleOnSubmit = useCallback((params) => {
    const result = pipe(
      toPairs,
      reduce((acc, [fieldName, fieldValue]) => {
        if (isArray(fieldValue)) {
          forEach(([key, value]) => {
            acc.append(fieldName, key, value)
          }, fieldValue)
        } else {
          acc.append(fieldName, fieldValue)
        }
        return acc
      }, new FormData())
    )(params)

    fork(({message}) => {
      createNotification({
        title: 'Failed to create Service',
        message,
        autoHide: true,
        variant: 'error',
      })
    })(({message}) => {
      navigate(-1)
      createNotification({
        title: 'Service created successfully',
        message,
        autoHide: true,
        variant: 'success',
      })
    })(createServiceFuture(result))
  }, [])

  useEffect(() => {
    dispatch(
      setBreadCrumbs({
        group: {
          name: 'Services',
        },
      })
    )
  }, [])

  const options = useMemo(
    () => ({
      pending,
      clusters: data,
      cluster: query.get('cluster'),
      navigate,
    }),
    [pending, data]
  )

  return (
    <Form
      options={options}
      LayoutComponent={TabsFormLayout}
      schema={schema}
      onSubmit={handleOnSubmit}
      layoutComponentProps={{
        title: 'NEW SERVICE',
      }}
    />
  )
}

export default WorkloadsCreateServiceView
