import {useDispatch, useSelector} from 'react-redux'
import {
  assoc,
  assocPath,
  map,
  path,
  pathOr,
  pipe,
} from 'ramda'
import React, {useCallback, useEffect} from 'react'
import {resetList, setList} from '../../reducers/workloads'
import {Button} from 'frontcore'
import useFetch from '../../hooks/useFetch'
import styled from 'styled-components'
import {singleCallFetchOptions} from '../../api/helpers'
import {useNotificationContext} from "../../hooks/useNotificationsContext";

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
`
const FetchFilterWrapper = ({optionsApi, children}) => {
  const {filtersRefreshRate = 600000} = useSelector(
    path(['settings', 'settings'])
  )

  const {createNotification} = useNotificationContext()

  const fetchOptions = Number(filtersRefreshRate)
    ? {refreshInterval: Number(filtersRefreshRate)}
    : singleCallFetchOptions

  const {data, error} = useFetch(optionsApi, fetchOptions)

  useEffect(() => {
    if(error) {
      createNotification({
        title: 'Failed to fetch filters options',
        message: error.message,
        variant: 'error',
        autoHide: true,
      })
    }

  }, [error])

  return <div>{children(data)}</div>
}

const Filter = ({
  Component,
  props,
  id,
  defaultValue,
  optionsApi,
  onChange,
  filter,
  pending = false,
}) => {
  const handleOnChange = useCallback(
    (value) => {
      onChange({id, value})
    },
    [onChange]
  )

  const value = filter?.[id] || defaultValue

  return (
    <div>
      {!optionsApi ? (
        <Component
          skeleton={pending}
          {...props}
          value={value}
          onChange={handleOnChange}
        />
      ) : (
        <FetchFilterWrapper optionsApi={optionsApi}>
          {(data) => (
            <Component
              skeleton={pending}
              options={data}
              {...props}
              value={value}
              onChange={handleOnChange}
            />
          )}
        </FetchFilterWrapper>
      )}
    </div>
  )
}

const Filters = ({
  name = 'groups',
  schema,
  pending = false,
}) => {
  const lists = useSelector(
    pathOr([], ['workloads', 'lists', name])
  )

  const {filter} = lists

  const dispatch = useDispatch()

  const handleOnChange = useCallback(
    ({id, value}) => {
      const result = pipe(
        assocPath(['filter', id], value),
        assoc('page', 0)
      )(lists)
      dispatch(setList({key: name, value: result}))
    },
    [lists]
  )

  const handleOnReset = useCallback(() => {
    dispatch(resetList({key: name}))
  }, [lists])

  return (
    <Root>
      {map(
        (props) => (
          <Filter
            pending={pending}
            key={props?.id}
            {...props}
            filter={filter}
            onChange={handleOnChange}
          />
        ),
        schema
      )}
      <Button
        variant={'text'}
        tabIndex={-1}
        onClick={handleOnReset}
        skeleton={pending}
      >
        Reset Filters
      </Button>
    </Root>
  )
}

export default Filters
