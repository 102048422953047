import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import useFuture from '../../hooks/useFuture';
import {assoc, is, isEmpty, isNil, join, map, mergeLeft, pathOr, pipe, toPairs, when} from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react'
import {initList, resetList, setList} from '../../reducers/workloads'
import {notEquals} from '../../helpers';
import useDebounce from '../../hooks/useDebounce';

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage = (name) => {
  const result = localStorage.getItem(name);

  try {
    return JSON.parse(result);
  } catch (error) {
    return result
  }
};

const useListRefreshFuture = (
  api,
  root,
  name,
  defaultOptions = {
    page: 0,
    size: 10,
    sort: EMPTY_OBJECT,
    filter: EMPTY_OBJECT,
  },
  overwriteFilters = EMPTY_OBJECT,
) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(EMPTY_ARRAY)

  const extendedOverWriteFilters = useMemo(() => overwriteFilters, []);
  const getGroupsFuture = useFuture(api);
  const lists = useSelector(pathOr(defaultOptions, [root, 'lists', name]));
  const {
    sort = EMPTY_OBJECT,
    filter = EMPTY_OBJECT,
    page = 0,
    size = 10,
  } = lists;
  const sorts = useMemo(() => pipe(toPairs, map(join(',')))(sort), [sort]);

  useEffect(() => {
    return () => dispatch(initList({name, size}));

  }, []);

  useEffect(() => {
    const result = assoc('page', 0, lists);
    dispatch(setList({key: name, value: result}));
  }, []);

  useEffect(() => {
    const storageOptions = getLocalStorage(name + 'List');
    if (!storageOptions) {
      setLocalStorage(name + 'List', defaultOptions);
    } else if (notEquals(lists, storageOptions)) {
      setLocalStorage(name + 'List', lists);
    }
    if (
      (isNil(lists) || isEmpty(lists)) &&
      notEquals(lists, storageOptions || defaultOptions)
    ) {
      dispatch(setList({key: name, value: storageOptions || defaultOptions}));
    }
  }, [lists]);

  const extendedFilters = useMemo(
    () => mergeLeft(extendedOverWriteFilters, filter),
    [filter],
  );

  const filters = useMemo(() => {
    return map(when(is(Array), join(',')), extendedFilters);
  }, [extendedFilters]);

  const handleOnSort = useCallback(
    (sort) => {
      const result = pipe(
        assoc('sort', sort),
        assoc('page', 0),
      )(lists);
      dispatch(setList({key: name, value: result}));
    },
    [lists],
  );


  const handleOnPageChange = useCallback(
    (value) => {
      const result = assoc('page', value, lists);
      dispatch(setList({key: name, value: result}));
    },
    [lists],
  );
  const handleOnSizeChange = useCallback(
    (value) => {
      const result = pipe(assoc('size', value), assoc('page', 0))(lists);
      dispatch(setList({key: name, value: result}));
    },
    [lists],
  );

  const debouncedSorts = useDebounce(sorts, 500);
  const debouncedFilters = useDebounce(filters, 500);

  const getFuture = useMemo(
    () =>
      getGroupsFuture({
        size,
        page,
        sort: debouncedSorts,
        ...debouncedFilters,
      }),
    [size, page, debouncedSorts, debouncedFilters],
  );

  const pages = useSelector(
    pathOr(EMPTY_OBJECT, [root, 'data', name, 'response']),
  );

  const {totalPages = 0} = pages;

  const data = useSelector(
    pathOr(EMPTY_ARRAY, [root, 'data', name, 'response', 'content']),
  );

  const handleOnReset = useCallback(() => {
    dispatch(resetList({key: name}))
  }, [lists])

  return {
    lists,
    size,
    page,
    getFuture,
    sort,
    handleOnReset,
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    data,
    totalPages,
    selected,
    setSelected
  };
};

export {useListRefreshFuture};
