import React, {useEffect, useState} from 'react'
import {Button, DropDownButton} from 'frontcore'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from 'react-oidc-context'
import styled from 'styled-components'
import {getLocalStorage} from '../../views/WorkloadsView/hooks'
import {toBoolean} from '../../helpers'

const Root = styled.div`
  background-color: ${({theme}) =>
    theme.palette['surface-primary']};
`

const ContentComponent = ({closeFn}) => {
  const [mode, setMode] = useState(false)

  useEffect(() => {
    const mode = getLocalStorage('mode')
    const result = toBoolean(mode)
    setMode(result)
  }, [])

  const auth = useAuth()
  const {removeUser} = auth
  const navigate = useNavigate()

  const {pathname} = useLocation()

  return (
    <Root>
      <Button
        fullWidth
        tabIndex={-1}
        variant={'text'}
        rounding={false}
        onClick={() => {
          closeFn()
          if (pathname === '/') {
            localStorage.setItem(
              'cachedUrl',
              'WorkloadsView/list/services'
            )
          } else if (pathname !== '/login') {
            localStorage.setItem('cachedUrl', pathname)
          }
          removeUser()
          localStorage.removeItem('token')
          navigate('/login')
        }}
      >
        Log Out
      </Button>
    </Root>
  )
}

const Miniature = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.red[300]};
  color: ${({theme}) => theme.palette.neutral[0]};
  display: flex;
  align-items: center;
  justify-content: center;
`

const MiniatureComponent = ({text}) => (
  <Miniature>{text}</Miniature>
)

const UserDropdown = ({expanded}) => {

    const auth = useAuth()

    console.log('auth', auth)
  const Component = expanded
    ? () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            Admin
          </div>
          <div style={{fontSize: 12}}>admin@ilum.cloud</div>
        </div>
      )
    : MiniatureComponent

  const StartComponent = expanded
    ? MiniatureComponent
    : null

  return (
    <DropDownButton
      buttonProps={{
        tabIndex: -1,
        variant: 'text',
        size: 'large',
        square: !expanded,
        justifyContent: expanded ? 'flex-start' : 'center',
        Component,
        componentProps: {
          text: 'A',
        },
        StartComponent,
        startComponentProps: {
          text: 'A',
        },
      }}
      ContentComponent={ContentComponent}
    />
  )
}

export default UserDropdown
