import Filters from '../../../filters'
import JobsList from '../../../Components/JobsList'
import ListLayout from '../../../../../components/layouts/listLayout'
import React from 'react'
import usePending from '../../../../../hooks/usePending'
import {useListRefreshFuture} from '../../../hooks'
import getJobs from '../../../../../api/workloads/jobs/getJobs'
import {EMPTY_ARRAY, EMPTY_OBJECT,} from '../../../../../constants'
import {Icon, Input, Select} from 'frontcore'
import JobBatchButtons from '../../../Components/JobBatchButtons'
import {isNotEmpty} from '../../../../../helpers'

const schema = [
  {
    id: 'search',
    Component: Input,
    defaultValue: '',
    props: {
      StartComponent: Icon,
      startComponentProps: {
        icon: 'search',
        size: 16,
      },
      placeHolder: 'Jobs Search',
      topLabelProps: {
        children: 'Jobs Search',
      },
    },
  },
  {
    id: 'jobStates',
    Component: Select,
    defaultValue: EMPTY_ARRAY,
    props: {
      label: 'Job States',
      size: 'small',
      value: [],
      options: [
        'SUBMITTED',
        'STARTING',
        'READY',
        'BUSY',
        'FAILED',
        'FINISHED',
        'UNKNOWN',
      ],
      multiSelect: true,
      buttonProps: {
        width: '200px',
        justifyContent: 'space-between',
      },
    },
  },
]

const ScheduleDetailsJobsList = ({scheduleId}) => {
  const listHandler = useListRefreshFuture(
    getJobs,
    'workloads',
    'jobs',
    {
      page: 0,
      size: 10,
      sort: {
        submitTime: 'desc',
      },
      filter: EMPTY_OBJECT,
    },
    {scheduleIds: [scheduleId]}
  )

  const {selected} = listHandler

  const pending = usePending(
    ['workloads', 'data'],
    ['jobs', 'killJob', 'deleteJob']
  )

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && <JobBatchButtons {...listHandler}/>
      }
      filtersNode={
        <Filters
          name={'jobs'}
          schema={schema}
          pending={pending}
        />
      }
      contentNode={
        <JobsList
          listHandler={listHandler}
          borderTop={true}
          pending={pending}
        />
      }
    />
  )
}

export default ScheduleDetailsJobsList
