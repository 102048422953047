import {GraphicWalker} from '@kanaries/graphic-walker'
import {VegaTheme} from '@kanaries/graphic-walker/vis/theme'
import useFuture from '../../../../hooks/useFuture'
import executeSQLStatement from '../../../../api/sql/executeSQLStatement'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useMemo} from 'react'
import {fork} from 'fluture'
import {resultsSetErrorMessage} from '../../../../reducers/sql/results'
import {EMPTY_FUNCTION} from '../../../../constants'
import {fromPairs, isNil, map, path, pipe, zip} from 'ramda'
import mapField from '../../../sql/results/modes/typeMapper'
import styled from 'styled-components'
import {skeletonCss} from 'frontcore'
import {
  ILUM_LINEAGE_ENABLED,
  ILUM_SQL_ENABLED,
} from '../../../../helpers/runtimeEnv'
import ViewNotAvailable from '../../../../components/viewNotAvailable'
import useDefaultCluster from '../../../../hooks/useDefaultCluster'

const Root = styled.div`
  ${skeletonCss}
`
const DataExploration = () => {
  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )

  const a = useSelector(
    path([
      'sqlViewer',
      'results',
      'data',
      'result',
      'response',
    ])
  )

  const pending = useSelector(
    path([
      'sqlViewer',
      'results',
      'data',
      'result',
      'pending',
    ])
  )

  const columns = a?.columns || []
  const rows = a?.rows || []

  const executeSQLStatementFuture = useFuture(
    executeSQLStatement
  )
  const {createNotification} = useNotificationContext()

  const dispatch = useDispatch()

  const defaultCluster = useDefaultCluster()

  useEffect(() => {
    if (isNil(defaultCluster)) {
      /*
      createNotification({
        title: 'Error',
        message: 'No available clusters to query Metastore data',
        variant: 'error',
        autoHide: true,
      })
      */
      return
    }

    fork(({message}) => {
      dispatch(resultsSetErrorMessage(message))
      createNotification({
        title: 'Error',
        message:
          'Failed to run query. Check logs for details',
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(
      executeSQLStatementFuture({
        maxRows: 1000,
        engine: 'SPARK_SQL',
        statement: `select * from ${chosenItem?.databaseName}.${chosenItem?.name} limit 1000`,
        clusterId: defaultCluster?.id,
      })
    )
  }, [chosenItem?.databaseName, chosenItem?.name, defaultCluster])

  const walkerFields = useMemo(() => {
    return columns.map(mapField)
  }, [columns])

  const walkerData = useMemo(() => {
    const fields = map(({name}) => name, columns)
    return map(pipe(zip(fields), fromPairs), rows)
  }, [columns, rows])

  return ILUM_SQL_ENABLED.toLowerCase() === 'true' ? (
    <Root
      $skeleton={pending}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      {!pending && (
        <GraphicWalker
          data={walkerData}
          fields={walkerFields}
          vizThemeConfig={VegaTheme}
          darkMode="light"
        />
      )}
    </Root>
  ) : (
    <ViewNotAvailable name={'SQL'} />
  )
}

export default DataExploration
