import {memo, useCallback} from 'react'
import ListLayout from '../../../../../components/layouts/listLayout'
import {Button, Icon} from 'frontcore'
import Filters from '../../../filters'
import SchedulesList from '../../../Components/SchedulesList'
import usePending from '../../../../../hooks/usePending'
import {useListRefreshFuture} from '../../../hooks'
import {EMPTY_OBJECT} from '../../../../../constants'
import getSchedules from '../../../../../api/workloads/schedules/getSchedules'
import {
  createSearchParams,
  useNavigate,
} from 'react-router-dom'
import schema from './schema'
import ScheduleBatchButtons from '../../../Components/ScheduleBatchButtons'
import {isNotEmpty} from '../../../../../helpers'

const ClusterDetailsScheduleList = ({
  clusterId,
  clusterName,
}) => {
  const pending = usePending(
    ['workloads', 'data'],
    ['schedules', 'stopSchedule', 'deleteSchedule']
  )

  const listHandler = useListRefreshFuture(
    getSchedules,
    'workloads',
    'schedules',
    {
      page: 0,
      size: 10,
      sort: EMPTY_OBJECT,
      filter: EMPTY_OBJECT,
    },
    {clusterNames: [clusterName]}
  )

  const {selected} = listHandler

  const navigate = useNavigate()
  const handleNewSchedule = useCallback(() => {
    navigate({
      pathname: '/workloads/create/schedule',
      search: createSearchParams({
        cluster: clusterName,
      }).toString(),
    })
  }, [clusterName])

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) &&  <ScheduleBatchButtons {...listHandler} />
      }
      filtersNode={
        <Filters
          name="schedules"
          schema={schema}
          pending={pending}
        />
      }
      createButtonNode={
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewSchedule}
          EndComponent={Icon}
          endComponentProps={{
            icon: 'plus',
            size: 18,
            color: 'white',
          }}
        >
          New Schedule
        </Button>
      }
      contentNode={
        <SchedulesList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default memo(ClusterDetailsScheduleList)
