import {any, equals, isEmpty, map} from 'ramda';
import {onSubmitParametersFn, onSubmitSparkParamsFn} from '../../../../helpers';
import Parameters from '../../../../components/parameters';
import {Button, CronPicker, Input, Select} from 'frontcore';
import RadioButtons from '../../../../components/RadioButtons';
import memorySettings from '../../memorySettings'
import DatetimeInput from '../../../../components/DatetimeInput';
import requiredValidator from "../../../../validators/requiredValidator";
import timeValidator from "../../../../validators/timeValidator";
import parametersValidator from "../../../../validators/parametersValidator";

const formSchema = (data, options) => {
  const {pending, navigate} = options
  return Object.freeze({
    fields: [
      {
        section: 'General',
        id: 'name',
        name: 'Name',
        description: 'Name of the schedule',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
          disabled: true,
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'clusterName',
        name: 'Cluster',
        defaultValue: options.cluster,
        description: 'Cluster assigned to the schedule',
        Component: Select,
        componentProps: {
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: map(
            (cluster) => cluster.name,
            options?.clusters || []
          ),
          disabled: true,
        },
        validators: [requiredValidator],
      },
      {
        section: 'General',
        id: 'description',
        name: 'Description',
        description: 'Description of the schedule',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        section: 'General',
        id: 'language',
        name: 'Language',
        description: 'Language used for that schedule',
        defaultValue: 'scala',
        Component: RadioButtons,
        componentProps: {
          skeleton: pending,
          buttonProps: {
            fullWidth: true,
          },
          fullWidth: true,
          options: [
            {id: 'scala', label: 'Scala'},
            {id: 'python', label: 'Python'},
          ],
          disabled: true,
        },
      },
      {
        section: 'Timing',
        id: 'startTime',
        name: 'Start Time',
        description: 'Date and time when the schedule will start. If not provided, the schedule will start immediately.',
        Component: DatetimeInput,
        validators: [timeValidator],
      },
      {
        section: 'Timing',
        id: 'endTime',
        name: 'End Time',
        description: 'Date and time when the schedule will be suspended. If not provided, the schedule will create jobs until schedule disabling.',
        Component: DatetimeInput,
        validators: [timeValidator],
      },
      {
        section: 'Timing',
        id: 'cron',
        defaultValue: '@daily',
        name: 'CRON Expression',
        description: 'Unix-style CRON expression. Determines periodicity of the schedule, in other words how often it runs',
        Component: CronPicker,
        // TODO: add cron validator
        validators: [requiredValidator],
      },
      // {
      //   section: 'Resources',
      //   id: 'files',
      //   name: 'Files',
      //   description: 'Files description.',
      //   Component: FileUpload,
      //   componentProps: {
      //     disabled: true,
      //     skeleton: pending,
      //     fullWidth: true,
      //   },
      //   onSubmitFn: onSubmitFilesFn,
      //   validators: [],
      // },
      // {
      //   section: 'Resources',
      //   id: 'jars',
      //   name: 'Jars',
      //   description: 'Jars description.',
      //   Component: FileUpload,
      //   componentProps: {
      //     disabled: true,
      //     skeleton: pending,
      //     fullWidth: true,
      //     accept: '.jar',
      //   },
      //   onSubmitFn: onSubmitFilesFn,
      //   validators: [],
      // },
      // {
      //   section: 'Resources',
      //   id: 'pyFiles',
      //   name: 'PyFiles',
      //   description: 'PyFiles description.',
      //   Component: FileUpload,
      //   componentProps: {
      //     disabled: true,
      //     skeleton: pending,
      //     fullWidth: true,
      //   },
      //   onSubmitFn: onSubmitFilesFn,
      //   validators: [],
      // },
      {
        section: 'Resources',
        id: 'pyRequirements',
        name: 'Requirements',
        description: 'Requirements description.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          fields: [{label: 'Value', name: 'value'}],
        },
        onSubmitFn: onSubmitParametersFn,
        validators: [
          (value) => {
            const result = map(
              (a) => isEmpty(a.value),
              value || []
            )
            return any(equals(true), result)
              ? 'This field is required'
              : ''
          },
        ],
      },
      {
        section: 'Configuration',
        id: 'jobConfig',
        name: 'Parameters',
        description: 'Key/Value configuration of the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [parametersValidator],
      },
      {
        section: 'Configuration',
        id: 'args',
        name: 'Arguments',
        description: 'Arguments of the job.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
          fields: [{label: 'Value', name: 'value'}],
        },
        onSubmitFn: onSubmitParametersFn,
        validators: [],
      },
      {
        section: 'Configuration',
        id: 'tags',
        name: 'Tags',
        description: 'Label application with a tag.',
        Component: Parameters,
        componentProps: {
          skeleton: pending,
          size: 'small',
          fullWidth: true,
        },
        onSubmitFn: onSubmitSparkParamsFn,
        validators: [parametersValidator],
      },
      {
        section: 'General',
        id: 'jobClass',
        name: 'Class',
        description: 'Full class name of the job (e.g., org.apache.spark.examples.SparkPi) or filename for Python.',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [requiredValidator],
      },
      ...memorySettings(data, options),
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  })
}

export default formSchema
