import React, {useCallback} from 'react'
import {Icon} from 'frontcore'
import styled, {useTheme} from 'styled-components'
import {LetterMiniature} from '../../../components/miniatures'
import ToolTipContent from './ToolTipContent'
import ToolTip from '../../../components/toolTip'
import {EMPTY_OBJECT} from '../../../constants'
import {propOr} from 'ramda'

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({theme}) => theme.palette.neutral[800]};
`

const iconMap = {
  root: {
    Component: LetterMiniature,
    componentProps: {type: 'cluster'},
  },
  cluster: {
    Component: LetterMiniature,
    componentProps: {type: 'cluster'},
  },

  storage: {
    Component: Icon,
    componentProps: {
      icon: 'server',
      size: 20,
    },
  },
  bucket: {
    Component: Icon,
    componentProps: {
      icon: 'trash',
      size: 20,
    },
  },

  folder: {
    Component: Icon,
    componentProps: {
      icon: 'directory',
      size: 20,
    },
  },
  file: {
    Component: Icon,
    componentProps: {
      icon: 'project',
      size: 20,
    },
  },
}
const ListItem = (props) => {
  const {type, name, onClick} = props

  const handleOnClick = useCallback(
    (value) => {
      if (type !== 'file') {
        onClick(props)
      }
    },
    [type]
  )

  const {Component, componentProps} = propOr(
    EMPTY_OBJECT,
    type,
    iconMap
  )

  return (
    <ToolTip content={<ToolTipContent item={props} />}>
      <Root onClick={handleOnClick}>
        <div style={{width: 24, height: 24}}>
          {Component && <Component {...componentProps} />}
        </div>
        <NameContainer>{name}</NameContainer>
      </Root>
    </ToolTip>
  )
}

export default ListItem

const Root = styled.div`
  cursor: default;
  padding: 8px;
  display: flex;
  overflow: hidden;
  gap: 8px;
  align-items: center;

  &:hover {
    background: ${({theme}) => theme.palette.neutral[100]};
  }
`
