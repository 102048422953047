import {ILUM_AIRFLOW_PATH} from "../../helpers/runtimeEnv";

const AirflowView = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_AIRFLOW_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    );
};

AirflowView.dispayName = "AirflowView";

export default AirflowView;
