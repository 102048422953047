import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {clamp} from 'ramda';
import useWindowSize from '../../hooks/useWindowSize';
import {Transition, TransitionGroup} from 'react-transition-group';
import styled, {css} from 'styled-components';
import {createTransitions} from '../../helpers';
import {Portal} from 'frontcore';
import {BOTTOM, LEFT, RIGHT, TOP} from '../../constants';

const enteringStateStyles = css`
  transform: ${({verticalOrientation}) =>
          verticalOrientation === TOP
                  ? 'translateY(50%) scaleY(0)'
                  : 'translateY(-50%) scaleY(0)'};
  pointer-events: all;
  opacity: 0;
`;

const enteredStateStyles = css`
  transform: translateY(0) scaleY(1);
  pointer-events: all;
  opacity: 1;
`;

const exitingStateStyles = css`
  transform: ${({verticalOrientation}) =>
          verticalOrientation === TOP
                  ? 'translateY(50%) scaleY(0)'
                  : 'translateY(-50%) scaleY(0)'};
  pointer-events: none;
  opacity: 0;
`;

const exitedStateStyles = css`
  transform: ${({verticalOrientation}) =>
          verticalOrientation === TOP
                  ? 'translateY(50%) scaleY(0)'
                  : 'translateY(-50%) scaleY(0)'};
  pointer-events: none;
  opacity: 0;
`;

const stateStylesMap = {
  entering: enteringStateStyles,
  entered: enteredStateStyles,
  exiting: exitingStateStyles,
  exited: exitedStateStyles,
};

const ContainerContent = styled.div`
  box-sizing: border-box;
  pointer-events: all;
  border: 1px solid rgba(34, 24, 90, 0.2);
    border-color: ${({theme}) => theme.palette.line};
  background-color: ${({theme}) => theme.palette['surface-primary']};
  box-shadow: 0 4px 10px rgba(47, 38, 152, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: ${createTransitions(['transform', 'opacity'], 150)};
`;

const ContainerRoot = styled.div`
  overflow: hidden;
  min-width: ${({minWidth = 0}) => minWidth + 'px'};
  display: ${({open}) => (open ? 'flex' : 'none')};
  flex-direction: column;
  transition: ${createTransitions(['opacity', 'height', 'width'], 150)};

  ${ContainerContent} {
    ${({state}) => stateStylesMap[state]};
  }
`;

const Container = ({
                     state,
                     onBlur,
                     children,
                     open,
                     buttonRef,
                     minWidth,
                     height,
                     verticalProps,
                     horizontalProps,
                   }) => {
  const ref = useRef(null);
  const handleClickOutside = useCallback(
    (event) => {
      if (
        buttonRef
          ? ref.current &&
          !ref.current.contains(event.target) &&
          !buttonRef.current.contains(event.target)
          : ref.current && !ref.current.contains(event.target)
      ) {
        onBlur && onBlur();
      }
    },
    [buttonRef, onBlur],
  );

  useEffect(() => {
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside, true);
    window.addEventListener('mousewheel', handleClickOutside, true);
    return () => {
      window.removeEventListener('mousewheel', handleClickOutside, true);
      window.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:
          verticalProps.verticalOrientation === TOP ? 'flex-end' : 'flex',
        ...verticalProps,
        ...horizontalProps,

        pointerEvents: 'none',
        minWidth: minWidth,

      }}
      ref={ref}
    >
      <ContainerRoot
        verticalOrientation={verticalProps.verticalOrientation}
        minWidth={minWidth}
        open={open}
        state={state}
        data-testid={'dupa'}
        height={height}
      >
        <ContainerContent ref={ref}>{children}</ContainerContent>
      </ContainerRoot>
    </div>
  );
};

const RootContextMenu = styled.div`
  overflow: hidden;
`;

const ContextMenu = React.forwardRef(
  (
    {
      onBlur,
      children,
      x,
      y,
      open,
      setOpen,
      closeOnClick,
      minWidth,
      topPadding = 0,
      bottomPadding = 0,
    },
    ref,
  ) => {
    const [windowWidth, windowHeight] = useWindowSize();

    const refRect = useMemo(() => {
      return ref?.current?.getBoundingClientRect();
    }, [ref?.current, windowWidth, open, ref]);

    const refX = useMemo(() => refRect?.x, [refRect]);

    const refY = useMemo(() => refRect?.y, [refRect]);

    const refHeight = useMemo(() => refRect?.height || 0, [refRect]);

    const refWidth = useMemo(() => refRect?.width || 0, [refRect]);

    const extendedWidth = useMemo(
      () => minWidth || refWidth,
      [refWidth, minWidth],
    );

    const extendedX = useMemo(() => x || refX, [x, refX]);

    const extendedY = useMemo(() => y || refY, [y, refY]);

    const leftOffset = useMemo(() => extendedX, [extendedX]);

    const rightOffset = useMemo(
      () => windowWidth - extendedX - refWidth,
      [extendedX, refWidth, windowWidth],
    );

    const topOffset = useMemo(() => extendedY, [extendedY]);

    const bottomOffset = useMemo(
      () => windowHeight - extendedY - refHeight,
      [extendedY, refHeight, windowHeight],
    );

    const horizontalOrientation = useMemo(
      () => (leftOffset >= rightOffset ? LEFT : RIGHT),
      [leftOffset, rightOffset],
    );

    const verticalOrientation = useMemo(
      () => (topOffset >= bottomOffset ? TOP : BOTTOM),
      [topOffset, bottomOffset],
    );

    const verticalProps = useMemo(() => {
      if (verticalOrientation === TOP) {
        return {
          top: 16,
          bottom: windowHeight - extendedY + topPadding,
          verticalOrientation,
        };
      } else if (verticalOrientation === BOTTOM) {
        return {
          top: extendedY + refHeight + bottomPadding || 0,
          bottom: 16,
          verticalOrientation,
        };
      }
    }, [
      verticalOrientation,
      extendedY,
      windowHeight,
      refHeight,
      bottomPadding,
      topPadding,
    ]);

    const horizontalProps = useMemo(() => {
      if (horizontalOrientation === LEFT) {
        return {
          right: clamp(16, Infinity, windowWidth - extendedX - refWidth),
          horizontalOrientation,
        };
      } else if (horizontalOrientation === RIGHT) {
        return {
          left: extendedX,
          horizontalOrientation,
        };
      }
    }, [horizontalOrientation, extendedX, windowWidth, refWidth]);

    const handleOnContextMenu = useCallback((event) => {
      event.preventDefault();
      event.stopPropagation();
    }, []);

    return <Portal>
      <RootContextMenu onContextMenu={handleOnContextMenu}>
        <TransitionGroup component={null}>
          <Transition key={open} timeout={{entering: 0, exit: 150}}>
            {(state) => open &&
              <Container
                horizontalProps={horizontalProps}
                verticalProps={verticalProps}
                onBlur={onBlur}
                open={open}
                state={state}
                buttonRef={ref}
                setOpen={setOpen}
                closeOnClick={closeOnClick}
                minWidth={extendedWidth}
              >
                {children}
              </Container>
            }
          </Transition>
        </TransitionGroup>
      </RootContextMenu>
    </Portal>;

  },
);

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;
