// global libraries
import { createSlice } from "@reduxjs/toolkit";

//==========SLICE==========

const initialState = {
  data:    null,
  pending: true,
  error:   null,
}

const chosenItemSlice = createSlice({
  name: 'chosenItem',
  initialState,
  reducers: {
    setItem: (_, { payload }) => payload,
    reset: () => initialState
  }
})

export default chosenItemSlice.reducer
export const {
  setItem,
  reset
} = chosenItemSlice.actions
