import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';

import {setChosenModule} from '../../reducers/modules';
import {Icon} from 'frontcore';
import {createTransitions} from '../../helpers';


const ModuleCard = ({module}) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(setChosenModule(module));
  }, [module]);

  return (
    <ModuleContainer onClick={handleClick}>
      {(module.installed === 'true' || window.__RUNTIME_CONFIG__[module.installed] === 'true') && (
        <InstalledWrapper>
          <Icon icon={'success-contained'} size={24} />
        </InstalledWrapper>
      )}
      {module.iconUrl ? (
        <img
          src={module.iconUrl}
          style={{
            height: module.iconHeight ?? '60px',
            width: 'auto',
          }}
        />
      ) : (
        <Icon icon={'warning'} size={24} />
      )}
      {module.title && (
        <ModuleTitle>{module.title}</ModuleTitle>)
      }
      <ModuleDescription>{module.description}</ModuleDescription>
      {module.comingSoon && (
        <ComingSoonOverlay>
          <ComingSoonTitle>
            Coming Soon!
          </ComingSoonTitle>
        </ComingSoonOverlay>
      )}
    </ModuleContainer>
  );
};

export default ModuleCard;

const InstalledWrapper = styled.div`
  position: absolute;
  fill: ${({theme}) => theme.palette.green[500]};
  top: 8px;
  left: 8px;
`;

const ModuleContainer = styled.div`
  background-color: white;
  position: relative;
  width: 300px;
  height: 180px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;
  user-select: none;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: scale(1.05) translateY(-2px);
    box-shadow: rgba(150, 150, 150, 0.2) 0px 2px 8px 0px;
  }
  overflow-y: hidden;
`;

const ModuleTitle = styled.h2`
  margin: 0;
  padding: 0;
  color: #3E4651;
  font-family: Roboto,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;

`;

const ModuleDescription = styled.p`
  color: grey;
  font-family: Roboto,serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.15px;

  display: block;
  margin-bottom: 16px;
`;

const ComingSoonOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: ${createTransitions(['opacity'], 300)};

  ${ModuleContainer}:hover & {
    opacity: 1
  }
`;

const ComingSoonTitle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(105%);
  color: #3E4651;
  font-family: Roboto, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  transition: ${createTransitions(['transform'], 300)};

  ${ModuleContainer}:hover & {
    transform: translateY(0);
  }
`;

