import StatusIndicator from '../../../../components/StatusIndicator'
import {propOr} from 'ramda'
import {EMPTY_STRING} from '../../../../constants'

const STATE_MAPPING = Object.freeze({
  ENABLED: 'success',
  DISABLED: 'error',
})

const LABEL_MAPPING = Object.freeze({
  ENABLED: 'ACTIVE',
  DISABLED: 'ARCHIVED',
})

const ScheduleState = ({ value = EMPTY_STRING }) => {
  const variant = propOr('default', value, STATE_MAPPING)
  const label = propOr(value, value, LABEL_MAPPING)

  return <StatusIndicator
    value={label}
    variant={variant}
  />
}

export default ScheduleState
