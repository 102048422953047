import {useCallback} from 'react'
import {always, pipe, when} from 'ramda'
import {and, fork} from 'fluture'
import {useNavigate} from 'react-router-dom'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import RolesButtons from '../index'
import {deleteRole} from '../../../../../api/security/deleteRole'
import {enableRole} from '../../../../../api/security/enableRole'
import {disableRole} from '../../../../../api/security/disableRole'

const RolesSingleButtons = ({
  pending,
  data,
  texts,
  getFuture,
  redirect = true,
}) => {
  const {id} = data

  const navigate = useNavigate()

  const {createNotification} = useNotificationContext()

  const deleteGroupFuture = useFuture(deleteRole)
  const enableGroupFuture = useFuture(enableRole)
  const disableGroupFuture = useFuture(disableRole)

  const handleOnEnable = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            message: 'Failed to enable Group:' + message,
            autoHide: false,
            variant: 'error',
          })
        })(() => {
          createNotification({
            message: 'Group enable successfully',
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        enableGroupFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnDisable = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            message: 'Failed to enable Group:' + message,
            autoHide: false,
            variant: 'error',
          })
        })(() => {
          createNotification({
            message: 'Group enable successfully',
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        disableGroupFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnDelete = useCallback(
    ({id}) => {
      pipe(
        when(always(!redirect), and(getFuture)),
        fork(({message}) => {
          createNotification({
            message: 'Failed to delete Group:' + message,
            autoHide: false,
            variant: 'error',
          })
        })(() => {
          redirect && navigate('/security/list/groups')
          createNotification({
            message: 'Group deleted successfully',
            autoHide: true,
            variant: 'success',
          })
        })
      )(
        deleteGroupFuture({
          params: [{key: 'id', value: id}],
        })
      )
    },
    [getFuture, redirect]
  )

  const handleOnEdit = useCallback(
    ({id}) => navigate('/security/edit/role/' + id),
    []
  )

  return (
    <RolesButtons
      texts={texts}
      id={id}
      data={data}
      pending={pending}
      onEnable={handleOnEnable}
      onDisable={handleOnDisable}
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
    />
  )
}

export default RolesSingleButtons
