import {path} from 'ramda'
import {memo, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ToolTip from '../../../components/toolTip'
import { DropDownButton, Button, Icon } from 'frontcore'
import DropDownButtonContentLayout from '../../../components/layouts/dropDownButtonContentLayout'
import {sidebarRemoveQuery} from '../../../reducers/sql/sidebar'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import useFuture from '../../../hooks/useFuture'
import deleteSQLStatement from '../../../api/sql/deleteSQLStatement'
import {fork} from 'fluture'

const EditorDeleteQuery = () => {
  const chosenQuery = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryDetails = useMemo(() => {
    return queries.find(({id}) => {
      return id === chosenQuery
    })
  }, [chosenQuery, queries])

  const { createNotification } = useNotificationContext()
  const dispatch = useDispatch()
  const deleteSQLStatementFuture = useFuture(deleteSQLStatement)
  const handleDeleteQuery = useCallback(() => {
    if (queryDetails.local) {
      dispatch(sidebarRemoveQuery(queryDetails.id))
      createNotification({
        title: 'Success',
        message: `Deleted local query "${queryDetails.name}"`,
        variant: 'success',
        autoHide: true,
      })
      return
    }
    const future = deleteSQLStatementFuture({
      params: [{ key: 'queryId', value: queryDetails.id }]
    })
    fork(({ message }) => {
      createNotification({
        title: 'Error',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(() => {
      dispatch(sidebarRemoveQuery(queryDetails.id))
      createNotification({
        title: 'Success',
        message: `Deleted remote query "${queryDetails.name}"`,
        variant: 'success',
        autoHide: true,
      })
    })(future)
  }, [queryDetails])

  return (
    <ToolTip
      config={{position: 'bottom_right'}}
      content={
        <div style={{padding: 8}}>
          Permanent delete both locally and remotely
        </div>
      }
    >
      <DropDownButton
        ContentComponent={({closeFn}) => (
          <DropDownButtonContentLayout>
            <div style={{fontSize: 16}}>
              Delete query: {chosenQuery.name}
            </div>
            <div style={{fontSize: 12}}>
              Are you sure you want to delete this query?
            </div>
            <div
              style={{
                display: 'flex',
                gap: 8,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color={'error'}
                tabIndex={-1}
                onClick={() => {
                  handleDeleteQuery()
                  closeFn()
                }}
              >
                Yes
              </Button>
              <Button
                tabIndex={-1}
                variant={'outlined'}
                onClick={() => {
                  closeFn()
                }}
              >
                No
              </Button>
            </div>
          </DropDownButtonContentLayout>
        )}
        buttonProps={{
          square: true,
          color: 'error',
          StartComponent: Icon,
          justifyContent: 'center',
          startComponentProps: {
            icon: 'trash',
            size: 20,
          },
        }}
      />
    </ToolTip>
  )
}

export default memo(EditorDeleteQuery)
