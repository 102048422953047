import DateCell from '../../dateCell'
import NameCell from '../../nameCell'
import ScheduleState from '../../WorkloadsDetailsView/WorkloadsScheduleDetailsView/ScheduleState'
import ExplainCRON from './ExplainCRON'
import {CountMiniature} from '../../../../components/miniatures'

const schema = Object.freeze([
  {
    value: 'name',
    label: 'Name',
    size: 0.8,
    Component: NameCell,
  },
  {
    value: 'id',
    label: 'Id',
    size: 1,
    Component: NameCell,
  },
  {
    value: 'language',
    label: 'Language',
    size: 0.7,
  },
  {
    value: 'jobsCount',
    label: 'Jobs',
    size: 0.5,
    Component: CountMiniature,
    componentProps: {
      type: 'job'
    }
  },
  {
    value: 'state',
    label: 'State',
    size: 0.5,
    Component: ScheduleState,
  },
  {
    value: 'startTime',
    label: 'Start Time',
    size: 1,
    Component: DateCell,
  },
  {
    value: 'endTime',
    label: 'End Time',
    size: 1,
    Component: DateCell,
  },
  {
    value: 'cron',
    label: 'CRON',
    size: 1.5,
    enableSort: false,
    Component: ExplainCRON,
  },
])

export default schema
