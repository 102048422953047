import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import useBoundingSize from '../../hooks/useBoundingSize'
import {Button, Icon} from 'frontcore'
import styled, {css} from 'styled-components'
import {always, cond, equals, prop, T} from 'ramda'
import {createTransitions} from '../../helpers'

const enteringStateStyles = css`
  opacity: 1;
  height: ${({height}) => height + 8 + 'px'};
`

const enteredStateStyles = css`
  opacity: 1;
  height: ${({height}) => height + 8 + 'px'};
`

const exitingStateStyles = css`
  opacity: 0;
  height: 0;
`

const exitedStateStyles = css`
  opacity: 0;
  height: 0;
`

const containerStateStylesMap = {
  entering: enteringStateStyles,
  entered: enteredStateStyles,
  exiting: exitingStateStyles,
  exited: exitedStateStyles,
}

const Root = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  width: 384px;
  color: white;
  pointer-events: all;
  overflow: hidden;
  box-sizing: border-box;
  ${({state}) => containerStateStylesMap[state]};
  transition: ${createTransitions([
    'top',
    'opacity',
    'width',
    'margin',
    'height',
  ])};
`

const Content = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, serif;
  letter-spacing: 0.25px;
  color: ${({theme}) => theme.palette.neutral[500]};
`

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  font-family: Roboto, serif;
  letter-spacing: 0.25px;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const warningStyles = css`
  border-color: ${({theme}) => theme.palette.yellow[600]};
  color: ${({theme}) => theme.palette.yellow[600]};
  fill: ${({theme}) => theme.palette.yellow[600]};
`

const errorStyles = css`
  border-color: ${({theme}) => theme.palette.red[600]};
  color: ${({theme}) => theme.palette.red[600]};
  fill: ${({theme}) => theme.palette.red[600]};
`

const successStyles = css`
  border-color: ${({theme}) => theme.palette.green[600]};
  color: ${({theme}) => theme.palette.green[600]};
  fill: ${({theme}) => theme.palette.green[600]};
`

const informationStyles = css`
  border-color: ${({theme}) => theme.palette.primary[600]};
  color: ${({theme}) => theme.palette.primary[600]};
  fill: ${({theme}) => theme.palette.primary[600]};
`

const MiddleContainer = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Container = styled.div`
    background: ${({theme}) => theme.palette['surface-primary']};
    border: 1px solid black;
    border-radius: 4px;
    display: flex;
    width: 100%;
    color: white;
    pointer-events: all;
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 16px;
    gap: 16px;
    letter-spacing: 0.1px;
    ${({variant, theme}) =>
            cond([
                [equals('warning'), always(warningStyles)],
                [equals('error'), always(errorStyles)],
                [equals('success'), always(successStyles)],
                [equals('information'), always(informationStyles)],
                [T, always(informationStyles)],
            ])(variant)};
    justify-content: space-between;
`

const duration = 5000

const iconMap = {
  success: 'success-contained',
  error: 'error-contained',
  warning: 'alert-contained',
  information: 'info-contained',
}

export const Notification = ({
  id,
  title,
  message,
  autoHide,
  closeNotification,
  variant,
  transition,
  index,
  state,
}) => {
  let timeout = useRef(0)
  useEffect(() => {
    if (autoHide) {
      timeout.current = window.setTimeout(() => {
        closeNotification(id)
      }, duration)
    }
  }, [timeout])

  const contentRef = useRef()

  const {height = 0} = useBoundingSize(contentRef)

  const icon = useMemo(
    () => prop(variant, iconMap),
    [variant]
  )

  const handleOnClose = useCallback(
    () => closeNotification(id),
    [id]
  )

  return (
    <Root
      state={state}
      index={index}
      variant={variant}
      style={transition}
      data-testid={'snackbar'}
      height={height}
    >
      <Container ref={contentRef} variant={variant}>
        <Icon icon={icon} size={24} />
        <MiddleContainer>
          <Title>{title || variant}</Title>
          <Content>{message}</Content>
        </MiddleContainer>
        <ButtonContainer>
          <Button
            variant={'text'}
            onClick={handleOnClose}
            Component={Icon}
            componentProps={{
              icon: 'close',
              size: 18,
            }}
            tabIndex={-1}
            square
            size={'small'}
            justifyContent={'center'}
          />
        </ButtonContainer>
      </Container>
    </Root>
  )
}

export default Notification
