import {
  __,
  addIndex,
  allPass,
  complement,
  cond,
  divide,
  equals,
  gte,
  has,
  ifElse,
  is,
  isEmpty,
  isNil,
  join,
  lt,
  map,
  or,
  pathEq,
  pipe,
  prop,
  replace,
  split,
  toPairs,
  toUpper,
} from 'ramda'

export const isNotNull = complement(isNil)
export const isNotEmpty = complement(isEmpty)
export const notEquals = complement(equals)
export const pathNotEq = complement(pathEq)
export const isArray = is(Array)
export const mapWithKey = addIndex(map)

const hasName = has('name')

const hasGroupName = has('groupName')
const hasNodeName = has('nodeName')

export const isNodeKey = allPass([
  hasGroupName,
  hasNodeName,
])

export const isGroupKey = hasName

export const createTransitions = (
  array,
  duration = 150,
  delay = 0
) => {
  const easing = 'cubic-bezier(0,.25,.75,1)'
  return pipe(
    map((item) =>
      join(' ', [
        item,
        duration + 'ms',
        easing,
        delay + 'ms',
      ])
    ),
    join(', ')
  )(array)
}

export const formatDuration = (time) => {
  const dayDurationInMilliseconds = 1000 * 3600 * 24
  const hourDurationInMilliseconds = 1000 * 3600
  const minuteDurationInMilliseconds = 1000 * 60
  return cond([
    [
      pipe(
        divide(__, dayDurationInMilliseconds),
        gte(__, 1)
      ),
      pipe(
        divide(__, dayDurationInMilliseconds),
        Math.floor,
        ifElse(
          equals(1),
          (number) => number + ' day ago',
          (number) => number + ' days ago'
        )
      ),
    ],
    [
      pipe(
        divide(__, hourDurationInMilliseconds),
        gte(__, 1)
      ),
      pipe(
        divide(__, hourDurationInMilliseconds),
        Math.floor,
        ifElse(
          equals(1),
          (number) => number + ' hour ago',
          (number) => number + ' hours ago'
        )
      ),
    ],
    [
      pipe(
        divide(__, minuteDurationInMilliseconds),
        gte(__, 1)
      ),
      pipe(
        divide(__, minuteDurationInMilliseconds),
        Math.floor,
        ifElse(
          equals(1),
          (number) => number + ' minute ago',
          (number) => number + ' minutes ago'
        )
      ),
    ],
    [
      pipe(lt(__, 60)),
      pipe(
        ifElse(
          equals(1),
          (number) => number + ' second ago',
          (number) => number + ' seconds ago'
        )
      ),
    ],
  ])(time)
}

export const formatDurationVerbose = (time) => {
  const millisecondsInSecond = 1000
  const secondsInMinute = 60
  const minutesInHour = 60
  const hoursInDay = 24
  const a_seconds = time / millisecondsInSecond
  const a_minutes = a_seconds / secondsInMinute
  const a_hours = a_minutes / minutesInHour
  const a_days = a_hours / hoursInDay
  const days = Math.floor(a_days)
  const hours = Math.floor(a_hours) % hoursInDay
  const minutes = Math.floor(a_minutes) % minutesInHour
  const seconds = Math.floor(a_seconds) % secondsInMinute
  const milliseconds = time % millisecondsInSecond

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  }
}

export const toBoolean = (x) =>
  typeof x !== 'string' ? !!x : x.toLowerCase() !== 'false'

export const calculateLastUpdate = (modifiedAt) => {
  const then = modifiedAt

  const now = new Date().getTime()

  const time = now - then

  return formatDuration(time)
}

export const formatDate = (date = 0) => {
  const aaa = new Date(date)

  return pipe(
    (date) => date.toISOString(),
    split('T'),
    (date) => {
      const first = pipe(split('-'), join('/'))(date[0])
      const second = pipe(split('.'), prop(0))(date[1])

      return [first, second]
    }
  )(aaa)
}

export const onSubmitFilesFn = (data) =>
  map((a) => {
    const blob = new Blob([a.data])
    const name = a.filename
    return [blob, name]
  }, data || [])

export const onSubmitSparkParamsFn = pipe(
  or(__, []),
  map(({key, value}) => key + '=' + value),
  join(';')
)
export const onSubmitParametersFn = pipe(
  or(__, []),
  map(({value}) => value),
  join(';')
)

export const getBaseUrl = (lineage) => {
  const isDev = process.env.NODE_ENV === 'development'
  let base
  if (isDev) {
    base = lineage
      ? window.__RUNTIME_CONFIG__.ILUM_LINEAGE_PATH
      : process.env.REACT_APP_BASE_URL
  } else {
    base = lineage
      ? window.__RUNTIME_CONFIG__.ILUM_LINEAGE_PATH
      : `${window.location.protocol}//${window.location.hostname}:${window.location.port}/core`
  }
  return base
}

export const camelCaseToWhiteSpace = pipe(
  replace(/([a-z])([A-Z])/g, '$1 $2'),
  replace(/^./, toUpper)
)

export const convertObjectToArray = (
  array,
  keyProp = 'key',
  valueProp = 'value'
) =>
  pipe(
    toPairs,
    map((a) => ({[keyProp]: a[0], [valueProp]: a[1]}))
  )(array)

export const convertObjectToArrayFn =
  (keyProp = 'key', valueProp = 'value') =>
  (array) =>
    pipe(
      toPairs,
      map((a) => ({[keyProp]: a[0], [valueProp]: a[1]}))
    )(array)

const units =     [
  'B',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
]
export const formatBytes = (value = 0) => {
  let unitIndex = 0
  while (unitIndex < units.length && value >= 1024) {
    value /= 1024
    unitIndex++
  }

  return `${value.toFixed(1)} ${units[unitIndex]}`
}

export function formatISODuration(isoDuration) {
  try {
    const regex = /P(?:([0-9]+)D)?T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9.]+)S)?/;
    const match = isoDuration.match(regex);

    if (!match) {
      throw new Error("Invalid ISO 8601 duration");
    }

    const days = parseInt(match[1] || 0, 10);
    const hours = parseInt(match[2] || 0, 10);
    const minutes = parseInt(match[3] || 0, 10);

    let readable = [];
    if (days > 0) readable.push(`${days} day${days > 1 ? 's' : ''}`);
    if (hours > 0) readable.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    if (minutes > 0) readable.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);

    return readable.join(', ');
  } catch (error) {
    return isoDuration;
  }
}
