import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  any,
  assoc,
  complement,
  find,
  fromPairs,
  isEmpty,
  isNil,
  lensPath,
  map,
  mergeAll,
  pipe,
  propOr,
  reject,
  set,
  toPairs,
  values,
} from 'ramda'
import {isNotNull, notEquals} from '../../helpers'
import styled from 'styled-components'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants'

const Root = styled.form`
  width: 100%;
  height: 100%;
`

const Form = ({
  autoComplete = 'on',
  schema,
  onSubmit,
  LayoutComponent,
  layoutComponentProps = EMPTY_OBJECT,
  initialData,
  options,
}) => {
  const [fields, setFields] = useState(EMPTY_OBJECT)

  const [errors, setErrors] = useState(EMPTY_OBJECT)

  const [touched, setTouched] = useState(EMPTY_OBJECT)

  const extendedSchema = useMemo(() => {
    return schema(fields, options)
  }, [fields, options, schema])

  const fff = useMemo(
    () => propOr(EMPTY_ARRAY, 'fields', extendedSchema),
    [extendedSchema]
  )

  const bbb = useMemo(
    () => propOr(EMPTY_ARRAY, 'buttons', extendedSchema),
    [extendedSchema]
  )

  const handleSetErrors = useCallback(
    (value) => {
      if (notEquals(value, errors)) {
        setErrors(value)
      }
    },
    [errors]
  )

  const handleSetTouched = useCallback(
    (value) => {
      if (notEquals(value, errors)) {
        setTouched(value)
      }
    },
    [errors]
  )

  const tempFields = useMemo(() => pipe(
    map(({id, defaultValue}) => [
      id,
      isNotNull(fields[id]) ? fields[id] : defaultValue,
    ]),
    fromPairs
  )(fff), [fff, fields])

  useEffect(() => {
    if (initialData) {
      setFields(initialData)
    }
  }, [initialData])

  const handleOnChange = useCallback(
    ({id, value}) => {
      const result = assoc(id, value, fields)
      const {validators = [], onChangeFn} = find((a) => a.id === id)(
        extendedSchema.fields
      )
      const errorsResult = pipe(
        map((validator) => validator(value)),
        reject(isEmpty),
        reject(isNil)
      )(validators)
      const newErrors = assoc(id, errorsResult, errors)
      handleSetErrors(newErrors)
      const newTouched = assoc(id, true, touched)
      handleSetTouched(newTouched)
      if (onChangeFn) {
        const a = onChangeFn(id, value, result)
        setFields(a)
      } else {
        setFields(result)
      }
    },
    [
      fields,
      setFields,
      touched,
      errors,
      handleSetErrors,
      handleSetTouched,
    ]
  )

  const extendedFields = pipe(
    map((field) => {
      const {id} = field
      const valueLens = lensPath([
        'componentProps',
        'value',
      ])
      const onChangeLens = lensPath([
        'componentProps',
        'onChange',
      ])
      const errorLens = lensPath([
        'componentProps',
        'errors',
      ])

      const onChange = (value) => {
        handleOnChange({id, value})
      }

      const value = tempFields[id]
      const e = touched[id] ? errors[id] : []

      return pipe(
        set(valueLens, value),
        set(errorLens, e),
        set(onChangeLens, onChange),
        (props) => ({
          ...props,
          element: (
            <props.Component
              {...props.componentProps}
              name={'form'}
            />
          ),
        })
      )(field)
    })
  )(fff)

  const extendedButtons = useMemo(
    () =>
      map(
        ({Component, componentProps}) => (
          <Component {...componentProps} />
        ),
        bbb
      ),
    [bbb]
  )

  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      const result = map(({validators = [], id}) => {
        const value = tempFields[id]
        const errors = pipe(
          map((validator) => validator(value)),
          reject(isEmpty)
        )(validators)
        return {id, errors}
      }, fff)

      const newErrors = pipe(
        map(({id, errors}) => ({[id]: errors})),
        mergeAll
      )(result)
      handleSetErrors(newErrors)
      const newTouched = pipe(
        map(({id}) => ({[id]: true})),
        mergeAll
      )(fff)
      handleSetTouched(newTouched)
      const hasErrors = pipe(
        values,
        any(complement(isEmpty))
      )(newErrors)

      if (!hasErrors) {
        const result = pipe(
          toPairs,
          map(([key, value]) => {
            const {onSubmitFn = (a) => a} = find(
              ({id}) => id === key,
              extendedFields
            )
            return {[key]: onSubmitFn(value)}
          }),
          mergeAll
        )(tempFields)

        onSubmit(result)
      }
    },
    [
      extendedSchema,
      touched,
      onSubmit,
      fields,
      tempFields,
      extendedFields,
    ]
  )

  return (
    <Root
      onSubmit={handleOnSubmit}
      autoComplete={autoComplete}
    >
      <LayoutComponent
        {...layoutComponentProps}
        fields={extendedFields}
        buttons={extendedButtons}
      />
    </Root>
  )
}

export default Form
