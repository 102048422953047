import React from 'react'
import StatusIndicator from '../StatusIndicator'

const variantMap = {
  ACTIVE: 'information',
  ARCHIVED: 'default',
}

const ServiceState = ({value}) => (
  <StatusIndicator value={value} variant={variantMap[value]}/>
);

export default ServiceState;