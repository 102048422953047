import {ResizeLayout} from 'frontcore'
import Line from '../line'
import {VERTICAL} from '../../constants'
import {Outlet} from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`
const TabsContainer = styled.div`
  padding: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  gap: 16px;
`
const MetricsContainer = styled.div`
  position: relative;
  display: flex;
  padding: ${({$show}) => ($show ? '16px 16px' : 0)};
  box-sizing: border-box;
`

const ListViewLayout = ({tabsNode, metricsNode}) => {
  return (
    <Root>
      <TabsContainer>{tabsNode}</TabsContainer>
      <Line />
      <ResizeLayout
        draggable={false}
        configuration={{
          orientation: VERTICAL,
          firstNode: {
            measurement: 'px',
            value: 210,
            max: 210,
          },
          secondNode: {
            measurement: 'flex',
            value: 1,
          },
        }}
        firstNode={
          <MetricsContainer>{metricsNode}</MetricsContainer>
        }
        secondNode={<Outlet />}
      />
    </Root>
  )
}

export default ListViewLayout