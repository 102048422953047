import styled, {css} from 'styled-components';
import {path, pathOr, prop} from 'ramda';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';


import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../constants';
import {closeOverlay} from '../../reducers/modules';
import {Button, Icon} from 'frontcore';
import {createTransitions} from '../../helpers';

const InformationOverlay = () => {
  const chosenModule = useSelector(pathOr(EMPTY_OBJECT, ['modules', 'chosenModule']));
  const overlayOpened = useSelector(path(['modules', 'overlayOpened']));

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeOverlay());
  }, EMPTY_ARRAY);

  const handleInstallClick = useCallback(() => {
    window.open('https://ilum.cloud/docs/production/', '_blank').focus();
  }, EMPTY_ARRAY);

  const iconUrl = prop('iconUrl', chosenModule);


  return (
    <Root opened={overlayOpened}>
      <CloseWrapper>
        <Button
          onClick={handleClose}
          tabIndex={-1}
          size={'small'}
          variant={'text'}
          Component={Icon}
          componentProps={{
            icon: 'close',
          }}
          square
        />
      </CloseWrapper>

      <div style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {iconUrl && (
          <img
            src={iconUrl}
            style={{
              height: `calc(${chosenModule.iconHeight ?? '60px'} * 2)`,
              width: 'auto',
            }}
          />
        )}
      </div>
      <div style={{display: 'flex', gap: 16}}>
        {chosenModule.comingSoon && (
          <ComingSoon>Coming Soon!</ComingSoon>
        )}
        {(chosenModule.installed === 'true' || window.__RUNTIME_CONFIG__[chosenModule.installed] === 'true') && (
          <InstalledBadge>Installed</InstalledBadge>
        )}
        {chosenModule.installed === 'false' && !chosenModule.comingSoon &&
        <Button
          onClick={handleInstallClick}
        >
          Install
        </Button>}
      </div>
      <div style={{flex: 1}}>
        {chosenModule.name && (
          <ModuleTitle>{chosenModule.name}</ModuleTitle>
        )}
        {(chosenModule.fullDescription || chosenModule.description) && (
          <ModuleDescription>
            {chosenModule.fullDescription ?? chosenModule.description}
          </ModuleDescription>
        )}
      </div>
      {chosenModule.website && (
        <WebsiteTip website={chosenModule.website} />
      )}
    </Root>
  );
};

export default InformationOverlay;

const WebsiteTip = ({website}) => {
  const handleOpenWebsite = useCallback(() => {
    window.open(website, '_blank').focus();
  }, [website]);

  return (
    <WebsiteContainer onClick={handleOpenWebsite}>
      <WebsiteLabel>Website: </WebsiteLabel>
      <WebsiteUrl>{website}</WebsiteUrl>
    </WebsiteContainer>
  );
};

const WebsiteLabel = styled.div`
  color: ${({theme}) => theme.palette.green[600]};
  font-size: 16px;
`;

const WebsiteUrl = styled.div`
  font-size: 18px;
  text-decoration: underline;
  color: ${({theme}) => theme.palette.green[600]};
`;

const WebsiteContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  user-select: none;
  background-color: ${({theme}) => theme.palette.green[200]};
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: ${({theme}) => theme.palette.green[600]};
  padding: 16px;
  box-sizing: border-box;
  font-family: Roboto, serif;
  transition: ${createTransitions(['background-color'])};
  &:hover {
    background-color: ${({theme}) => theme.palette.green[300]};
  }
  &:active {
    background-color: ${({theme}) => theme.palette.green[400]};
  }
`;

const ModuleDescription = styled.div`
  color: ${({theme}) => theme.palette.neutral[500]};
  font-family: Roboto,serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.15px;

  margin-top: 24px;
  text-align: justify;
  word-wrap: break-word;
`;

const ModuleTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: #3E4651;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

const text = css`
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  border-radius: 12px;
  user-select: none;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
`;

const InstalledBadge = styled.div`
  ${text};
  color: ${({theme}) => theme.palette.green[600]};
  background-color: ${({theme}) => theme.palette.green[200]};
  border-color: ${({theme}) => theme.palette.green[600]};
`;

const ComingSoon = styled.div`
  ${text};
  color: ${({theme}) => theme.palette.neutral[600]};
  background-color: ${({theme}) => theme.palette.neutral[200]};
  border-color: ${({theme}) => theme.palette.neutral[500]};
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 500px;
  z-index: 100;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.05) 0 4px 16px, rgba(17, 17, 26, 0.05) 0 8px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 16px;
  transition: ${createTransitions(['transform'])};
  transform: ${({opened}) => (opened ? 'translateX(0)' : 'translateX(100%)')};
`;
