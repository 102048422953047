import {filter, lensProp, map, omit, pick, pipe, prop, propEq, set} from 'ramda'
import schema from '.'

const nestMemorySettings = (params) => {
  const fieldNames = pipe (
    schema,
    filter(propEq('Memory', 'section')),
    map(prop('id'))
  )({ dynamicAllocation: true })

  const memorySettings = pick(fieldNames, params)
  const settingsLens = lensProp('memorySettings')

  return pipe (
    omit(fieldNames),
    set (
      settingsLens, 
      JSON.stringify(memorySettings),
    )
  ) (params)
}

export default nestMemorySettings
