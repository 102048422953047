import React, {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  __,
  clamp,
  cond,
  divide,
  equals,
  head,
  pipe,
  prop,
  propOr,
  split,
} from 'ramda'
import {createTransitions, notEquals} from '../../helpers'
import useWindowSize from '../../hooks/useWindowSize'
import {
  Transition,
  TransitionGroup,
} from 'react-transition-group'
import styled, {css} from 'styled-components'
import {BOTTOM, LEFT, RIGHT, TOP} from '../../constants'
import useDebounce from '../../hooks/useDebounce'
import {Portal, useInterval} from 'frontcore'
import Line from '../line'
import useBoundingSize from '../../hooks/useBoundingSize'
import FadeTransition from '../transitions/fadeTransition'

const ARROW_SIZE = 5
const HALF_ARROW_SIZE = ARROW_SIZE / 2

const Root = styled.div`
  font-size: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`

const entering = css`
  opacity: 0;
`
const entered = css`
  opacity: 1;
`
const exiting = css`
  opacity: 0;
`
const exited = css`
  opacity: 0;
`

const stateMap = {
  entering,
  entered,
  exiting,
  exited,
}

const borderWidth = '8px'
const arrowCss = css`
  content: '';
  position: absolute;
`

const arrowDownStyles = css`
  ::before {
    ${arrowCss};
    border-left: ${borderWidth} solid transparent;
    border-right: ${borderWidth} solid transparent;
    top: 100%;
    left: 16px;
    margin-left: -${borderWidth};
    border-top: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['border-tooltip']}`};
    margin-top: 1px;
  }

  ::after {
    ${arrowCss};
    border-left: ${borderWidth} solid transparent;
    border-right: ${borderWidth} solid transparent;
    top: 100%;
    left: 16px;
    margin-left: -${borderWidth};
    border-top: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['surface-tooltip']}`};
    margin-top: -0.5px;
    z-index: 1;
  }
`

const arrowLeftStyles = css`
  ::before {
    ${arrowCss};
    border-top: ${borderWidth} solid transparent;
    border-bottom: ${borderWidth} solid transparent;
    top: 16px;
    left: 0;
    margin-top: -${borderWidth};
    margin-left: calc(-${borderWidth} - 1px);
    border-right: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['border-tooltip']}`};
  }

  ::after {
    ${arrowCss};
    border-top: ${borderWidth} solid transparent;
    border-bottom: ${borderWidth} solid transparent;
    top: 16px;
    left: 0;
    margin-top: -${borderWidth};
    margin-left: calc(-${borderWidth} + 0.5px);
    border-right: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['surface-tooltip']}`};
    z-index: 1;
  }
`

const arrowUpStyles = css`
  ::before {
    ${arrowCss};
    border-left: ${borderWidth} solid transparent;
    border-right: ${borderWidth} solid transparent;
    top: 0;
    left: 16px;
    margin-left: -${borderWidth};
    margin-top: calc(-${borderWidth} - 1px);
    border-bottom: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['border-tooltip']}`};
  }

  ::after {
    ${arrowCss};
    border-left: ${borderWidth} solid transparent;
    border-right: ${borderWidth} solid transparent;
    top: 0;
    left: 16px;
    margin-left: -${borderWidth};
    border-bottom: ${borderWidth} solid white;
    margin-top: calc(-${borderWidth} + 0.5px);
    border-bottom: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['surface-tooltip']}`};
    z-index: 1;
  }
`

const arrowRightStyles = css`
  ::before {
    ${arrowCss};
    border-top: ${borderWidth} solid transparent;
    border-bottom: ${borderWidth} solid transparent;
    top: 16px;
    left: 100%;
    margin-top: -${borderWidth};
    margin-left: 1px;
    border-left: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['border-tooltip']}`};
  }

  ::after {
    ${arrowCss};
    border-top: ${borderWidth} solid transparent;
    border-bottom: ${borderWidth} solid transparent;
    top: 16px;
    left: 100%;
    margin-top: -${borderWidth};
    margin-left: -0.5px;
    border-left: ${({theme}) =>
      `${borderWidth} solid ${theme.palette['surface-tooltip']}`};
    z-index: 1;
  }
`

const arrowDirectionStylesMap = {
  left: arrowRightStyles,
  right: arrowLeftStyles,
  top: arrowDownStyles,
  bottom: arrowUpStyles,
}

const Container = styled.div`
  pointer-events: all;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-color: ${({theme}) =>
    theme.palette['border-tooltip']};
  background-color: ${({theme}) =>
    theme.palette['surface-tooltip']};
  color: ${({theme}) => theme.palette.text.tertiary};
  border-radius: 3px;
  transition: ${createTransitions([
    'opacity',
    'top',
    'left',
    'bottom',
    'right',
  ])};
  ${({state}) => stateMap[state]};

  ${({toolTipCoordinates}) => toolTipCoordinates['text']}

`

const ToolTip = ({children, config, content, lockable = false}) => {

  const ref = useRef()
  const rect = useBoundingSize(ref)

  const height = propOr(0, 'height', rect)
  const width = propOr(0, 'width', rect)

  const [windowWidth, windowHeight] = useWindowSize()

  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0,
  })
  const [open, setOpen] = useState(false)

  const handleMouseOver = (event) => {
    const rect =
      ref.current && ref.current.getBoundingClientRect()
    const x = propOr(0, 'x', rect)
    const y = propOr(0, 'y', rect)
    const result = {x, y}
    if (notEquals(result, coordinates)) {
      setCoordinates({x, y})
    }
    if (event.ctrlKey) {
      setLock(true)
    }
    setOpen(true)
  }

  const [lock, setLock] = useState(false)


  const extendedOpen = lockable ? lock || open : open

  const handleMouseLeave = (event) => {
    if (lockable && event.ctrlKey) {
    } else {
      setOpen(false)
    }
  }
  useEffect(() => {
    const node = ref.current

    if (node) {
      window.addEventListener('keyup', (event) => {
        if (event.key === 'Control') {
          if (contentRef.current) {
            setLock(true)
          }
        }
        if (event.key === 'Escape') {
          event.preventDefault()
          setLock(false)
        }
      })
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseleave', handleMouseLeave)
    }
    return () => {
      node?.removeEventListener('mouseover', handleMouseOver)
      node?.removeEventListener(
        'mouseleave',
        handleMouseLeave
      )
    }
  }, [ref.current, open])

  const left = coordinates.x
  const right = windowWidth - (left + width)
  const top = coordinates.y
  const bottom = windowHeight - (top + height)

  const verticalPosition = top > bottom ? TOP : BOTTOM
  const verticalFreeSpace =
    verticalPosition === TOP ? top : bottom

  const horizontalPosition = left > right ? LEFT : RIGHT
  const horizontalFreeSpace =
    horizontalPosition === LEFT ? left : right

  const position =
    verticalFreeSpace > horizontalFreeSpace
      ? verticalPosition + '_' + horizontalPosition
      : horizontalPosition + '_' + verticalPosition

  const extendedPosition = propOr(
    position,
    'position',
    config
  )

  const debouncedOpen = useDebounce(open, 1)

  const contentRef = useRef()

  const contentRect = useMemo(
    () =>
      contentRef?.current &&
      contentRef?.current?.getBoundingClientRect(),
    [debouncedOpen]
  )

  const contentWidth = useMemo(
    () => prop('width', contentRect),
    [contentRect]
  )
  const contentHeight = useMemo(
    () => prop('height', contentRect),
    [contentRect]
  )

  const halfHeight = useMemo(() => height / 2, [height])
  const arrowVerticalOffset = useMemo(
    () => clamp(0, Infinity, halfHeight - HALF_ARROW_SIZE),
    [halfHeight, HALF_ARROW_SIZE]
  )
  const arrowY = useMemo(
    () =>
      pipe(
        divide(__, 2),
        clamp(0, arrowVerticalOffset)
      )(contentHeight),
    [arrowVerticalOffset, contentHeight]
  )

  const halfWidth = useMemo(() => width / 2, [width])
  const arrowHorizontalOffset = useMemo(
    () => clamp(0, Infinity, halfWidth),
    [halfWidth, HALF_ARROW_SIZE]
  )
  const arrowX = useMemo(
    () =>
      pipe(
        divide(__, 2),
        clamp(0, arrowHorizontalOffset)
      )(contentWidth),
    [arrowHorizontalOffset, contentWidth]
  )

  const countArrowHorizontalLeftRightPosition = useMemo(
    () =>
      contentWidth > width
        ? arrowX - HALF_ARROW_SIZE
        : arrowX + HALF_ARROW_SIZE,
    [contentWidth, width, arrowX, HALF_ARROW_SIZE]
  )

  const toolTipCoordinates = useMemo(
    () =>
      cond([
        [
          equals('right_bottom'),
          () => ({
            text: {
              top: coordinates.y + 'px',
              bottom: 'auto',
              right: 'auto',
              left: coordinates.x + 8 + width + 'px',
            },
            arrow: {
              top: arrowY - HALF_ARROW_SIZE + 'px',
              left: -10 + 'px',
            },
          }),
        ],
        [
          equals('left_bottom'),
          () => ({
            text: {
              bottom: 'auto',
              top: coordinates.y + 'px',
              right: windowWidth - coordinates.x + 8 + 'px',
              left: 'auto',
            },
            arrow: {
              top: arrowY - HALF_ARROW_SIZE + 'px',
              left: '100%',
            },
          }),
        ],
        [
          equals('left_top'),
          () => ({
            text: {
              bottom:
                windowHeight -
                coordinates.y -
                height +
                'px',
              top: 'auto',
              right: windowWidth - coordinates.x + 8 + 'px',
              left: 'auto',
            },
            arrow: {
              bottom: arrowY - HALF_ARROW_SIZE + 'px',
              left: '100%',
            },
          }),
        ],
        [
          equals('right_top'),
          () => ({
            text: {
              bottom:
                windowHeight -
                coordinates.y -
                height +
                'px',
              top: 'auto',
              right: 'auto',
              left: coordinates.x + 8 + width + 'px',
            },
            arrow: {
              bottom: arrowY - HALF_ARROW_SIZE + 'px',
              left: -10 + 'px',
            },
          }),
        ],
        [
          equals('bottom_right'),
          () => ({
            text: {
              bottom: 'auto',
              top: coordinates.y + height + 8 + 'px',
              right: 'auto',
              left: coordinates.x + 'px',
            },
            arrow: {
              top: -10 + 'px',
              left:
                countArrowHorizontalLeftRightPosition +
                'px',
            },
          }),
        ],
        [
          equals('bottom_left'),
          () => ({
            text: {
              bottom: 'auto',
              top: coordinates.y + height + 8 + 'px',
              right:
                windowWidth - coordinates.x - width + 'px',
              left: 'auto',
            },
            arrow: {
              top: -10 + 'px',
              right:
                countArrowHorizontalLeftRightPosition +
                'px',
            },
          }),
        ],
        [
          equals('top_right'),
          () => ({
            text: {
              bottom:
                windowHeight - coordinates.y + 8 + 'px',
              top: 'auto',
              right: 'auto',
              left: coordinates.x + 'px',
            },
            arrow: {
              top: '100%',
              left:
                countArrowHorizontalLeftRightPosition +
                'px',
            },
          }),
        ],
        [
          equals('top_left'),
          () => ({
            text: {
              bottom:
                windowHeight - coordinates.y + 8 + 'px',
              top: 'auto',
              right:
                windowWidth - coordinates.x - width + 'px',
              left: 'auto',
            },
            arrow: {
              top: '100%',
              right:
                countArrowHorizontalLeftRightPosition +
                'px',
            },
          }),
        ],
      ])(extendedPosition),
    [
      coordinates.y,
      coordinates.x,
      width,
      height,
      windowHeight,
      windowWidth,
      extendedPosition,
      arrowY,
      arrowY,
      countArrowHorizontalLeftRightPosition,
    ]
  )

  const arrowDirection = useMemo(
    () => pipe(split('_'), head)(extendedPosition),
    [extendedPosition]
  )

  const extendedChildren = useMemo(
    () =>
      React.cloneElement(children, {
        ref: ref,
      }),
    [children, ref]
  )

  return (
    <>
      {extendedChildren}
      <Portal>
        <TransitionGroup component={null}>
          <Transition
            key={extendedOpen}
            timeout={{enter: 20, exit: 150}}
          >
            {(state) =>
              extendedOpen && (
                <Root data-testid={'toolTip'}>
                  <Container
                    toolTipCoordinates={toolTipCoordinates}
                    arrowDirection={arrowDirection}
                    state={state}
                  >
                    <div ref={contentRef}>
                      {content}
                      {lockable && (
                        <>
                          <Line />
                          {lock ? (
                            <div style={{padding: 8}}>
                              Press{' '}
                              <span
                                style={{fontWeight: 700}}
                              >
                                ESCAPE
                              </span>{' '}
                              key to{' '}
                              <span
                                style={{fontWeight: 700}}
                              >
                                UNLOCK
                              </span>{' '}
                              this tooltip
                            </div>
                          ) : (
                            <div style={{padding: 8}}>
                              Press{' '}
                              <span
                                style={{fontWeight: 700}}
                              >
                                CTRL
                              </span>{' '}
                              key to{' '}
                              <span
                                style={{fontWeight: 700}}
                              >
                                LOCK
                              </span>{' '}
                              this tooltip
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Container>
                </Root>
              )
            }
          </Transition>
        </TransitionGroup>
      </Portal>
    </>
  )
}

ToolTip.displayName = 'ToolTip'

export default ToolTip
