import JobButtons from '../JobButtons'
import React, {useCallback} from 'react'
import {pipe} from 'ramda'
import {and, fork} from 'fluture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useNavigate} from 'react-router-dom'
import useFuture from '../../../../hooks/useFuture'
import killJob from '../../../../api/workloads/jobs/killJob'
import deleteJob from '../../../../api/workloads/jobs/deleteJob'

const JobSingleButtons = ({pending, data, getFuture}) => {
  const {createNotification} = useNotificationContext()
  const killJobFuture = useFuture(killJob)
  const deleteJobFuture = useFuture(deleteJob)
  const navigate = useNavigate()
  const handleOnKill = useCallback(({id}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to kill Job',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Job killed successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      killJobFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnDelete = useCallback(({id}) => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to deleted Job',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        navigate('/workloads/list/jobs')
        createNotification({
          title: 'Job deleted successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteJobFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnClone = useCallback(({id}) => {
    const params = new URLSearchParams({
      template: id,
    }).toString()
    navigate(`/workloads/create/job?${params}`)
    createNotification({
      title: 'Warning',
      message:
        'Please, go to "Resources" and attach files again',
      variant: 'warning',
      autoHide: true,
    })
  }, [])

  return <JobButtons
    pending={pending}
    state={data?.state}
    clusterType={data?.clusterType}
    jobId={data?.jobId}
    onKill={handleOnKill}
    onDelete={handleOnDelete}
    onClone={handleOnClone}
    onSpark={() => {}}
    onYarn={() => {}}
  />
}

export default JobSingleButtons;