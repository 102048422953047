import { combineReducers } from '@reduxjs/toolkit'
import sidebarReducer from './sidebar'
import editorReducer from './editor'
import resultsReducer from './results'

const sqlViewerReducer = combineReducers({
  sidebar: sidebarReducer,
  editor: editorReducer,
  results: resultsReducer,
})

export default sqlViewerReducer
