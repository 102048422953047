import {Select} from 'frontcore'
import {path} from 'ramda'
import {memo, useCallback} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import Converter from './converter'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'

const Root = styled.div`
  background-color: white;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
`

const DATA_FORMATS_SUPPORTED = ['CSV', 'Excel', 'JSON']

const EXTENSIONS = Object.freeze({
  CSV: 'csv',
  Excel: 'xlsx',
  JSON: 'json',
})

const DownloadResults = () => {
  const { createNotification } = useNotificationContext()
  const results = useSelector(path(['sqlViewer', 'results', 'data', 'result', 'response']))

  const handleDownload = useCallback((dataFormat) => {
    try {
      if (!results) return
      const fileContent = Converter[dataFormat](results)
      const link = document.createElement('a')
      link.href = fileContent
      link.download = `results.${EXTENSIONS[dataFormat]}`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      createNotification({
        title: 'Results Downloaded',
        message: `Query results were download in ${dataFormat} format`,
        variant: 'success',
        autoHide: true,
      })
    } catch (error) {
      console.dir(error)
      createNotification({
        title: 'Download failure',
        message: `Failed to download results in ${dataFormat} format`,
        variant: 'error',
        autoHide: true,
      })
    }
  }, [results, createNotification])

  return (
    <Root>
      <Select
        value='Download'
        options={DATA_FORMATS_SUPPORTED}
        onChange={handleDownload}
      />
    </Root>
  )
}

export default memo(DownloadResults)
