import {memo, useCallback, useMemo, useState} from 'react'
import RButton from '../RButton'
import {
  equals,
  isEmpty,
  isNil,
  isNotNil,
  path,
  pipe,
  prop,
  propOr,
} from 'ramda'
import {useDispatch, useSelector} from 'react-redux'
import ExpandListButton from '../ExpandListButton'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import {toggleChosenItem} from '../../reducers/dataExplorer/flatTree'
import {deleteData} from '../../reducers/data'
import useFuture from '../../hooks/useFuture'
import getCatalogs from '../../api/table/getCatalogs'
import getDatabases from '../../api/table/getDatabases'
import getColumns from '../../api/table/getColumns'
import getTables from '../../api/table/getTables'
import {fork} from 'fluture'
import {EMPTY_STRING} from '../../constants'

const ITEM_KIND = Object.freeze({
  METASTORE: 'metastore',
  CATALOG: 'catalog',
  DATABASE: 'database',
  TABLE: 'table',
  COLUMN: 'column',
  OTHER: 'other',
})

const PADDING_MAP = Object.freeze({
  [ITEM_KIND.METASTORE]: 4,
  [ITEM_KIND.CATALOG]: 16,
  [ITEM_KIND.DATABASE]: 30,
  [ITEM_KIND.TABLE]: 30 + 14,
  [ITEM_KIND.COLUMN]: 30 + 14 + 14 + 14,
  [ITEM_KIND.OTHER]: 4,
})

const ICON_MAP = Object.freeze({
  [ITEM_KIND.METASTORE]: 'server',
  [ITEM_KIND.CATALOG]: 'directory',
  [ITEM_KIND.DATABASE]: 'database',
  [ITEM_KIND.TABLE]: 'table',
  [ITEM_KIND.OTHER]: 'question',
})

const ListItem = (item) => {
  const {
    id,
    name = 'Unnamed',
    kind = ITEM_KIND.OTHER,
    type = EMPTY_STRING,
    data = [],
  } = item

  const {createNotification} = useNotificationContext()
  const dispatch = useDispatch()

  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )
  const toggleChosen = useCallback(() => {
    if (kind === ITEM_KIND.COLUMN) return
    dispatch(toggleChosenItem(item))
  }, [item, kind])

  const getCatalogsFuture = useFuture(getCatalogs)
  const getDatabaseFuture = useFuture(getDatabases)
  const getColumnsFuture = useFuture(getColumns)
  const getTablesFuture = useFuture(getTables)

  const [pending, setPending] = useState(false)
  const toggleExpanded = useCallback(() => {
    if (data.length > 0) {
      dispatch(deleteData(item))
      return
    }
    setPending(true)
    const future = (() => {
      switch (kind) {
        case ITEM_KIND.METASTORE:
          return getCatalogsFuture({
            params: [{key: 'metaStoreId', value: item?.id}],
          })
        case ITEM_KIND.CATALOG:
          return getDatabaseFuture({
            params: [
              {
                key: 'metaStoreId',
                value: item?.metaStoreId,
              },
              {key: 'catalogId', value: item?.id},
            ],
          })
        case ITEM_KIND.DATABASE:
          return getTablesFuture({
            params: [
              {
                key: 'metaStoreId',
                value: item?.metaStoreId,
              },
              {key: 'catalogId', value: item?.catalogId},
              {key: 'databaseId', value: item?.id},
            ],
          })
        case ITEM_KIND.TABLE:
          return getColumnsFuture({
            params: [
              {
                key: 'metaStoreId',
                value: item?.metaStoreId,
              },
              {key: 'catalogId', value: item?.catalogId},
              {
                key: 'databaseId',
                value: item?.databaseId,
              },
              {key: 'id', value: item?.id},
            ],
          })
        default:
          return null
      }
    })()
    if (isNil(future)) return
    fork(() => {
      createNotification({
        title: 'Error',
        message: 'Failed to fetch from Metastore',
        variant: 'error',
        autoHide: true,
      })
      setPending(false)
    })((data) => {
      if (isEmpty(data)) {
        createNotification({
          title: 'Empty container',
          message: 'Metastore container is empty. No data was fetched',
          variant: 'info',
          autoHide: true,
        })
      }
      setPending(false)
    })(future)
  }, [data, kind, item])

  return (
    <RButton
      name={name}
      padding={PADDING_MAP[kind]}
      icon={ICON_MAP[kind]}
      onClick={toggleChosen}
      active={equals(chosenItem, item)}
      StartComponent={
        kind !== ITEM_KIND.COLUMN && ExpandListButton
      }
      startComponentProps={{
        onClick: toggleExpanded,
        pending,
      }}
    >
      <div style={{ padding: 8 }}>
        {type.toUpperCase()}
      </div>
    </RButton>
  )
}

export default memo(ListItem)
