import NameCell from '../../nameCell'
import {CountMiniature} from '../../../../components/miniatures'
import DateCell from '../../dateCell'
import ServiceState from '../../../../components/ServiceState'
import ToolTip from '../../../../components/toolTip'

const schema = [
  {
    value: 'name',
    label: 'Name',
    size: 1.5,
    Component: NameCell,
  },
  {
    value: 'id',
    label: 'Id',
    size: 1.5,
    Component: NameCell,
  },
  {
    value: 'kind',
    label: 'Type',
    size: 0.7,
  },
  {
    value: 'language',
    label: 'Language',
    size: 1,
  },
  {
    value: 'state',
    label: 'State',
    size: 0.7,
    Component: ServiceState,
  },
  {
    value: 'jobsCount',
    label: 'Jobs',
    description: 'Number of active Jobs',
    size: 0.7,
    enableSort: false,
    Component: CountMiniature,
    componentProps: {
      type: 'job',
    },
  },
  {
    value: 'totalRequests',
    label: 'Requests',
    description:
      'Total number of WorkloadsRequestsListView received by this group.',
    size: 0.8,
    enableSort: false,
    Component: CountMiniature,
    componentProps: {
      type: 'request',
    },
  },
  {
    value: 'scale',
    label: 'Scale',
    ToolTipComponent: () => (
      <div>
        Number of replicas handled by loadbalancer.
        <ToolTip
          content={
            <div style={{padding: 10}}>Go To Kubernetes Documentation</div>
          }
        >
          <a href={'https://kubernetes.io/docs/home/'}>
            Kubernetes
          </a>
        </ToolTip>
      </div>
    ),
    lockable: true,
    size: 0.7,
  },
  {
    value: 'createdTime',
    label: 'Created Time',
    description:
      'The time when the group was created in the system.',
    size: 1,
    Component: DateCell,
  },
]

export default schema
