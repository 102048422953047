import List from '../../../../../components/list'

import HeaderComponent from '../../../../../components/list/headerComponent'
import ItemComponent from '../../../../../components/list/itemComponent'
import ToolTipContentComponent from '../../../../WorkloadsView/Components/ServicesList/toolTipContentComponent'
import React, {useEffect, useMemo} from 'react'
import {join, map, pipe} from 'ramda'
import {fork} from 'fluture'
import {EMPTY_FUNCTION} from '../../../../../constants'
import DateCell from '../../../../WorkloadsView/dateCell'
import LabelIcon from '../../../../../components/Icons/LabelIcon'
import GroupSingleButtons from '../../../Components/GroupButtons/GroupSingleButtons'
import StatusIndicator from '../../../../../components/StatusIndicator'

const schema = [
  {
    value: 'method',
    label: 'Method',
    size: 1,
  },
  {
    value: 'endpoint',
    label: 'Endpoint',
    size: 2,
  },
  {
    value: 'userId',
    label: 'User Id',
    size: 2,
  },
  {
    value: 'username',
    label: 'Username',
    size: 2,
  },
  {
    value: 'groups',
    label: 'Groups',
    Component: ({value }) => value && join(' ', value),
    size: 2,
  },
  {
    value: 'timestamp',
    label: 'Timestamp',
    size: 2,
    Component: DateCell,
  },
]

const ActivitiesList = ({listHandler, pending = false}) => {
  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists = {},
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    selected,
    setSelected,
  } = listHandler

  const extendedData = useMemo(
    () =>
      pipe(
        map((element) => ({
          ...element,
        })),
        map((data) => ({data}))
      )(data),
    [data]
  )

  useEffect(() => {
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])

  const {filter} = lists

  return (
    <List
      filters={filter}
      schema={schema}
      data={extendedData}
      HeaderComponent={HeaderComponent}
      headerComponentProps={{
        sort,
        onSort: handleOnSort,
        skeleton: pending,
        iconWidth: '120px',
      }}
      selected={selected}
      onSelect={setSelected}
      onReset={handleOnReset}
      ItemComponent={ItemComponent}
      itemComponentProps={{
        toFn: ({id}) => '/security/details/activity/' + id,
        buttonsWidth: '0px',
        skeleton: pending,
        ToolTipComponent: ToolTipContentComponent,
        IconComponent: ({data}) => (
          <LabelIcon color={'#444444'}>{data?.method}</LabelIcon>
        ),
        iconWidth: '120px',
        iconComponentProps: {
          type: 'activity',
        },
      }}
      pagerProps={{
        size,
        page,
        totalPages,
        onChangeSize: handleOnSizeChange,
        onChangePage: handleOnPageChange,
      }}
    />
  )
}

export default ActivitiesList
