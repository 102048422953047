import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
    
  overflow: hidden;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`

const VerticalLayout = ({
  LogoComponent,
  ButtonsComponent,
  UserComponent,
}) => {
  return (
    <Root>

      <LogoComponent />

      <div
        style={{
          display: 'flex',
          gap: 50,
          alignItems: 'center',

        }}
      >
        <ButtonsComponent
          expanded={false}
          textWrap={false}
        />
      </div>
      <div style={{flex: 1}}></div>
      <UserComponent expanded={true} />
    </Root>
  )
}

export default VerticalLayout
