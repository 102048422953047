import styled from 'styled-components';

export const ViewTitle = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  align-self: stretch;
    padding-left: 16px;
  font-family: Roboto,serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
    color: ${({theme}) => theme.palette.neutral[700]};
`

export const Title = styled.div`
    color: ${({theme}) => theme.palette.neutral[700]};
    font-family: Roboto,serif;
    font-size: 24px;
   
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.15px;
    height: 64px;
`


export const SubTitle = styled.div`
    font-size: 20px;
    color: ${({theme}) => theme.palette.text.tertiary};
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: Roboto,serif;
 
  font-style: normal;
  
  line-height: 150%;
  letter-spacing: 0.15px;
   
`