import { memo, useCallback, useRef, useState } from 'react'
import {createPortal} from 'react-dom'
import styled from 'styled-components'

const Root = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  min-width: 200px;
  max-width: 200px;
  font-size: 14px;
  padding: 12px 10px;
  background-color: #fbfbfb;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TopLevel = styled.div`
  font-size: 14px;
  position: fixed;
  background-color: #fbfbfb;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 12px 10px;
`

const Field = ({ children }) => {
  const rootRef = useRef(null)
  const [pivot, setPivot] = useState(null)

  const handleExpand = useCallback(() => {
    if (!rootRef.current) return
    const { top, left } = rootRef.current.getBoundingClientRect()
    setPivot({ top: top - 1, left: left - 1 })
  }, [])

  const handleCollapse = useCallback(() => {
    setPivot(null)
  }, [])

  return (
    <>
      <Root 
        ref={rootRef}
        onClick={handleExpand}
      >{children}</Root>
      {pivot && createPortal((
        <TopLevel 
          style={pivot}
          onMouseLeave={handleCollapse}
        >
          {children}
        </TopLevel>
      ), document.body)}
    </>
  )
}

export default memo(Field)
