import {useDispatch, useSelector} from 'react-redux'
import {
  equals,
  isEmpty,
  path,
  pipe,
  prop,
  propOr,
  toUpper,
} from 'ramda'
import {isNotEmpty} from '../../helpers'
import useFuture from '../../hooks/useFuture'
import {useCallback, useState} from 'react'
import {deleteData} from '../../reducers/data'
import {fork} from 'fluture'
import {toggleChosenItem} from '../../reducers/dataExplorer/flatTree'
import {propsMap} from './helpers'
import getCatalogs from '../../api/table/getCatalogs'
import getDatabases from '../../api/table/getDatabases'
import getTables from '../../api/table/getTables'
import ExpandListButton from '../../components/ExpandListButton'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import getColumns from '../../api/table/getColumns'
import RButton from '../../components/RButton'

const paddingMap = {
  metastore: 4,
  catalog: 32,
  database: 60,
  table: 60 + 28,
  column: 60 + 28 + 28 + 28,
}

const ListButton = (item) => {
  const leftShift = paddingMap[item.kind]
  const dispatch = useDispatch()
  const {createNotification} = useNotificationContext()
  const data = propOr([], 'data', item)

  const expanded = isNotEmpty(data)

  const chosen = useSelector(
    pipe(path(['flatTree', 'chosenItem']), equals(item))
  )

  const [pending, setPending] = useState(false)
  const getCatalogsFuture = useFuture(getCatalogs)

  const getDatabaseFuture = useFuture(getDatabases)
  const getColumnsFuture = useFuture(getColumns)
  const getTablesFuture = useFuture(getTables)

  const responseFn = (data) => {
    if (isEmpty(data)) {
      createNotification({
        title: `${item?.kind} Is Empty`,
        message: `${item.name} ${item.kind} does not contain any elements`,
        variant: 'information',
        autoHide: true,
      })
    }
    setPending(false)
  }
  const rejectFn = () => {
    setPending(false)
  }

  const toggleExpanded = useCallback(
    (event) => {
      event.stopPropagation()
      if (expanded) {
        dispatch(deleteData(item))
      } else {
        setPending(true)
        if (item?.kind === 'metastore') {
          fork(rejectFn)(responseFn)(
            getCatalogsFuture({
              params: [
                {key: 'metaStoreId', value: item?.id},
              ],
            })
          )
        } else if (item?.kind === 'catalog') {
          fork(rejectFn)(responseFn)(
            getDatabaseFuture({
              params: [
                {
                  key: 'metaStoreId',
                  value: item?.metaStoreId,
                },
                {key: 'catalogId', value: item?.id},
              ],
            })
          )
        } else if (item?.kind === 'database') {
          fork(rejectFn)(responseFn)(
            getTablesFuture({
              params: [
                {
                  key: 'metaStoreId',
                  value: item?.metaStoreId,
                },
                {key: 'catalogId', value: item?.catalogId},
                {key: 'databaseId', value: item?.id},
              ],
            })
          )
        } else if (item?.kind === 'table') {
          fork(rejectFn)(responseFn)(
            getColumnsFuture({
              params: [
                {
                  key: 'metaStoreId',
                  value: item?.metaStoreId,
                },
                {key: 'catalogId', value: item?.catalogId},
                {
                  key: 'databaseId',
                  value: item?.databaseId,
                },
                {key: 'id', value: item?.id},
              ],
            })
          )
        }
      }
    },
    [item.id, expanded]
  )

  const toggleChosen = useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(toggleChosenItem(item))
    },
    [item]
  )

  const type = prop('kind', item)
  const extendedProps = propsMap[type]

  return (
    <RButton
      name={item.name}
      padding={leftShift}
      active={chosen}
      icon={extendedProps?.icon}
      onClick={
        item.kind === 'column' ? () => {} : toggleChosen
      }
      StartComponent={
        item.kind !== 'column' && ExpandListButton
      }
      startComponentProps={{
        onClick: toggleExpanded,
        pending,
      }}
    >
      <div>{toUpper(item?.type || '')}</div>
    </RButton>
  )
}

export default ListButton
