import {map, omit, path, pipe, propOr, toPairs} from 'ramda'
import React, {useEffect, useMemo} from 'react'
import {Tabs} from 'frontcore'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useSelector} from 'react-redux'
import DetailsInfo from '../../WorkloadsView/detailsInfo'

import getDatabase from '../../../api/table/getDatabase'
import {isNotEmpty} from '../../../helpers'
import Configuration from '../../../components/configuration'
import NoResultFound from '../../../components/NoResultFound'
import {EMPTY_OBJECT} from '../../../constants'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'

const DatabaseDetails = () => {
  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )

  const {name, kind} = chosenItem

  const getDatabaseFuture = useFuture(getDatabase)

  const details = useSelector(
    path(['data', 'data', 'database', 'response'])
  )

  const extendedDetailsData = pipe(
    omit([
      'parameters',
      'metastoreId',
      'catalogId',
      'catalogName',
    ]),
    toPairs,
    map((a) => ({key: a[0], value: a[1]}))
  )(details || [])
  const tabsContent = (
    <Tabs
      value={'parameters'}
      options={[
        {
          id: 'parameters',
          children: 'Parameters',
        },
      ]}
    />
  )

  useEffect(() => {
    fork(() => {})(() => {})(
      getDatabaseFuture({
        params: [
          {
            key: 'metaStoreId',
            value: chosenItem.metaStoreId,
          },
          {key: 'catalogId', value: chosenItem.catalogId},
          {key: 'id', value: chosenItem.id},
        ],
      })
    )
  }, [chosenItem])

  const configurationData = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'parameters'),
        toPairs,
        map((a) => ({key: a[0], value: a[1]}))
      )(details),
    [details]
  )

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'database'} />}
      name={name}
      id={kind}
      pending={false}
      data={extendedDetailsData}
    />
  )

  const listContent = isNotEmpty(configurationData) ? (
    <div style={{overflow: 'hidden', padding: 16}}>
      <Configuration
        data={configurationData}
        schema={[
          {
            id: 'key',
            label: 'Key',
            copy: true,
          },
          {
            id: 'value',
            label: 'Value',
            copy: true,
            obfuscate: true,
          },
        ]}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <NoResultFound
        title={'No Data'}
        text={'This Metastore have no configuration'}
      />
    </div>
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={tabsContent}
      bottomContent={listContent}
    />
  )
}

export default DatabaseDetails
