import React from 'react'
import styled from 'styled-components'
import {createTransitions} from '../../helpers'
import skeletonCss from '../../styles/skeletonCss'
import {Icon} from 'frontcore'

export const colorMap = {
  cluster: '#D1495B',
  service: '#E66F00',
  job: '#00a2a8',
  request: '#0084e3',
  stage: '#d53fff',
  schedule: '#ad42f5',
  lineageDataset: '#ffb600',
  lineageJob: '#006d72',
  metastore: '#000000',
  catalog: '#000000',
  timelineJob: '#9575CD',
  timelineStage: '#F06292',
}

const letterMap = {
  default: 'N/A',
  cluster: 'cluster',
  service: 'box',
  job: 'database',

  request: 'table',
  storage: 'S',
  stage: 'table',
  bucket: 'B',
  query: 'Q',
  schedule: 'clock',
  timelineJob: 'table',
  timelineStage: 'table'
}

const SquareMiniatureRoot = styled.div`
  min-width: 20px;
  width: fit-content;
  padding: 4px;
  height: 20px;
  opacity: ${({active}) => (active ? 1 : 0.5)};
  display: flex;
  font-family: Inter,serif;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.palette.miniatures.text};
  font-weight: 700;
  font-size: 12px;
  box-sizing: border-box;
  background-color: ${({value, type, theme}) =>
    value === 0
      ? theme.palette.neutral[300]
      : colorMap[type] || theme.palette.neutral[300]};
  transition: ${createTransitions(['opacity'])};
`

const CircleMiniatureRoot = styled.div`
  ${skeletonCss};
  font-size: 14px;
  font-weight: 700;
  font-family: Inter,serif;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  color: ${({theme}) => theme.palette.miniatures.text};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({type, theme}) =>
    colorMap[type] || theme.palette.neutral[600]};
  transition: ${createTransitions(['opacity'])};
`

const LetterMiniature = ({
  type = 'default',
  skeleton = false,
  icon
}) => (
  <CircleMiniatureRoot $skeleton={skeleton} type={type}>

    <Icon icon={icon || letterMap[type]} size={14} color={'white'}/>
  </CircleMiniatureRoot>
)

const CountMiniature = (a) => {
  const {value = 0, active = false, type = 'default'} = a

  return (
    <SquareMiniatureRoot
      value={value}
      active={active}
      type={type}
    >
      {value}
    </SquareMiniatureRoot>
  )
}

export {LetterMiniature, CountMiniature}
