import {Tabs} from 'frontcore'
import {CountMiniature} from '../../../components/miniatures'
import useFetch from '../../../hooks/useFetch'
import React, {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Metrics from '../metrics'
import {EMPTY_OBJECT} from '../../../constants'
import getInfoCountFetch from '../../../api/file/getInfoCountFetch'
import ListViewLayout from '../../../components/layouts/ListViewLayout'
import {useSelector} from "react-redux";
import {path} from "ramda";
import {singleCallFetchOptions} from "../../../api/helpers";

const TabComponent = ({active, type, value, label}) => (
    <div
        style={{display: 'flex', gap: 8, alignItems: 'center'}}
    >
        {label}
        <CountMiniature
            value={value}
            active={active}
            type={type}
        />
    </div>
)

const WorkloadsListView = () => {
    const {pathname} = useLocation()
    const navigate = useNavigate()

    const {tabsRefreshRate = 600000} = useSelector(
        path(['settings', 'settings'])
    )

    const fetchOptions = Number(tabsRefreshRate) ? {refreshInterval: Number(tabsRefreshRate)} : singleCallFetchOptions

    const {data = EMPTY_OBJECT} = useFetch(
        getInfoCountFetch,
        fetchOptions
    )

    const {
        clusters = 0,
        groups = 0,
        jobs = 0,
        requests = 0,
        schedules = 0,
    } = data

    const handleOnClickTab = useCallback(
        (value) => {
            navigate(value)
        },
        [navigate]
    )

    return (
        <ListViewLayout
            tabsNode={
                <Tabs
                    value={pathname}
                    onChange={handleOnClickTab}
                    options={[
                        {
                            id: '/workloads/list/clusters',
                            Component: TabComponent,
                            componentProps: {
                                type: 'cluster',
                                label: 'Clusters',
                                value: clusters,
                            },
                        },
                        {
                            id: '/workloads/list/services',
                            Component: TabComponent,
                            componentProps: {
                                type: 'service',
                                label: 'Services',
                                value: groups,
                            },
                        },
                        {
                            id: '/workloads/list/schedules',
                            Component: TabComponent,
                            componentProps: {
                                type: 'schedule',
                                label: 'Schedules',
                                value: schedules,
                            },
                        },
                        {
                            id: '/workloads/list/jobs',
                            Component: TabComponent,
                            componentProps: {
                                type: 'job',
                                label: 'Jobs',
                                value: jobs,
                            },
                        },
                        {
                            id: '/workloads/list/requests',
                            Component: TabComponent,
                            componentProps: {
                                type: 'request',
                                label: 'Requests',
                                value: requests,
                            },
                        },
                    ]}
                />
            }
            metricsNode={<Metrics/>}
        />
    )
}

export default WorkloadsListView
