import {append, equals, find, map, mergeRight, pipe, reject,} from 'ramda'
import React, {useCallback, useEffect, useMemo, useState,} from 'react'
import {EMPTY_ARRAY, EMPTY_FUNCTION, EMPTY_OBJECT,} from '../../../../constants'
import {fork} from 'fluture'
import DateCell from '../../dateCell'
import useSSE from '../../../../hooks/useSSE'
import {getBaseUrl} from '../../../../helpers'
import {useNavigate} from 'react-router-dom'
import NameCell from '../../nameCell'
import JobState from '../../../../components/jobState'
import HeaderComponent from '../../../../components/list/headerComponent'
import ItemComponent from '../../../../components/list/itemComponent'
import List from '../../../../components/list'
import ToolTipContentComponent from './toolTipContentComponent'
import {CountMiniature} from '../../../../components/miniatures'
import JobSingleButtons from '../JobSingleButtons'
import {useNotificationContext} from "../../../../hooks/useNotificationsContext";

const schema = [
  {
    value: 'jobName',
    label: 'Name',
    size: 1.5,
    Component: NameCell,
  },
  {
    value: 'jobId',
    label: 'Id',
    size: 2,
    Component: NameCell,
  },
  {
    value: 'jobType',
    label: 'Type',
    size: 1,
  },
  {
    value: 'totalRequests',
    label: 'Requests',
    description: 'Total number of requests received by this service.',
    size: 1,
    enableSort: false,
    Component: CountMiniature,
    componentProps: {
      type: 'request'
    }
  },
  {
    value: 'state',
    label: 'Status',
    size: 1,
    Component: JobState,
  },
  {
    value: 'submitTime',
    label: ' Submit Time',
    description: 'The time when the job was created in the system.',
    size: 1.5,
    Component: DateCell,
  },
  {
    value: 'startTime',
    label: ' Start Time',
    description: 'The time when the job was started.',
    size: 1.5,
    Component: DateCell,
  },
  {
    value: 'endTime',
    label: 'End Time',
    description: 'The time when the job has finished.',
    size: 1.5,
    Component: DateCell,
  },
]

const JobsList = ({listHandler, pending = false}) => {
  const navigate = useNavigate()

  const {
    data,
    totalPages,
    size,
    page,
    getFuture,
    sort,
    lists,
    handleOnSort,
    handleOnPageChange,
    handleOnSizeChange,
    handleOnReset,
    selected,
    setSelected
  } = listHandler

  const {filter} = lists;

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    fork(({message}) => {
      createNotification({
        title: 'Failed to fetch Jobs',
        message,
        variant: 'error',
        autoHide: true,
      })
    })(EMPTY_FUNCTION)(getFuture)
  }, [getFuture])


  const baseUrl = getBaseUrl()

  const apiUrl = process.env.REACT_APP_API_URL

  const url = useMemo(
    () => `${baseUrl}${apiUrl}/truly/job/info`,
    [baseUrl, apiUrl]
  )

  const [volatileJobs, setVolatileJobs] =
    useState(EMPTY_ARRAY)

  const [tempJob, setTempJob] =
    useState()

  useEffect(() => {
    if(tempJob) {
      const result = pipe(
        reject(({jobId}) => jobId === tempJob?.jobId),
        append(tempJob)
      )(volatileJobs)
      setVolatileJobs(result)
      setTempJob()
    }
  }, [tempJob, volatileJobs])

  useSSE(
    url,
    [
      {
        name: 'message',
        handler: (event) => {
          const job = JSON.parse(event.data)
          setTempJob(job)
        },
      },
    ],
    []
  )

  const extendedData = useMemo(() => {
    return pipe(
      map((element) => {
        const volatile = find(
          ({jobId}) => equals(jobId, element?.jobId),
          volatileJobs
        )
        return mergeRight(element, volatile || EMPTY_OBJECT)
      }),
      map((data) => ({data}))
    )(data)
  }, [data, volatileJobs])

  const handleOnDetails = useCallback(
    (data) => {
      navigate('/workloads/details/job/' + data.jobId)
    },
    [data]
  )

  return (
      <List
        idProp={'jobId'}
        filters={filter}
        schema={schema}
        data={extendedData}
        debug={false}
        onReset={handleOnReset}
        HeaderComponent={HeaderComponent}
        headerComponentProps={{
          sort,
          onSort: handleOnSort,
          skeleton: pending,
        }}
        selected={selected}
        onSelect={setSelected}
        ItemComponent={ItemComponent}
        itemComponentProps={{
          skeleton: pending,
          toFn: ({jobId}) => '/workloads/details/job/' + jobId,
          onDetails: handleOnDetails,
          ButtonsComponent: JobSingleButtons,
          ToolTipComponent: ToolTipContentComponent,
          typeComponentProps: {
            type: 'job',
          },
          buttonsComponentProps: {
            pending,
            texts: false,
            getFuture: getFuture,
          },
        }}
        pagerProps={{
          size,
          page,
          totalPages,
          onChangeSize: handleOnSizeChange,
          onChangePage: handleOnPageChange,
        }}
      />
  )
}

export default JobsList
