import {useListRefreshFuture} from '../hooks'
import getGroups from '../../../../api/security/getGroups'
import ListLayout from '../../../../components/layouts/listLayout'
import Filters from '../../../WorkloadsView/filters'
import {Button, Icon} from 'frontcore'
import React from 'react'
import GroupsList from './GroupsList'
import {useNavigate} from 'react-router-dom'
import usePending from "../../../../hooks/usePending";

const SecurityGroupsListView = () => {
    const listHandler = useListRefreshFuture(
        getGroups,
        'security',
        'groups',
    )

    const navigate = useNavigate()

    const pending = usePending(
        ['security', 'data'],
        ['groups', 'createGroup', 'deleteGroup', 'enableGroup', 'disableGroup']
    )

    return (
        <ListLayout
            batchButtonsNode={<div>batch buttons</div>}
            filtersNode={
                <Filters
                    name={'groups'}
                    schema={[]}
                    pending={pending}
                />
            }
            createButtonNode={
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() =>
                            navigate('/security/create/group')
                        }
                        EndComponent={Icon}
                        endComponentProps={{
                            icon: 'plus',
                            size: 18,
                            color: 'white',
                        }}
                    >
                        Add Group
                    </Button>
                </div>
            }
            contentNode={
                <GroupsList
                    pending={pending}
                    listHandler={listHandler}
                />
            }
        />
    )
}

export default SecurityGroupsListView
