import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {formatUrlParam} from '../helpers'
import React, {useEffect, useMemo, useState} from 'react'
import {
  append,
  ascend,
  assoc,
  evolve,
  findIndex,
  flatten,
  groupWith,
  map,
  mergeAll,
  pathOr,
  pipe,
  prop,
  reduce,
  sortWith, startsWith,
} from 'ramda'
import {EMPTY_ARRAY, EMPTY_OBJECT, VERTICAL} from '../../../constants'
import ElkGraph from '../TableGraphView/elkGraph'
import {mapWithKey} from '../../../helpers'


import Configuration from '../../../components/configuration'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import getColumn from '../../../api/lineage/getColumn'
import {useSelector} from 'react-redux'
import getDataset from '../../../api/lineage/getDataset'
import Line from '../../../components/line'
import DetailsInfo from '../../WorkloadsView/detailsInfo'
import {Button, Icon, Portal, ResizeLayout} from 'frontcore'
import {ILUM_LINEAGE_ENABLED} from '../../../helpers/runtimeEnv'
import ViewNotAvailable from "../../../components/viewNotAvailable";

const Details = ({namespace, dataset}) => {
  const getDatasetFuture = useFuture(getDataset)

  const model = useSelector(prop('lineage'))

  useEffect(() => {
    fork((message) => {})(() => {})(
      getDatasetFuture({
        params: [
          {
            key: 'namespace',
            value: formatUrlParam(namespace),
          },
          {
            key: 'dataset',
            value: formatUrlParam(dataset),
          },
        ],
      })
    )
  }, [namespace, dataset])

  const extendedData = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_OBJECT, [
          'data',
          'dataset',
          'response',
        ])
      )(model),
    [model]
  )

  return (
    <div
      style={{
        padding: 8,
        overflow: 'hidden',
        height: '100%',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <Configuration
        data={extendedData?.fields || []}
        schema={[
          {
            id: 'name',
            label: 'Name',
            copy: false,
          },
          {
            id: 'type',
            label: 'Type',
            copy: false,
          },
          {
            id: 'tags',
            label: 'Tags',
            copy: false,
            Component: ({value}) =>
              value?.length && value?.length(),
          },
          {
            id: 'description',
            label: 'Description',
            copy: false,
            Component: ({value}) =>
              value || 'No description',
          },
        ]}
      />
    </div>
  )
}

const ColumnGraphView = (props) => {
  const params = useParams()
  const nodeType = props?.nodeType || params?.nodeType
  const nodeId = props?.nodeId || params?.nodeId
  const namespace = props?.namespace || params?.namespace
  const [selected, setSelected] = useState()


  const getColumnFuture = useFuture(getColumn)

  useEffect(() => {
    fork((message) => {})(() => {})(
      getColumnFuture({
        params: [
          {
            key: 'nodeType',
            value: nodeType,
          },
          {
            key: 'namespace',
            value: formatUrlParam(namespace),
          },
          {
            key: 'nodeId',
            value: nodeId,
          },
          {
            key: 'depth',
            value: 5,
          },
        ],
      })
    )
  }, [namespace, nodeType, nodeId])

  const model = useSelector(prop('lineage'))

  const navigate = useNavigate()

  const extendedData = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, [
          'data',
          'column',
          'response',
          'graph',
        ])
      )(model),
    [model]
  )

  const pending = useMemo(
    () =>
      pipe(
        pathOr(false, [
          'data',
          'column',
          'pending',
        ])
      )(model),
    [model]
  )

  const extendedNodes2 = useMemo(
    () =>
      pipe(
        reduce((accumulator, value) => {
          const index = findIndex(({id}) => {
            return id === value?.data?.dataset
          }, accumulator)
          if (index !== -1) {
            return evolve(
              {[index]: {fields: append(value)}},
              accumulator
            )
          } else {
            return append(
              {id: value?.data?.dataset, fields: [value]},
              accumulator
            )
          }
        }, EMPTY_ARRAY),
        map(({id, fields}) => {
          const inEdges1 = flatten(
            map(
              ({inEdges, data}) =>
                map(assoc('data', data), inEdges),
              fields
            )
          )
          const outEdges1 = flatten(
            map(
              ({outEdges, data}) =>
                map(assoc('data', data), outEdges),
              fields
            )
          )

          const inEdges = pipe(
            sortWith([ascend(prop('origin'))]),
            groupWith((a, b) => a.origin === b.origin),
            map(
              mapWithKey(
                (data, index) =>
                  ({
                    ...data,
                    inPortId: index,
                  })
              )
            ),
            flatten
          )(inEdges1)

          const outEdges = pipe(
            sortWith([ascend(prop('origin'))]),
            groupWith((a, b) => a.origin === b.origin),
            map(
              mapWithKey((data, index) => ({
                ...data,
                outPortId: index,
              }))
            ),
            flatten
          )(outEdges1)

          return {
            id,
            type: 'COLUMN',
            position: {x: 0, y: 0},
            isConnectable: false,
            data: {
              fields,
              name: id,
              inEdges,
              outEdges,
              isConnectable: false,
            },
          }
        })
      )(extendedData),
    [extendedData]
  )

  const extendedEdges = pipe(
    map((props) => {
      const {data} = props

      const {inEdges, outEdges, name} = data

      const extendedInEdges = map(
        ({origin, destination, inPortId}) => ({
          target: name,
          origin: destination,
          destination: origin,
          inPortId,
        }),
        inEdges
      )
      const extendedOutEdges = map(
        ({origin, destination, outPortId}) => ({
          source: name,
          origin,
          destination,
          outPortId,
        }),
        outEdges
      )

      return [...extendedInEdges, extendedOutEdges]
    }),
    flatten,
    sortWith([
      ascend(prop('origin')),
      ascend(prop('destination')),
    ]),
    groupWith(
      (a, b) =>
        a.destination === b.destination &&
        a.destination === b.destination
    ),
    map(mergeAll),
    map((data) => {
      const {
        source,
        target,
        origin,
        destination,
        outPortId,
        inPortId,
      } = data

      return {
        id: 'e' + origin + '-' + destination,
        source,
        target,
        sourceHandle: origin + '_' + outPortId,
        targetHandle: destination + '_' + inPortId,
        type: 'straight',
        animated: true,
      }
    })
  )(extendedNodes2)

  const {pathname} = useLocation()

  return ILUM_LINEAGE_ENABLED.toLowerCase() === 'true' ? (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: 24,
          paddingBottom: 8,
          height: 40,
          paddingLeft: 24,
          alignItems: 'center',
        }}
      >
        {startsWith('/ilum-lineage', pathname) && <Button
          size={'small'}
          variant={'text'}
          StartComponent={Icon}
          startComponentProps={{
            icon: 'arrow-left',
            size: 18,
          }}
          tabIndex={-1}
          justifyContent={'center'}
          onClick={() => {
            navigate(-1)
          }}
        >
          Back to List
        </Button>}
        <DetailsInfo
          title={'Column Flow'}
          skeleton={false}
          data={[
            {
              key: 'Node ID',
              value: nodeId,
            },
            {
              key: 'Node Type',
              value: nodeType,
            },
            {
              key: 'Namespace',
              value: namespace,
            },
          ]}
        />
      </div>
      <Line />
      <ResizeLayout
        configuration={{
          orientation: VERTICAL,
          firstNode: {
            measurement: 'flex',
            value: 1,
          },
          secondNode: {
            measurement: 'px',
            value: 8 * 32,
          },
        }}
        firstNode={
          !pending ? (
            <ElkGraph
              n={extendedNodes2}
              e={extendedEdges}
              onNodeClick={(event, {data}) => {
                setSelected(data)
              }}
            />
          ) : (
            <div></div>
          )
        }

        secondNode={
          <Details
            namespace={namespace}
            dataset={selected?.name}
          />
        }
      />
    </div>
  ) : (
    <ViewNotAvailable name={'Lineage'} />
  )
}

export default ColumnGraphView
