import {Select, skeletonCss} from 'frontcore'
import {memo, useCallback, useEffect, useMemo} from 'react'
import useFetch from '../../../hooks/useFetch'
import {getClustersFetch} from '../../../api/workloads/clusters/getClustersFetch'
import styled from 'styled-components'
import {isNil, pathOr} from 'ramda'
import {useNotificationContext} from '../../../hooks/useNotificationsContext'
import {useDispatch, useSelector} from 'react-redux'
import {EMPTY_OBJECT} from '../../../constants'
import {sidebarSetCluster} from '../../../reducers/sql/sidebar'
import {LetterMiniature} from '../../../components/miniatures'

const Root = styled.div`
  height: 32px;
  width: 140px;
  ${skeletonCss}
`

const ClusterCell = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.1s background-color ease-in-out;
  &:hover {
    background-color: #dadada;
  }
`

const EditorClusterSelector = () => {
  const dispatch = useDispatch()
  const chosenCluster = useSelector(pathOr(EMPTY_OBJECT, ['sqlViewer', 'sidebar', 'chosenCluster']))
  const handleSetChosenCluster = useCallback((newCluster) => {
    dispatch(sidebarSetCluster(newCluster))
  }, [dispatch])

  const {data: clusters, error, isLoading} = useFetch(
    getClustersFetch
  )

  useEffect(() => {
    if (isNil(clusters) || clusters.length <= 0) return
    handleSetChosenCluster(clusters[0])
  }, [clusters, handleSetChosenCluster])

  const {createNotification} = useNotificationContext()
  useEffect(() => {
    if (isNil(error)) return
    createNotification({
      title: 'Error',
      message: 'Failed to fetch clusters',
      variant: 'error',
      autoHide: true,
    })
  }, [createNotification, error])

  const isActive = useMemo(() => {
    return !isLoading && isNil(error) && clusters.length > 0
  }, [isLoading, error, clusters])

  return (
    <Root $skeleton={!isActive} >
      {isActive && (
        <Select
          label='Cluster'
          options={clusters}
          value={chosenCluster.name}
          buttonProps={{width: '140px'}}
          ItemComponent={memo(({value, closeFn}) => {
            const handleChoose = useCallback(() => {
              handleSetChosenCluster(value)
              closeFn()
            }, [closeFn, value])

            return (
              <ClusterCell onClick={handleChoose}>
                <LetterMiniature 
                  icon='cluster' 
                  type={value.type}
                />
                <span>{value.name}</span>
              </ClusterCell>
            )
          })}
        />
      )}
    </Root>
  )
}

export default memo(EditorClusterSelector)
