import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect} from 'react'
import useFuture from '../../../../hooks/useFuture'
import getGroup from '../../../../api/security/getGroup'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {join, pathOr, toUpper} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import DetailsHeader from '../../../../components/DetailsHeader'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import ReactJson from 'react-json-view'
import getRole from '../../../../api/security/getRole'
import UserIcon from '../../../../components/Icons/UserIcon'
import LabelIcon from '../../../../components/Icons/LabelIcon'
import {Tabs} from 'frontcore'
import GroupSingleButtons from '../../Components/GroupButtons/GroupSingleButtons'
import {CountMiniature} from '../../../../components/miniatures'
import * as PropTypes from 'prop-types'
import RolesSingleButtons from '../../Components/RolesButtons/RolesSingleButtons'

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

function RoleSingleButtons(props) {
  return null
}

RoleSingleButtons.propTypes = {
  redirect: PropTypes.bool,
  texts: PropTypes.bool,
  data: PropTypes.shape({}),
  getFuture: PropTypes.any,
  pending: PropTypes.bool,
}
const SecurityRoleDetailsView = () => {
  const getRoleFuture = useFuture(getRole)

  const {roleId, clusterName} = useParams()

  const dispatch = useDispatch()

  const role = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'role',
      'response',
    ])
  )

  const {
    name,
    users,
    groups,
    operations,
    enabled,
    lastTimeCreated,
    permissions,
  } = role

  useEffect(() => {
    if (roleId) {
      fork(() => {})(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getRoleFuture({
          params: [{key: 'id', value: roleId}],
        })
      )
    }
  }, [roleId])

  const topContent = (
    <DetailsHeader
      pending={false}
      icon={
        <LabelIcon color={'#9c64a8'} size={'large'}>
          {name}
        </LabelIcon>
      }
      data={[
        // {
        //   key: 'description',
        //   value: description,
        // },
        {
          key: 'last Time Created',
          value: lastTimeCreated,
          Component: DateCellRow,
        },
        {
          key: 'groups',
          value: groups,
        },
        {
          key: 'users',
          value: users,
        },
        {
          key: 'enabled',
          value: enabled,
          Component: ({value = false}) => value.toString(),
        },
        {
          key: 'permissions',
          value: permissions,
          Component: ({value}) => value && join(' ', value),
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={'permissions'}
        options={[
          {
            id: 'permissions',
            Component: TabComponent,
            componentProps: {
              label: 'Permissions',
            },
          },
        ]}
      />
      <RolesSingleButtons
        texts={true}
        getFuture={getRoleFuture({
          params: [{key: 'id', value: roleId}],
        })}
        pending={false}
        data={role}
        redirect={false}
      />
    </div>
  )
  const bottomContent = <ReactJson src={role} />

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityRoleDetailsView;