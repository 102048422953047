import ListLayout from '../../../../../components/layouts/listLayout'
import Filters from '../../../filters'
import schema from './schema'
import React, {useCallback} from 'react'
import usePending from '../../../../../hooks/usePending'
import {useListRefreshFuture} from '../../../hooks'

import {Button, Icon} from 'frontcore'
import {
  createSearchParams,
  useNavigate,
} from 'react-router-dom'
import {isNotEmpty} from '../../../../../helpers'
import GroupBatchButtons from '../../../Components/ServiceBatchButtons'
import ServiceList from '../../../Components/ServicesList'
import getServices from "../../../../../api/workloads/services/getServices";

const ClusterDetailsServiceList = ({
  clusterId,
  clusterName,
}) => {
  const navigate = useNavigate()

  const listHandler = useListRefreshFuture(
    getServices,
    'workloads',
    'services',
    {
      page: 0,
      size: 10,
      sort: {
        createdTime: 'desc',
      },
      filter: {
        groupStates: ['ACTIVE'],
      },
    },
    {clusterNames: [clusterName]}
  )

  const {selected} = listHandler

  const pendingServices = usePending(
    ['workloads', 'data'],
    [
      'groupsDetails',
      'createGroup',
      'deleteGroup',
      'archiveGroup',
      'scaleGroup',
      'deleteCluster',
    ]
  )

  const handleOnClickNewGroupButton = useCallback(() => {
    navigate({
      pathname: `/workloads/create/group`,
      search: createSearchParams({
        cluster: clusterName,
      }).toString(),
    })
  }, [])

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <GroupBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'services'}
          schema={schema}
          pending={pendingServices}
        />
      }
      createButtonNode={
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleOnClickNewGroupButton}
          EndComponent={Icon}
          endComponentProps={{
            icon: 'plus',
            size: 18,
            color: 'white',
          }}
        >
          New Service
        </Button>
      }
      contentNode={
        <ServiceList
          listHandler={listHandler}
          borderTop={true}
          pending={pendingServices}
        />
      }
    />
  )
}

export default ClusterDetailsServiceList
