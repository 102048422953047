import Filters from '../../../WorkloadsView/filters'
import {Button, Icon} from 'frontcore'
import ListLayout from '../../../../components/layouts/listLayout'
import React from 'react'
import UsersList from './UsersList'
import {useListRefreshFuture} from '../../../WorkloadsView/hooks'

import getUsers from '../../../../api/security/getUsers'
import {useNavigate} from 'react-router-dom'
import usePending from "../../../../hooks/usePending";

const SecurityUsersListView = () => {

    const listHandler = useListRefreshFuture(
        getUsers,
        'security',
        'users',
        {
            page: 0,
            size: 10,
            sort: {
                createdTime: 'desc',
            },
            filter: {
                groupStates: ['ACTIVE'],
            },
        }
    )

    const navigate = useNavigate()

    const pending = usePending(
        ['security', 'data'],
        ['users', 'createUser', 'deleteUser', 'enableUser', 'disableUser']
    )

    return (
        <ListLayout
            batchButtonsNode={<div>batch buttons</div>}
            filtersNode={
                <Filters
                    name={'users'}
                    schema={[]}
                    pending={pending}
                />
            }
            createButtonNode={
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() =>
                            navigate('/security/create/user')
                        }
                        EndComponent={Icon}
                        endComponentProps={{
                            icon: 'plus',
                            size: 18,
                            color: 'white',
                        }}
                    >
                        Add User
                    </Button>
                </div>
            }
            contentNode={
                <UsersList pending={pending} listHandler={listHandler}/>
            }
        />
    )
}

export default SecurityUsersListView
