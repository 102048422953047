import NameCell from '../../nameCell'
import DateCell from '../../dateCell'
import {join, map, pipe, reject, toPairs} from 'ramda'
import {formatDurationVerbose} from '../../../../helpers'
import React from 'react'
import styled from 'styled-components'

const ErrorRoot = styled.div`
  color: ${({theme}) => theme.palette.red[500]};
`

const ResultRoot = styled.div`
  color: ${({theme}) => theme.palette.default[500]};
`

const timeUnitsArray = {
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's',
  milliseconds: 'ms',
}

const Result = ({value}) => <ResultRoot>{value}</ResultRoot>

const Error = ({value}) => <ErrorRoot>{value}</ErrorRoot>

const schema = [
  {
    value: "requestId",
    label: "Id",
    size: 2.5,
    Component: NameCell,
  },
  {
    value: "clazz",
    label: "Class",
    size: 4,
  },
  {
    value: "startTime",
    label: "Start Time",
    size: 2,
    Component: DateCell,
  },
  {
    value: "endTime",
    label: "End Time",
    size: 2,
    Component: DateCell,
  },
  {
    value: "duration",
    label: "Duration",
    size: 2,
    Component: ({data}) => {
      const {startTime, endTime} = data;
      const duration = pipe(
        formatDurationVerbose,
        reject((a) => a === 0),
        toPairs,
        map((a) => a[1] + timeUnitsArray[a[0]]),
        join(' ')
      )(endTime - startTime)
      return <div>{(endTime && startTime) ? duration : 'N/A'}</div>
    },
    enableSort: false,
  },
  {
    value: "result",
    label: "Result",
    description: 'The request result.',
    enableSort: false,
    size: 4,
    Component: (a) => a.value ? <Result value={a.value}/> : <Error  value={a.data?.error?.message}/>
  },
];

export default schema