import React from 'react';
import styled from 'styled-components';
import StatusIndicator from '../StatusIndicator'


const backgroundColorMap = {
  SUBMITTED: '#E5ECFF',
  STARTING: '#FFF6C3',
  READY: '#DAF2E7',
  BUSY: '#FFF6C3',
  FAILED: '#FAE1E3',
  FINISHED: '#EEF0F3',
  UNKNOWN: '#EEF0F3',
};

const colorMap = {
  SUBMITTED: '#022481',
  STARTING: '#806104',
  READY: '#0A663B',
  BUSY: '#806104',
  FAILED: '#80141D',
  FINISHED: '#3E4651',
  UNKNOWN: '#2A2F36',
};

const variantMap = {
  SUBMITTED: 'information',
  STARTING: 'warning',
  READY: 'success',
  BUSY: 'warning',
  FAILED: 'error',
  FINISHED: 'default',
  UNKNOWN: 'default',
};

const JobState = ({value}) => (
  <StatusIndicator variant={variantMap[value]} value={value}/>
);

export default JobState;