import React from 'react'
import {EMPTY_ARRAY} from '../../constants'
import {map} from 'ramda'
import styled from 'styled-components'
import skeletonCss from '../../styles/skeletonCss'

const Root = styled.div`
  color: ${({theme}) => theme.palette.text.tertiary};
  display: flex;
  gap: 16px;
  overflow: hidden;
  height: 40px;
  align-items: flex-end;
`

const Container = styled.div`
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  overflow: hidden;
`

const Key = styled.div`
  font-weight: 600;
  text-transform: uppercase;

  white-space: nowrap;
  display: flex;
  align-items: center;
`

const Value = styled.div`
  ${skeletonCss};
  border-radius: 4px;
  font-size: 12px;

  white-space: nowrap;
  display: flex;
  align-items: center;
`

const DetailsInfo = ({
  data = EMPTY_ARRAY,
  skeleton = false,
  title = 'Title',
  icon,
}) => {
  return (
    <Root>
      {map(
        ({key, value, Component, componentProps}) => (
          <Container key={key}>
            <Key>{key}</Key>
            <Value $skeleton={skeleton}>
              {Component ? (
                <Component
                  value={value}
                  {...componentProps}
                />
              ) : value ? (
                value
              ) : (
                'N/A'
              )}
            </Value>
          </Container>
        ),
        data
      )}
    </Root>
  )
}

export default DetailsInfo
