import {LetterMiniature} from '../../components/miniatures';
import {Icon} from 'frontcore';
import {append, flatten, reduce} from 'ramda';

export const ITEM_TYPES = Object.freeze({
  CLUSTER: 'cluster',
  STORAGE: 'storage',
  TABLE:   'table'
})

export const propsMap = {
  metastore: {
    icon: 'server'
  },
  catalog: {
    icon: 'directory'
  },
  database: {
    icon: 'database'
  },
  table: {
    icon: 'table'
  }
}

export const flattenTree = (array) => reduce((acc, val) => {
  const data = val.data;
  let result = append(val, acc);
  if (data) {
    result = flatten(append(flattenTree(val.data), result));
  }
  return result;
}, [], array);
