import {
  evolve,
  length,
  flatten,
  includes,
  intersperse,
  join,
  keys,
  map,
  pipe,
  prop,
  times,
  uniq,
  when,
  any,
  __,
  always, propOr,
} from 'ramda'
import ToolTip from '../toolTip'
import Line from '../line'
import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  createTransitions,
  isNotEmpty,
  mapWithKey,
} from '../../helpers'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import {Button, Icon} from 'frontcore'
import {EMPTY_OBJECT, EMPTY_STRING} from '../../constants'
import ToolTipContentLayout from '../layouts/toolTip/toolTipContentLayout'

const ButtonContainer = styled.div`
  opacity: 0;
  transition: ${createTransitions(['opacity'])};
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 800;
  padding: 8px;
  font-family: Inter, serif;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  border-color: ${({theme}) => theme.palette.line};
`

const Row = styled.div`
  color: ${({theme}) => theme.palette['font-primary']};
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 24px;
  font-size: 14px;
  gap: 8px;
  height: 32px;
  transition: ${createTransitions(['background-color'])};

  &:hover {
    background-color: ${({theme}) =>
      theme.palette['surface-secondary']};

    ${ButtonContainer} {
      opacity: 1;
    }
  }
`

const Container = styled.div`
  overflow-y: scroll;
  flex: 1;
`

const RowHeader = styled.div`
  color: ${({theme}) => theme.palette['font-primary']};
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 24px;
  font-size: 14px;
  height: 32px;
  gap: 8px;
  background-color: ${({theme}) =>
    theme.palette['surface-secondary']};
  user-select: none;
`

const securedKeyWords = ['access', 'secret', 'password']

const Item = (props) => {
  const {createNotification} = useNotificationContext()

  const {data, schema} = props

  const extendedData = useMemo(
    () =>
      pipe(
        when(
          pipe(propOr(EMPTY_STRING,'key'), (a) =>
            any(includes(__, a))(securedKeyWords)
          ),
          evolve({
            value: (value) =>
              pipe(
                length,
                times(() => '*'),
                join('')
              )(value),
          })
        )
      )(data),
    [data, securedKeyWords]
  )

  return (
    <Row>
      {map(
        ({id, Component, copy}) => (
          <ValueContainer>
            <div
              style={{
                flex: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {Component ? (
                <Component value={extendedData[id]} />
              ) : (
                extendedData[id]
              )}
            </div>
            {copy && (
              <ButtonContainer>
                <ToolTip
                  content={
                    <ToolTipContentLayout>
                      Copy Value To Clipboard
                    </ToolTipContentLayout>
                  }
                >
                  <Button
                    variant={'text'}
                    square
                    size={'small'}
                    Component={Icon}
                    componentProps={{
                      size: 16,
                      icon: 'project',
                    }}
                    justifyContent={'center'}
                    tabIndex={-1}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        data[id]
                      )
                      createNotification({
                        message: 'Text copied to clipboard',
                        autoHide: true,
                        variant: 'information',
                        title: 'Clipboard',
                      })
                    }}
                  />
                </ToolTip>
              </ButtonContainer>
            )}
          </ValueContainer>
        ),
        schema
      )}
    </Row>
  )
}

const KeyContainer = styled.div`
  flex: 1;
  text-transform: capitalize;
`

const ValueContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
`

const Configuration = ({data, title, schema}) => {
  const _keys = pipe(map(keys), flatten, uniq)(data)
  const content = pipe(
    map((data) => ({
      Component: Item,
      componentProps: {data},
    })),
    intersperse({
      Component: Line,
    }),
    mapWithKey(
      (
        {Component, componentProps = EMPTY_OBJECT},
        index
      ) => (
        <Component
          key={index}
          {...componentProps}
          _keys={_keys}
          schema={schema}
        />
      )
    )
  )(data)

  return (
    <Root>
      {title && (
        <div>
          <Title>{title}</Title>
          <Line />
        </div>
      )}
      <RowHeader>
        {map(
          (b) => (
            <KeyContainer>{b}</KeyContainer>
          ),
          _keys
        )}
      </RowHeader>
      {isNotEmpty(content) && <Line />}
      <Container>{content}</Container>
    </Root>
  )
}

export default Configuration
