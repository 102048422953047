import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {and, fork} from 'fluture'
import useFuture from '../../../../hooks/useFuture'
import getJob from '../../../../api/workloads/jobs/getJob'
import {useDispatch, useSelector} from 'react-redux'
import {
  append,
  path,
  pathOr,
  pipe,
  propOr,
  reject,
} from 'ramda'
import SlideTransition from '../../../../components/transitions/slideTransition'
import {Icon, Tabs} from 'frontcore'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../../constants'
import {
  colorMap,
  CountMiniature,
} from '../../../../components/miniatures'
import {
  initJob,
  setBreadCrumbs,
} from '../../../../reducers/workloads'
import killJob from '../../../../api/workloads/jobs/killJob'
import DateCellRow from '../../dateCellRow'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import deleteJob from '../../../../api/workloads/jobs/deleteJob'
import usePending from '../../../../hooks/usePending'
import {useNavigate, useParams} from 'react-router-dom'
import JobState from '../../../../components/jobState'
import DetailsLayout from '../../../../components/layouts/detailsLayout'

import JobError from './jobError'
import styled from 'styled-components'
import JobOverview from './jobOverview'
import {
  convertObjectToArrayFn,
  getBaseUrl,
  mapWithKey,
} from '../../../../helpers'
import JobTags from './jobTags'
import JobParameters from './jobParamters'
import JobArguments from './jobArguments'
import useSSE from '../../../../hooks/useSSE'
import JobLineage from './jobLineage'
import JobButtons from '../../Components/JobButtons'
import DetailsHeader from '../../../../components/DetailsHeader'
import JobDetailsMemory from './JobDetailsMemory'
import JobDetailsRequestsList from './JobDetailsRequestsList'
import JobDetailsTimelineView from './JobDetailsTimelineView'
import JobDetailsExecutors from './JobDetailsExecutors'
import JobDetailsLogs from './JobDetailsLogs'
import ResourceIcon from '../../../../components/Icons/ResourceIcon'

const Label = styled.div`
  color: ${({$variant, theme}) =>
    $variant === 'error'
      ? theme.palette.red[500]
      : theme.palette.neutral[900]};
`

const TabComponent = ({
  active,
  type,
  value,
  label,
  variant = 'default',
}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    <Label $variant={variant}>{label}</Label>
    {value !== undefined && (
      <CountMiniature
        value={value}
        active={active}
        type={type}
      />
    )}
  </div>
)

const iconMap = {
  'kubernetes-pod': 'kubernetes',
  'spark-submit-process': 'spark',
  'yarn-container': 'hadoop',
}

const WorkloadsJobDetailsView = () => {
  const [tab, setTab] = useState()

  const getJobFuture = useFuture(getJob)

  const navigate = useNavigate()

  const data = useSelector(
    pathOr(EMPTY_OBJECT, [
      'workloads',
      'data',
      'job',
      'response',
    ])
  )

  const {
    jobName: name,
    jobId: id,
    jobType: type,
    totalRequests,
    jobConfig = EMPTY_ARRAY,
    tags = EMPTY_ARRAY,
    args = EMPTY_ARRAY,
    error,
    state,
    startTime,
    endTime,
    clusterType,
  } = data

  useEffect(() => {
    dispatch(initJob())
  }, [])

  const {jobId} = useParams()

  const dispatch = useDispatch()

  const apiUrl = process.env.REACT_APP_API_URL

  const baseUrl = getBaseUrl()

  const url = useMemo(
    () =>
      `${baseUrl}${apiUrl}/truly/job/info?jobIds=${jobId}`,
    [baseUrl, apiUrl, jobId]
  )

  const [volatileJobs, setVolatileJobs] =
    useState(EMPTY_ARRAY)

  const [tempJob, setTempJob] = useState()

  useEffect(() => {
    if (tempJob) {
      const result = pipe(
        reject(({jobId}) => jobId === tempJob?.jobId),
        append(tempJob)
      )(volatileJobs)
      setVolatileJobs(result)
      setTempJob()
    }
  }, [tempJob, volatileJobs])

  useSSE(
    url,
    [
      {
        name: 'message',
        handler: (event) => {
          const job = JSON.parse(event.data)
          setTempJob(job)
        },
      },
    ],
    []
  )

  const volatileStatus = useMemo(
    () => pipe(path([0, 'state']))(volatileJobs),
    [volatileJobs]
  )

  useEffect(() => {
    if (jobId) {
      fork((e) => {})((a) => {
        const {
          clusterId,
          clusterName,
          groupId,
          groupName,
          scheduleId,
          scheduleName,
          jobId,
          jobName,
        } = a
        if (groupId) {
          dispatch(
            setBreadCrumbs({
              cluster: {
                id: clusterId,
                name: clusterName,
              },
              group: {
                id: groupId,
                name: groupName,
              },
              job: {
                id: jobId,
                name: jobName,
                active: true,
              },
            })
          )
        } else if (scheduleId) {
          dispatch(
            setBreadCrumbs({
              cluster: {
                id: clusterId,
                name: clusterName,
              },
              schedule: {
                id: scheduleId,
                name: scheduleName,
              },
              job: {
                id: jobId,
                name: jobName,
                active: true,
              },
            })
          )
        } else {
          dispatch(
            setBreadCrumbs({
              cluster: {
                id: clusterId,
                name: clusterName,
              },
              job: {
                id: jobId,
                name: jobName,
                active: true,
              },
            })
          )
        }
      })(
        getJobFuture({
          params: [{key: 'id', value: jobId}],
        })
      )
    }
  }, [jobId, type])

  const {createNotification} = useNotificationContext()

  const killJobFuture = useFuture(killJob)

  const deleteJobFuture = useFuture(deleteJob)

  const handleOnKill = useCallback(({id}) => {
    pipe(
      and(
        getJobFuture({
          params: [{key: 'id', value: id}],
        })
      ),
      fork(({message}) => {
        createNotification({
          message: 'Failed to kill Job: ' + message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          message: 'Job killed successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      killJobFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnDelete = useCallback(({id}) => {
    pipe(
      fork(({message}) => {
        createNotification({
          message: 'Failed to deleted Job: ' + message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        navigate('/workloads/list/jobs')
        createNotification({
          message: 'Job deleted successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteJobFuture({
        params: [{key: 'id', value: id}],
      })
    )
  }, [])

  const handleOnClone = useCallback(({id}) => {
    const params = new URLSearchParams({
      template: id,
    }).toString()
    navigate(`/workloads/create/job?${params}`)
    createNotification({
      title: 'Warning',
      message:
        'Please, go to "Resources" and attach files again',
      variant: 'warning',
      autoHide: true,
    })
  }, [])

  const pending = usePending(
    ['workloads', 'data'],
    ['job', 'killJob', 'deleteJob']
  )

  const requestTab =
    type === 'SINGLE'
      ? []
      : [
          {
            id: 'requests',
            Component: TabComponent,
            componentProps: {
              type: 'request',
              label: 'Requests',
              value: totalRequests,
            },
          },
        ]

  const topContent = (
    <DetailsHeader
      name={name}
      icon={<ResourceIcon type={'job'} />}
      id={id}
      data={[
        {
          key: 'type',
          value: type,
        },
        {
          key: 'start time',
          value: startTime,
          Component: DateCellRow,
        },
        {
          key: 'end time',
          value: endTime,
          Component: DateCellRow,
        },
        {
          key: 'State',
          value: volatileStatus || state,
          Component: JobState,
        },
      ]}
    />
  )

  const parameters = useMemo(
    () =>
      pipe(convertObjectToArrayFn('key', 'value'))(
        jobConfig
      ),
    [jobConfig]
  )

  const extendedTags = useMemo(
    () =>
      pipe(convertObjectToArrayFn('key', 'value'))(tags),
    [tags]
  )

  const extendedArguments = useMemo(
    () =>
      pipe(
        mapWithKey((value, index) => ({key: index, value}))
      )(args),
    [args]
  )

  const errorTab = error
    ? [
        {
          id: 'error',
          Component: TabComponent,
          componentProps: {
            label: 'Error',
            variant: 'error',
          },
        },
      ]
    : []

  const memory = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'memorySettings'),
        convertObjectToArrayFn('key', 'value')
      )(data),
    [data]
  )

  const tabs = [
    {
      id: 'overview',
      Component: TabComponent,
      componentProps: {
        label: 'Overview',
      },
    },
    {
      id: 'lineage',
      Component: TabComponent,
      componentProps: {
        type: 'default',
        label: 'Lineage',
      },
    },
    {
      id: 'timeline',
      Component: TabComponent,
      componentProps: {
        label: 'Timeline',
      },
    },
    {
      id: 'executors',
      Component: TabComponent,
      componentProps: {
        label: 'Executors',
      },
    },
    {
      id: 'logs',
      Component: TabComponent,
      componentProps: {
        label: 'Logs',
      },
    },
    {
      id: 'parameters',
      Component: TabComponent,
      componentProps: {
        type: 'default',
        label: 'Parameters',
        value: parameters.length,
      },
    },
    {
      id: 'arguments',
      Component: TabComponent,
      componentProps: {
        type: 'default',
        label: 'Arguments',
        value: args.length,
      },
    },
    {
      id: 'tags',
      Component: TabComponent,
      componentProps: {
        type: 'default',
        label: 'Tags',
        value: extendedTags.length,
      },
    },
    {
      id: 'memory',
      Component: TabComponent,
      componentProps: {
        type: 'default',
        label: 'Memory',
        value: memory.length,
      },
    },
    ...requestTab,
    ...errorTab,
  ]

  const firstTab = useMemo(() => tabs[0].id, [tabs])

  useEffect(() => {
    setTab(firstTab)
  }, [firstTab])

  useEffect(() => {
    if (tab === undefined) {
      setTab(firstTab)
    }
  }, [tab])

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs value={tab} options={tabs} onChange={setTab} />
      <JobButtons
        pending={pending}
        state={state}
        clusterType={clusterType}
        jobId={jobId}
        onKill={handleOnKill}
        onDelete={handleOnDelete}
        onClone={handleOnClone}
        onSpark={() => {}}
        onYarn={() => {}}
      />
    </div>
  )

  const bottomContent = (
    <SlideTransition trigger={tab} duration={150}>
      {tab === 'requests' && (
        <JobDetailsRequestsList jobId={jobId} />
      )}
      {tab === 'overview' && <JobOverview />}
      {tab === 'logs' && <JobDetailsLogs />}
      {tab === 'timeline' && <JobDetailsTimelineView />}
      {tab === 'executors' && <JobDetailsExecutors />}
      {tab === 'tags' && <JobTags data={extendedTags} />}
      {tab === 'parameters' && (
        <JobParameters data={parameters} />
      )}
      {tab === 'arguments' && (
        <JobArguments data={extendedArguments} />
      )}
      {tab === 'error' && <JobError />}
      {tab === 'lineage' && <JobLineage />}
      {tab === 'memory' && (
        <JobDetailsMemory data={memory} />
      )}
    </SlideTransition>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default WorkloadsJobDetailsView
