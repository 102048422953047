import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {isNil, isNotNil, map, path} from 'ramda'
import EditorArea from './EditorArea'
import EditorBottomControls from './EditorBottomControls'
import {Icon, Button} from 'frontcore'
import RButton from '../../../components/RButton'
import {
  removeQueriesBar,
  sidebarChooseQuery,
} from '../../../reducers/sql/sidebar'
import {useMemo} from 'react'

const Root = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;

  & .cm-editor {
    outline: none !important;
  }

  display: flex;
  flex-direction: column;
`

const EditorContainer = styled.div`
  flex: 1;
  min-width: 0;
  min-height: 0;
  position: relative;
`

const NoQuerySelected = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  user-select: none;
`

const Editor = () => {
  const dispatch = useDispatch()

  const tab = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenQuery'])
  )

  const queriesBar = useSelector(
    path(['sqlViewer', 'sidebar', 'queriesBar'])
  )

  const queries = useSelector(
    path(['sqlViewer', 'sidebar', 'queries'])
  )

  const chosenQuery = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenQuery'])
  )

  const extendedQueriesBar = useMemo(() => {
    return queriesBar
      .map((queryId) => {
        return queries.find((query) => query.id === queryId)
      })
      .filter(isNotNil)
  }, [queries, queriesBar])

  if (isNil(tab)) {
    return (
      <Root>
        <NoQuerySelected>
          Please, select an existing query or create a new
          one
        </NoQuerySelected>
      </Root>
    )
  }

  return (
    <Root>
      <div style={{display: 'flex', overflow: 'hidden'}}>
        {map(
          ({name, id}) => (
            <RButton
              icon={'database'}
              name={name}
              active={id === chosenQuery}
              onClick={() =>
                dispatch(sidebarChooseQuery(id))
              }
            >
              <Button
                square={true}
                variant={'text'}
                tabIndex={-1}
                size={'small'}
                justifyContent={'center'}
                Component={Icon}
                componentProps={{icon: 'close', size: 18}}
                onClick={(event) => {
                  event.stopPropagation()
                  dispatch(removeQueriesBar(id))
                }}
              />
            </RButton>
          ),
          extendedQueriesBar
        )}
      </div>
      <EditorContainer>
        <EditorBottomControls />
        <EditorArea />
      </EditorContainer>
    </Root>
  )
}

export default Editor
