import {
  map,
  path,
  pathOr,
  pick,
  pipe,
  propOr,
  toPairs,
} from 'ramda'
import React, {useEffect, useMemo} from 'react'
import {Tabs} from 'frontcore'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useSelector} from 'react-redux'
import DetailsInfo from '../../WorkloadsView/detailsInfo'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../constants'
import getMetaStore from '../../../api/table/getMetaStore'
import Configuration from '../../../components/configuration'
import NoResultFound from '../../../components/NoResultFound'
import {convertObjectToArray, isNotEmpty} from '../../../helpers'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'

const MetaStoreDetails = () => {
  const getMetaStoreFuture = useFuture(getMetaStore)
  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )

  const {name, kind} = chosenItem

  const details = useSelector(
    pathOr(EMPTY_ARRAY, [
      'data',
      'data',
      'metaStore',
      'response',
    ])
  )

  useEffect(() => {
    fork(() => {})(() => {})(
      getMetaStoreFuture({
        params: [
          {key: 'metaStoreId', value: chosenItem.id},
        ],
      })
    )
  }, [chosenItem])

  const extendedDetailsData = pipe(
    pick([
      'name',
      'id',
      'address',
      'type',
      'version',
      'description',
    ]),
    toPairs,
    map((a) => ({key: a[0], value: a[1]}))
  )(details)

  const tabsContent = (
    <Tabs
      value={1}
      options={[
        {
          id: 1,
          children: 'Configuration',
        },
      ]}
    />
  )

  const configurationData = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'configuration'),
        a => convertObjectToArray(a, 'key', 'value')

      )(details),
    [details]
  )

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'metastore'} />}
      name={name}
      id={kind}
      pending={false}
      data={extendedDetailsData}
    />

  )

  const listContent = isNotEmpty(configurationData) ? (
    <div style={{overflow: 'hidden', padding: 16}}>
      <Configuration
        data={configurationData}
        schema={[
          {
            id: 'key',
            label: 'Key',
            copy: true,
          },
          {
            id: 'value',
            label: 'Value',
            copy: true,
            obfuscate: true,
          },
        ]}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <NoResultFound
        title={'No Data'}
        text={'This Metastore have no configuration'}
      />
    </div>
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={tabsContent}
      bottomContent={listContent}
    />
  )
}

export default MetaStoreDetails
