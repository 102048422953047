import {createAsyncThunk} from '@reduxjs/toolkit'
import {
  assocPath,
  dissocPath,
  identity,
  pipe,
  reduce,
  startsWith,
} from 'ramda'
import {
  calculateBody,
  calculateHeaders,
  calculateUrl,
} from './helpers'
import {EMPTY_OBJECT} from '../constants'

const asyncThunk = ({
  type,
  query,
  method = 'GET',
  mock,
  variables = EMPTY_OBJECT,
  transform = identity,
  path = '',
  api = true,
  lineage = false,
}) =>
  createAsyncThunk(
    type,
    (args) =>
      new Promise((resolve, reject) => {
        const body = calculateBody(
          args,
          method,
          variables,
          query
        )
        const url = calculateUrl(
          args,
          path,
          method,
          api,
          lineage
        )
        const headers = calculateHeaders(args)

        if (mock) {
          resolve(mock)
        } else {
          fetch(url, {
            method,
            headers,
            body,
          })
            .then((res) => {
              const contentDisposition = res.headers.get(
                'content-disposition'
              )
              const status = res.status.toString()

              if (startsWith('5', status)) {
                return res.text().then((message) => {
                  return {
                    errors: `${res.status}: ${message}`,
                  }
                })
              } else if (startsWith('4', status)) {
                return res
                  .json()
                  .then(({message = 'No Message'}) => {
                    return {
                      errors: `${res.status}: ${message}`,
                    }
                  })
              }
              if (contentDisposition) {
                return {
                  content: res,
                  filename: contentDisposition,
                }
              } else {
                try {
                  return res.text()
                } catch (error) {
                  return {}
                }
              }
            })
            .then((result) => {
              if (result.errors) {
                return result
              } else {
                try {
                  return result ? JSON.parse(result) : {}
                } catch (e) {
                  return result
                }
              }
            })
            .then((result) => {
              if (result?.errors) {
                return reject(new Error(result.errors))
              } else {
                return resolve(transform(result))
              }
            })
            .catch((error) => {
              return reject(new Error(error))
            })
        }
      })
  )
const defaultFn = () => (state) => state

export const extraReducersMapper =
  (calls = []) =>
  (builder) =>
    reduce(
      (
        accumulator,
        {
          asyncThunk,
          name,
          pendingFn,
          fulfilledFn = defaultFn,
          rejectedFn = defaultFn,
        }
      ) =>
        accumulator
          .addCase(asyncThunk.pending, (state) =>
            pipe(
              assocPath(['data', name, 'pending'], true),
              assocPath(['data', name, 'error'], false),
              dissocPath(['data', name, 'reject']),
              (state) =>
                pendingFn ? pendingFn(state) : state
            )(state)
          )
          .addCase(
            asyncThunk.fulfilled,
            (state, {payload, meta}) =>
              pipe(
                assocPath(
                  ['data', name, 'response'],
                  payload
                ),
                assocPath(['data', name, 'pending'], false),
                fulfilledFn(payload, meta)
              )(state)
          )
          .addCase(
            asyncThunk.rejected,
            (state, {payload}) =>
              pipe(
                dissocPath(['data', name, 'response']),
                assocPath(
                  ['data', name, 'reject'],
                  payload
                ),
                assocPath(['data', name, 'pending'], false),
                assocPath(['data', name, 'error'], true),
                rejectedFn(payload)
              )(state)
          ),
      builder,
      calls
    )

export default asyncThunk
