import DropDownInput from '../../components/dropDownInput'
import {Button, Icon} from 'frontcore'
import {
  EMPTY_ARRAY,
  EMPTY_FUNCTION,
  EMPTY_STRING,
} from '../../constants'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {useSelector} from 'react-redux'
import {
  always,
  cond,
  isEmpty,
  pathOr,
  pipe,
  propOr,
  T,
  take,
  toLower,
} from 'ramda'
import useDebounce from '../../hooks/useDebounce'
import {useNavigate} from 'react-router-dom'
import {mapWithKey} from '../../helpers'
import NoItemsFound from '../../components/NoResultFound'

import {fork} from 'fluture'
import useFuture from '../../hooks/useFuture'
import getSearch from '../../api/lineage/getSearch'
import {LetterMiniature} from '../../components/miniatures'
import {formatUrlParam} from './helpers'
import RButton from '../../components/RButton'
import StyledLink from '../../components/StyledLink'

const labelMap = {
  JOB: 'job',
  DATASET: 'dataset',
}

const colorLineageMap = {
  JOB: 'gear',
  DATASET: 'database',
}

export const ButtonWrapper = (a) => {
  const {type, name, id, pending, onClick, namespace} = a
  const navigate = useNavigate()

  return (
    <StyledLink
      to={`/ilum-lineage/table-graph/${toLower(type)}/${formatUrlParam(name)}/${formatUrlParam(namespace)}`}
    >
      <RButton
        name={name || id}
        type={type}
        IconComponent={LetterMiniature}
        iconComponentProps={{
          type: labelMap[type],
          icon: colorLineageMap[type],
        }}
      />
    </StyledLink>
  )
}

const ContentComponent = ({
  closeFn,
  data,
  pending,
  search = EMPTY_STRING,
}) => {
  const navigate = useNavigate()

  const handleOnClick = useCallback((p) => {
    closeFn()
  }, [])

  const clusters = () => (
    <div
      style={{
        width: 212,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {mapWithKey(
        (props, index) => (
          <ButtonWrapper
            key={index}
            {...props}
            pending={pending}
            onClick={handleOnClick}
          />
        ),
        data
      )}
    </div>
  )

  const result2 = cond([
    [
      always(isEmpty(search)),
      always(
        <NoItemsFound
          title={'Empty Phrase'}
          text="You have not provided any phrase for the search"
        />
      ),
    ],
    [always(isEmpty(data)), always(<NoItemsFound />)],
    [
      T,
      always(
        <div
          style={{
            width: 212,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {clusters({closeFn})}
        </div>
      ),
    ],
  ])()

  const result = isEmpty(data) ? (
    <NoItemsFound
      title={'Empty Phrase'}
      text="You have not provided any phrase for the search"
    />
  ) : (
    <div
      style={{
        width: 212,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      {clusters({closeFn})}
    </div>
  )

  return (
    <div
      style={{
        userSelect: pending ? 'none' : 'auto',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        padding: 4,
      }}
    >
      {result2}
    </div>
  )
}

const LineageSearch = () => {
  const [search, setSearch] = useState(EMPTY_STRING)

  const data = useSelector(
    pathOr({}, ['lineage', 'data', 'search'])
  )

  const pending = useMemo(
    () => propOr(false, 'pending', data),
    [data]
  )

  const extendedData = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, ['response', 'results']),
        take(20)
      )(data),
    [data]
  )

  const getSearchFuture = useFuture(getSearch)

  useEffect(() => {
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getSearchFuture({
        params: [{key: 'text', value: search}],
      })
    )
  }, [search])

  const debouncedSearch = useDebounce(search, 500)

  return (
    <DropDownInput
      inputProps={{
        topLabelProps: {
          children: 'Lineage Global Search',
        },
        placeHolder: 'Global Search',
        StartComponent: Icon,
        startComponentProps: {size: 16, icon: 'search'},
        EndComponent: Button,
        paddingRight: '4px',
        endComponentProps: {
          square: true,
          size: 'small',
          onClick: (event) => {
            event.stopPropagation()
            setSearch(EMPTY_STRING)
          },
          variant: 'text',
          justifyContent: 'center',
          tabIndex: -1,
          Component: Icon,
          componentProps: {
            size: 16,
            icon: 'trash',
          },
        },
      }}
      value={search}
      onChange={setSearch}
      ContentComponent={ContentComponent}
      contentProps={{
        data: extendedData,
        pending,
        search,
      }}
    />
  )
}

export default LineageSearch
