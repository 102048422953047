import {useDispatch, useSelector} from 'react-redux'
import React, {useCallback} from 'react'
import {path, pipe, propEq, when} from 'ramda'
import {propsMap} from '../../helpers'
import {setChosenItem} from '../../../../reducers/dataExplorer/flatTree'
import {BreadCrumbs, Icon} from 'frontcore'
import styled from 'styled-components'
import RButton from '../../../../components/RButton'

const Root = styled.div`
  cursor: default;
  padding-left: 4px;
  padding-right: 8px;
  &:hover {
    &:hover {
      background-color: ${({theme}) =>
        theme.palette.neutral[100]};
    }
  }
`

const BreadCrumbButton = (props) => {
  const {onClick, data} = props

  const {item, active} = data

  const {id, name, kind} = item

  const extendedProps = propsMap[kind]

  const handleOnClick = useCallback(() => {
    onClick(data)
  }, [data, onClick])

  return (
    <RButton
      icon={extendedProps.icon}
      name={name}
      onClick={handleOnClick}
      type={kind}
    />
  )
}

const calculateMetaStoreBreadCrumbsData = (item) => [
  {item, active: true},
]

const calculateCatalogBreadCrumbsData = ({
  id,
  name,
  kind,
  metaStoreId,
  metaStoreName,
}) => {
  const metaStore = {
    item: {
      id: metaStoreId,
      name: metaStoreName,
      kind: 'metastore',
    },
    active: false,
  }
  const catalog = {
    item: {
      id,
      name,
      kind,
      metaStoreId,
      metaStoreName,
    },
    active: true,
  }
  return [metaStore, catalog]
}

const calculateDatabaseBreadCrumbsData = (a) => {
  const {
    id,
    name,
    kind,
    metaStoreName,
    metaStoreId,
    catalogId,
    catalogName,
  } = a

  const metaStore = {
    item: {
      id: metaStoreId,
      name: metaStoreName,
      kind: 'metastore',
    },
    active: false,
  }

  const catalog = {
    item: {
      id: catalogId,
      name: catalogName,
      kind: 'catalog',
      metaStoreId,
      metaStoreName,
    },
    active: false,
  }
  const database = {
    item: {
      id,
      name,
      kind,
      metaStoreId,
      metaStoreName,
      catalogId,
      catalogName,
    },
    active: true,
  }
  return [metaStore, catalog, database]
}

const calculateTableBreadCrumbsData = (a) => {
  const {
    id,
    name,
    kind,
    catalogId,
    catalogName,
    databaseId,
    databaseName,
    metaStoreName,
    metaStoreId,
  } = a

  const metaStore = {
    item: {
      id: metaStoreId,
      name: metaStoreName,
      kind: 'metastore',
    },
    active: false,
  }

  const catalog = {
    item: {
      id: catalogId,
      name: catalogName,
      kind: 'catalog',
      metaStoreId,
      metaStoreName,
    },
    active: false,
  }
  const database = {
    item: {
      id: databaseId,
      name: databaseName,
      kind: 'database',
      metaStoreId,
      metaStoreName,
      catalogId,
      catalogName,
    },
    active: true,
  }
  const table = {
    item: {
      id,
      name,
      kind,
      metaStoreId,
      metaStoreName,
      catalogId,
      catalogName,
      databaseId,
      databaseName,
    },
    active: true,
  }
  return [metaStore, catalog, database, table]
}

const ExplorerBreadcrumbs = () => {
  const chosenItem = useSelector(
    path(['flatTree', 'chosenItem'])
  )
  const data = pipe(
    when(
      propEq('metastore', 'kind'),
      calculateMetaStoreBreadCrumbsData
    ),
    when(
      propEq('catalog', 'kind'),
      calculateCatalogBreadCrumbsData
    ),
    when(
      propEq('database', 'kind'),
      calculateDatabaseBreadCrumbsData
    ),
    when(
      propEq('table', 'kind'),
      calculateTableBreadCrumbsData
    )
  )(chosenItem)

  const dispatch = useDispatch()

  const handleOnClick = useCallback(({item}) => {
    dispatch(setChosenItem(item))
  }, [])

  return (
    <BreadCrumbs
      data={data || []}
      onClick={handleOnClick}
      ItemComponent={BreadCrumbButton}
      itemComponentProps={{}}
      SeparatorComponent={() => <div>/</div>}
    />
  )
}

export default ExplorerBreadcrumbs
