import {GraphicWalker} from '@kanaries/graphic-walker'
import {VegaTheme} from '@kanaries/graphic-walker/vis/theme'
import {fromPairs, map, path, pipe, zip} from 'ramda'
import {memo, useCallback, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import styled, {css} from 'styled-components'
import mapField from './typeMapper'
import {Button} from 'frontcore'

const Root = styled.div`
  z-index: 10000;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  ${({ $expand }) => $expand && css`
    position: fixed;
  `}
  background-color: white;
`

const ExpandContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 16px;
  z-index: 10001;
`

const Stretch = styled.div`
  &, & > * {
    width: 100%;
    height: 100%;
  }
`

/*
 * Graphic Walker field has the following properties:
 * fid
 * name
 * analyticType
 * semanticType
 * dataType
 */

const EmbeddedWalker = () => {
  const {columns, rows} = useSelector(
    path([
      'sqlViewer',
      'results',
      'data',
      'result',
      'response',
    ])
  )

  const walkerFields = useMemo(() => {
    return columns.map(mapField)
  }, [columns])

  const walkerData = useMemo(() => {
    const fields = map(({name}) => name, columns)
    return map(pipe(zip(fields), fromPairs), rows)
  }, [columns, rows])

  const [expand, setExpand] = useState(false)
  const toggleExpand = useCallback(() => {
    setExpand(value => !value)
  }, [])

  return (
    <Root $expand={expand}>
      <ExpandContainer>
        <Button
          variant='outlined'
          size='small'
          onClick={toggleExpand}
        >{expand ? 'Collapse' : 'Expand'}</Button>
      </ExpandContainer>
      <Stretch>
        <GraphicWalker
          data={walkerData}
          fields={walkerFields}
          vizThemeConfig={VegaTheme}
          darkMode="light"
        />
      </Stretch>
    </Root>
  )
}

export default memo(EmbeddedWalker)
