import Form from '../../../../../components/form';
import FormLayout from './formLayout';
import React from 'react';
import {Button, Icon} from 'frontcore';
import CodeEditor from '../../../../../components/codeEditor';

const schema = () => {
  return {
    fields: [
      {
        section: 1,
        id: 'code',
        defaultValue: '',
        name: 'Code',
        Component: CodeEditor,
        componentProps: {
          topLabelProps: {
            children: 'Class',
          },
          fullWidth: true,
          autoFocus: true,
        },
        validators: [],
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          children: 'Execute',
          type: 'submit',
          color: 'primary',
        },
      },
    ],
  };
};


const ExecuteCodeForm = ({onSubmit}) => {

  return  <Form
    schema={schema}
    LayoutComponent={FormLayout}
    onSubmit={onSubmit}
  />

}

export default ExecuteCodeForm;