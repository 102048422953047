import {ILUM_SUPERSET_PATH} from "../../helpers/runtimeEnv";

const SupersetView = () => {
    return (

        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
            }}
        >
            <iframe
                src={ILUM_SUPERSET_PATH}
                style={{ height: "100%", width: "100%", border: "none" }}
            />
        </div>
    );
};

SupersetView.dispayName = "SupersetView";

export default SupersetView;
