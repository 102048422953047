import {Resizer} from 'frontcore'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  overflow: hidden;
  //align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
    padding-bottom: 8px;
`

const HorizontalLayout = ({
  LogoComponent,
  ButtonsComponent,
  UserComponent,
}) => {
  return (
    <Resizer>
      {({width}) => {
        const expanded = width > 200
        return (
          <Root>
            <div style={{overflow: 'hidden',padding: '20px 10px'}}>
              <LogoComponent expanded={expanded}/>
            </div>
            <div style={{gap: 8, display: 'flex', flexDirection: 'column'}}>
              <ButtonsComponent expanded={expanded} configuration={{orientation: 'right_top'}}/>
            </div>
            <div style={{flex: 1}} />

              <UserComponent expanded={expanded}/>

          </Root>
        )
      }}
    </Resizer>
  )
}

export default HorizontalLayout
