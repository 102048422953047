import {useDispatch} from 'react-redux';
import {encaseP} from 'fluture';
import {startsWith} from 'ramda';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from 'react-oidc-context';

const useFuture = (fn) => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const {removeUser} = auth;

  const navigate = useNavigate();

  const {pathname} = useLocation();

  return (args) =>
    encaseP(
      () =>
        new Promise((resolve, reject) =>
          dispatch(fn(args))
            .unwrap()
            .then((originalPromiseResult) => {
              resolve(originalPromiseResult);
            })
            .catch((rejectedValueOrSerializedError) => {
              const {message} = rejectedValueOrSerializedError;
              if (startsWith('401', message)) {
                if(pathname === '/') {
                  localStorage.setItem('cachedUrl', '/workloads/list/services');
                } else if (pathname !== '/login') {
                  localStorage.setItem('cachedUrl', pathname);
                }
                removeUser();
                navigate('/login');
                localStorage.removeItem('token');
              }
              reject(rejectedValueOrSerializedError);
            }),
        ),
    )();
};

export default useFuture;
