import {memo, useCallback} from 'react'
import StatusIndicator from '../../../components/StatusIndicator'
import {useDispatch, useSelector} from 'react-redux'
import {sidebarChooseQuery} from '../../../reducers/sql/sidebar'
import {path} from 'ramda'
import ToolTip from '../../../components/toolTip'
import TooltipContainer, {
  Key,
  Value,
} from '../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout'
import RButton from '../../../components/RButton'

const Query = ({id, local, name, search}) => {
  const dispatch = useDispatch()

  const handlePickQuery = useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(sidebarChooseQuery(id))
    },
    [id]
  )

  const chosenQuery = useSelector(
    path(['sqlViewer', 'sidebar', 'chosenQuery'])
  )

  const possibleError = useSelector(
    path(['sqlViewer', 'editor', 'statementErrors', id])
  )

  return (
    <ToolTip
      config={{position: 'right_bottom'}}
      content={
        <TooltipContainer>
          <Key>ID</Key>
          <Value>{id}</Value>
          {local && (
            <>
              <Key>Local</Key>
              <Value>Will be lost after page reload</Value>
            </>
          )}
          {possibleError && (
            <>
              <Key>Syntax Error</Key>
              <Value>{possibleError.message}</Value>
            </>
          )}
        </TooltipContainer>
      }
    >
      <RButton
        icon={'database'}
        name={name}
        active={chosenQuery === id}
        onClick={handlePickQuery}
      >
        {possibleError && (
          <StatusIndicator variant="error" value="ERROR" />
        )}
        {local && (
          <StatusIndicator
            variant="information"
            value="LOCAL"
          />
        )}
        {!local && (
          <StatusIndicator
            variant="default"
            value="REMOTE"
          />
        )}
      </RButton>
    </ToolTip>
  )
}

export default memo(Query)
