import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {join, pathOr} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import getGroup from '../../../../api/security/getGroup'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import DetailsHeader from '../../../../components/DetailsHeader'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import ReactJson from 'react-json-view'
import LabelIcon from '../../../../components/Icons/LabelIcon'
import {Tabs} from 'frontcore'
import {CountMiniature} from '../../../../components/miniatures'
import GroupSingleButtons from '../../Components/GroupButtons/GroupSingleButtons'

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

const SecurityGroupDetailsView = () => {
  const getGroupFuture = useFuture(getGroup)

  const {groupId, clusterName} = useParams()

  const dispatch = useDispatch()

  const group = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'group',
      'response',
    ])
  )

  const {
    name,
    users,
    operations,
    enabled,
    roles,
    lastActivity,
    permissions,
    creationTime,
  } = group

  useEffect(() => {
    if (groupId) {
      fork(() => {})(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getGroupFuture({
          params: [{key: 'id', value: groupId}],
        })
      )
    }
  }, [groupId])

  const topContent = (
    <DetailsHeader
      pending={false}
      icon={
        <LabelIcon color={'#6474a8'} size={'large'}>
          {name}
        </LabelIcon>
      }
      data={[
        // {
        //   key: 'description',
        //   value: description,
        // },
        {
          key: 'last Activity',
          value: lastActivity,
          Component: DateCellRow,
        },
        {
          key: 'creation Time',
          value: creationTime,
          Component: DateCellRow,
        },
        {
          key: 'users',
          value: users,
        },
        {
          key: 'operations',
          value: operations,
        },
        {
          key: 'enabled',
          value: enabled,
          Component: ({value = false}) => value.toString(),
        },
        {
          key: 'roles',
          value: roles,
          Component: ({value}) => value && join(' ', value),
        },

        {
          key: 'permissions',
          value: permissions,
          Component: ({value}) => value && join(' ', value),
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={'permissions'}
        options={[
          {
            id: 'permissions',
            Component: TabComponent,
            componentProps: {
              label: 'Permissions',
            },
          },
        ]}
      />
      <GroupSingleButtons
        texts={true}
        getFuture={getGroupFuture({
          params: [{key: 'id', value: groupId}],
        })}
        pending={false}
        data={group}
        redirect={false}
      />
    </div>
  )
  const bottomContent = <ReactJson src={group} />

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityGroupDetailsView
