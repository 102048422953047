import {createSlice} from '@reduxjs/toolkit';
import {assoc, assocPath, lensPath, pipe, set, times} from 'ramda'
import {extraReducersMapper} from '../../api';
import {EMPTY_OBJECT} from '../../constants';
import getServices from '../../api/workloads/services/getServices';
import getJobs from '../../api/workloads/jobs/getJobs';
import getClusters from '../../api/workloads/clusters/getClusters';
import getJob from '../../api/workloads/jobs/getJob';
import getJobResources from '../../api/workloads/jobs/getJobResources';
import getService from '../../api/workloads/services/getService';
import getRequests from '../../api/workloads/requests/getRequests';
import getRequest from '../../api/workloads/requests/getRequest';
import getCluster from '../../api/workloads/clusters/getCluster';
import getElements from '../../api/getElements';
import getJobsDetails from '../../api/workloads/jobs/getJobsDetails';
import getSparkUrl from '../../api/getSparkUrl';
import executeJob from '../../api/workloads/services/executeJob';
import getLicense from '../../api/getLicense';
import authenticate from '../../api/authenticate';
import authenticateOauth2Config from '../../api/authenticateOauth2Config';
import deleteCluster from '../../api/workloads/clusters/deleteCluster';
import createCluster from '../../api/workloads/clusters/createCluster';
import deleteService from '../../api/workloads/services/deleteService';
import archiveService from '../../api/workloads/services/archiveService';
import scaleService from '../../api/workloads/services/scaleService';
import killJob from '../../api/workloads/jobs/killJob';
import deleteJob from '../../api/workloads/jobs/deleteJob';
import createService from '../../api/workloads/services/createService';
import createJob from '../../api/workloads/jobs/createJob';
import getStagesMetrics from '../../api/workloads/jobs/getStagesMetrics';
import getStagesExecutors from '../../api/workloads/jobs/getStagesExecutors';
import getClustersMetrics from '../../api/workloads/clusters/getClustersMetrics'
import getJobOverview from '../../api/workloads/jobs/getJobOverview'
import activateSchedule from '../../api/workloads/schedules/activateSchedule';
import {createSchedule} from '../../api/workloads/schedules/createSchedule';
import deleteSchedule from '../../api/workloads/schedules/deleteSchedule';
import getSchedule from '../../api/workloads/schedules/getSchedule';
import getScheduleMetricsMinor from '../../api/workloads/schedules/getScheduleMetricsMinor';
import getSchedulesMinor from '../../api/workloads/schedules/getSchedulesMinor';
import stopSchedule from '../../api/workloads/schedules/stopSchedule';
import getSchedules from '../../api/workloads/schedules/getSchedules';
import getSearch from '../../api/lineage/getSearch'
import editSchedule from '../../api/workloads/schedules/editSchedule';
import recreateCluster from '../../api/workloads/clusters/recreateCluster';
import pauseService from "../../api/workloads/services/pauseService";

const defaultData = ({
  pending: true,
  error: false,
});

const initialList = {
  page: 0,
  size: 10,
  filter: {},
  sort: {},
};

const initialState = {
  breadCrumbs: EMPTY_OBJECT,
  lists: {
    clusters: EMPTY_OBJECT,
    requests: EMPTY_OBJECT,
    services: EMPTY_OBJECT,
  },
  data: {
    services: {
      error: false,
      pending: false,
    },
  },
};

const executeJobPending = (state) => {
  return set(lensPath(['data', 'executeJob', 'response']), EMPTY_OBJECT, state);
};

export const workloadsSlice = createSlice({
  name: 'workloads',
  initialState,
  reducers: {
    init: () => {
      return initialState;
    },
    initList: (state, {payload: {name, size}}) => {
      const content = times(() => {
      }, size);
      const result = {
        pending: true,
        error: false,
        response: {
          content,
        },
      };

      return assocPath(
        ['data', name],
        result,
        state,
      );
    },
    initCluster: (state) => assocPath(['data', 'cluster'], defaultData, state),
    initService: (state) => assocPath(['data', 'service'], defaultData, state),
    initJob: (state) => assocPath(['data', 'job'], defaultData, state),
    initRequest: (state) => assocPath(['data', 'request'], defaultData, state),
    setDetails: (state, { payload }) => {
      return pipe(assoc("details", payload))(state);
    },
    setList: (state, {payload: {key, value}}) => {
      return assocPath(['lists', key], value, state);
    },
    resetList: (state, {payload: {key}}) => {
      return assocPath(['lists', key], initialList, state);
    },
    setBreadCrumbs: (state, {payload}) => {
      return assoc('breadCrumbs', payload, state);
    },
    initExecute: (state) => {
      return set(
        lensPath(['data', 'executeJob', 'response']),
        EMPTY_OBJECT,
        state,
      );
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getServices,
      name: 'services',
    },
    {
      asyncThunk: scaleService,
      name: 'scaleService',
    },
    {
      asyncThunk: getJob,
      name: 'job',
    },
    {
      asyncThunk: authenticate,
      name: 'authenticate',
    },
    {
      asyncThunk: authenticateOauth2Config,
      name: 'authenticate/oauth2',
    },
    {
      asyncThunk: getJobs,
      name: 'jobs',
    },
    {
      asyncThunk: killJob,
      name: 'killJob',
    },
    {
      asyncThunk: deleteJob,
      name: 'deleteJob',
    },

    {
      asyncThunk: getClusters,
      name: 'clusters',
    },
    {
      asyncThunk: recreateCluster,
      name: 'recreateCluster',
    },
    {
      asyncThunk: getCluster,
      name: 'cluster',
    },
    {
      asyncThunk: getSparkUrl,
      name: 'sparkUrl',
    },
    {
      asyncThunk: getRequests,
      name: 'requests',
    },
    {
      asyncThunk: getLicense,
      name: 'getLicense',
    },
    {
      asyncThunk: getRequest,
      name: 'request',
    },
    {
      asyncThunk: createCluster,
      name: 'createCluster',
    },
    {
      asyncThunk: createJob,
      name: 'createJob',
    },
    {
      asyncThunk: createService,
      name: 'createService',
    },
    {
      asyncThunk: deleteCluster,
      name: 'deleteCluster',
    },
    {
      asyncThunk: executeJob,
      name: 'executeJob',
      pendingFn: executeJobPending,
    },
    {
      asyncThunk: getService,
      name: 'service',
    },
    {
      asyncThunk: pauseService,
      name: 'pauseService',
    },
    {
      asyncThunk: getJobResources,
      name: 'jobResources',
    },
    {
      asyncThunk: getElements,
      name: 'elements',
    },
    {
      asyncThunk: deleteService,
      name: 'deleteService',
    },
    {
      asyncThunk: archiveService,
      name: 'archiveService',
    },
    {
      asyncThunk: getStagesMetrics,
      name: 'stagesMetrics',
    },
    {
      asyncThunk: getStagesExecutors,
      name: 'stagesExecutors',
    },
    {
      asyncThunk: getClustersMetrics,
      name: 'clustersMetrics',
    },
    {
      asyncThunk: getJobOverview,
      name: 'jobOverview',
    },
    {
      asyncThunk: getSchedules,
      name: 'schedules',
    },
    {
      asyncThunk: activateSchedule,
      name: 'activateSchedule',
    },
    {
      asyncThunk: createSchedule,
      name: 'createSchedule',
    },
    {
      asyncThunk: deleteSchedule,
      name: 'deleteSchedule',
    },
    {
      asyncThunk: getSchedule,
      name: 'schedule',
    },
    {
      asyncThunk: getScheduleMetricsMinor,
      name: 'scheduleMetrics',
    },
    {
      asyncThunk: getSchedulesMinor,
      name: 'schedulesMinor',
    },
    {
      asyncThunk: stopSchedule,
      name: 'stopSchedule',
    },

    {
      asyncThunk: getSearch,
      name: 'lineageJobs',
    },
    {
      asyncThunk: editSchedule,
      name: 'editSchedule',
    },
  ]),
});

export const {
  setDetails,
  setList,
  resetList,
  setBreadCrumbs,
  initList,
  initExecute,
  initCluster,
  initService,
  initJob,
  initRequest,
} = workloadsSlice.actions;

export default workloadsSlice.reducer;
