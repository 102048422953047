import {Icon, VirtualizedList} from 'frontcore'
import {path} from 'ramda'
import {memo, useMemo} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import DownloadResults from '../DownloadResults'
import Field from './Field'

const Root = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
`

const SchemaRow = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  & > * + * {
    border-left: 1px solid white;
  }
`

const SchemaField = styled.div`
  box-sizing: border-box;
  min-width: 200px;
  max-width: 200px;
  user-select: none;
  font-size: 14px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 10px;
  }
  cursor: pointer;
  transition: 0.1s background-color ease-in-out;
  background-color: #eee;
  border: 1px solid #eee;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  &:hover {
    background-color: #ddd;
    border: 1px solid #ddd;
  }
`;

const Row = styled.div`
  height: 40px;
  display: flex;
`

const ListContainer = styled.div`
  flex: 1;
  &, * {
    scrollbar-width: none;
  }
`

const DataList = () => {
  const {columns, rows} = useSelector(path(['sqlViewer', 'results', 'data', 'result', 'response']))

  const rowsWrapped = useMemo(() => {
    return rows.map(fields => Object.freeze({ fields }))
  }, [rows])

  return (
    <Root>
      <div style={{ minWidth: 200 * columns.length }}>
        <SchemaRow>
          {columns.map(({name}, index) => (
            <SchemaField key={index}>
              <Icon icon='table' size={16} />
              <span>{name}</span>
            </SchemaField>
          ))}
        </SchemaRow>
      </div>
      <ListContainer style={{ minWidth: 200 * columns.length }}>
        <VirtualizedList
          gap={0}
          items={rowsWrapped}
          itemHeight={40}
          ItemComponent={RowComponent}
        />
      </ListContainer>
      <DownloadResults />
    </Root>
  )
}

export default memo(DataList)

const RowComponent = memo(({ fields }) => {
  return (
    <Row>
      {fields.map((field, index) => (
        <Field key={index}>
          {String(field)}
        </Field>
      ))}
    </Row>
  )
})
