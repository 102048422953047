import ClusterMetrics from '../../clusterMetrics';
import React from 'react';
import ToolTipDetailsGridContentLayout, {
  Key,
  Row,
  Value,
} from '../../../../components/layouts/toolTip/ToolTipDetailsGridContentLayout';

const ToolTipContentComponent = ({data}) => (
  <ToolTipDetailsGridContentLayout>
      <Key>Name</Key>
      <Value> {data?.requestId}</Value>
      <Key>Class</Key>
      <Value>{data?.clazz}</Value>
      <Key>Cluster</Key>
      <Value> {data?.clusterName}</Value>
      <Row>
        <ClusterMetrics name={data?.clusterName} />
      </Row>
  </ToolTipDetailsGridContentLayout>
);

export default ToolTipContentComponent;
