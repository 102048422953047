import Line from '../../components/line'
import {
  BreadCrumbs,
  Button,
} from 'frontcore'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useFetch from '../../hooks/useFetch'
import {
  append,
  assoc,
  equals,
  findIndex,
  isEmpty,
  map,
  omit,
  path,
  pipe,
  propOr,
  sort,
  startsWith,
  take,
  toLower,
  toPairs,
  trim,
} from 'ramda'
import getBucketContentFetch from '../../api/file/getBucketContentFetch'
import getFolderContentFetch from '../../api/file/getFolderContentFetch'
import getRootContentFetch from '../../api/file/getRootContentFetch'
import getClusterContentFetch from '../../api/file/getClusterContentFetch'
import getStoragesContentFetch from '../../api/file/getStoragesContentFetch'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  EMPTY_STRING,
} from '../../constants'
import styled from 'styled-components'
import {useNotificationContext} from '../../hooks/useNotificationsContext'
import List from './components/List'
import Grid from './components/Grid'
import Empty from './components/Empty'
import Pending from './components/Pending'
import InMemorySearch from './components/InMemorySearch'
import {useSelector} from 'react-redux'
import ErrorLayout from './components/ErrorLayout'
import RButton from '../../components/RButton'
import {LetterMiniature} from '../../components/miniatures'
import DetailsHeader from '../../components/DetailsHeader'
import ResourceIcon from '../../components/Icons/ResourceIcon'

const options = Object.freeze({
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  shouldRetryOnError: false,
  refreshInterval: 0,
})

const propsMap = {
  root: {
    label: 'Root'
  },
  cluster: {
    IconComponent: LetterMiniature,
    iconComponentProps: {type: 'cluster'}
  },
  storage: {
    label: 'Storage',
    icon: 'server'
  },
  bucket: {
    label: 'Bucket',
    icon: 'trash'
  },
  folder: {
    icon: 'directory'
  },
  file: {
    icon: 'project'
  }
}

const BreadCrumbButton = (props) => {
  const {data} = props

  const {id, type, active, name} = data

  const extendedData = propsMap[type]

  return (
    <div>
      <RButton
        name={name || id}
        {...extendedData}
        type={type}
      />
    </div>
  )
}

const fetchMap = {
  root: () => ({
    api: getRootContentFetch,
    params: {},
  }),
  cluster: ({id}) => ({
    api: getClusterContentFetch,
    params: {
      params: [{key: 'clusterId', value: id}],
    },
  }),
  storage: ({clusterId, id}) => ({
    api: getStoragesContentFetch,
    params: {
      params: [
        {key: 'clusterId', value: clusterId},
        {key: 'storageId', value: id},
      ],
    },
  }),
  bucket: ({clusterId, storageId, id}) => ({
    api: getBucketContentFetch,
    params: {
      params: [
        {key: 'clusterId', value: clusterId},
        {key: 'storageId', value: storageId},
        {key: 'bucketId', value: id},
      ],
    },
  }),
  folder: ({clusterId, storageId, bucketId, id}) => ({
    api: getFolderContentFetch,
    params: {
      params: [
        {key: 'clusterId', value: clusterId},
        {key: 'storageId', value: storageId},
        {key: 'bucketId', value: bucketId},
        {key: 'id', value: id},
      ],
    },
  }),
}

const ordnanceMap = Object.freeze({
  cluster: 0,
  storage: 1,
  bucket: 2,
  folder: 3,
  file: 4,
})

const componentMap = Object.freeze({
  list: List,
  grid: Grid,
  empty: Empty,
  error: ErrorLayout,
  pending: Pending,
})

const FileExplorerView = () => {
  const [selected, setSelected] = useState()

  const {createNotification} = useNotificationContext()

  const [breadCrumbs, setBreadCrumbs] = useState([])

  const type = propOr('root', 'type', selected)

  const {api, params} = useMemo(() => {
    return pipe(
      propOr(() => EMPTY_OBJECT, type),
      (apiSelector) => apiSelector(selected)
    )(fetchMap)
  }, [type, selected])

  const handleOnClick = useCallback(
    (item) => {
      setSelected(item)
      const result = append(item, breadCrumbs)
      setBreadCrumbs(result)
    },
    [breadCrumbs]
  )

  const handleOnClickBreadCrumbs = useCallback(
    (item) => {

      const extendedItem = omit(['active'], item)

      const index = pipe(
        findIndex((a) => equals(a, extendedItem))
      )(breadCrumbs)

      const result = take(index + 1, breadCrumbs)
      setBreadCrumbs(result)
      setSelected(item)
    },
    [breadCrumbs]
  )

  useEffect(() => {
    if (isEmpty(breadCrumbs)) {
      handleOnClick({
        type: 'root',
        name: 'root',
        id: 'root',
      })
    }
  }, [])

  const extendedBreadCrumbs = useMemo(
    () =>
      pipe(
        map((item) => {
          const active = equals(item, selected)
          return assoc('active', active, item)
        })
      )(breadCrumbs),
    [selected, breadCrumbs]
  )

  const [view, setView] = useState('list')

  const detailsData = pipe(
    omit(['name', 'id', 'type', 'onClick']),
    toPairs,
    map((a) => ({key: a[0], value: a[1]}))
  )(selected)

  const {
    data = EMPTY_ARRAY,
    error,
    isLoading,
  } = useFetch(api, options, params)

  useEffect(() => {
    if (error?.message) {
      createNotification({
        message: error?.message,
        autoHide: false,
        variant: 'error',
      })
    }
  }, [error?.message])

  const search = useSelector(
    path(['fileExplorer', 'search'])
  )
    .trim()
    .toLowerCase()

  const searchCompare = useCallback(
    pipe(
      propOr(EMPTY_STRING, 'name'),
      trim,
      toLower,
      startsWith(search)
    ),
    [search]
  )

  const extendedData = useMemo(() => {
    return sort((a, b) => {
      let aOrdnance = ordnanceMap[a?.type]
      let bOrdnance = ordnanceMap[b?.type]
      if (searchCompare(a)) {
        aOrdnance -= 50
      }
      if (searchCompare(b)) {
        bOrdnance -= 50
      }
      return aOrdnance - bOrdnance
    }, data)
  }, [data, searchCompare])

  const extendedView = useMemo(() => {
    if (isLoading) {
      return 'pending'
    } else if (error) {
      return 'error'
    } else if (isEmpty(data)) {
      return 'empty'
    } else {
      return view
    }
  }, [error, data, view, isLoading])

  const Component = useMemo(
    () => componentMap[extendedView],
    [extendedView]
  )

  return (
    <Root>
      <BreadCrumbsContainer>
        <BreadCrumbs
          data={extendedBreadCrumbs}
          ItemComponent={BreadCrumbButton}
          onClick={handleOnClickBreadCrumbs}
          SeparatorComponent={() => <div>/</div>}
        />
      </BreadCrumbsContainer>
      <DetailsContainer>
        <DetailsHeader
          icon={<ResourceIcon type={selected?.type} />}
          name={selected?.name}
          id={selected?.id}
          pending={false}
          data={detailsData}
        />
      </DetailsContainer>
      <Line />
      <ButtonsContainer>
        <InMemorySearch />
        <div style={{display: 'flex', gap: 8}}>
          <Button
            tabIndex={-1}
            active={view === 'list'}
            onClick={() => setView('list')}
            variant={'text'}
          >
            List
          </Button>
          <Button
            tabIndex={-1}
            active={view === 'grid'}
            onClick={() => setView('grid')}
            variant={'text'}
          >
            Grid
          </Button>
        </div>
      </ButtonsContainer>
      <Line />
      <DataContainer>
        <Component
          data={extendedData}
          onClick={handleOnClick}
        />
      </DataContainer>
    </Root>
  )
}

export default FileExplorerView

const Root = styled.div`
  box-sizing: border-box;
  height: 100%;
    width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BreadCrumbsContainer = styled.div`
  padding: 16px;
`

const DetailsContainer = styled.div`
  padding: 0 16px 16px 16px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`

const DataContainer = styled.div`
  flex: 1;
`
