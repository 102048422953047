import React, {useCallback} from 'react'
import styled from 'styled-components'
import {LetterMiniature} from '../../../components/miniatures'
import ToolTip from '../../../components/toolTip'
import ToolTipContent from './ToolTipContent'

import {Icon} from 'frontcore'

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
    color: ${({theme}) => theme.palette.neutral[800]};
`

const iconMap = {
  cluster: {
    Component: LetterMiniature,
    componentProps: {type: 'cluster'},
  },

  storage: {
    Component: Icon,
    componentProps: {
      icon: 'server',
      size: 20,
    },
  },
  bucket: {
    Component: Icon,
    componentProps: {
      icon: 'trash',
      size: 20,
    },
  },

  folder: {
    Component: Icon,
    componentProps: {
      icon: 'directory',
      size: 20,
    },
  },
  file: {
    Component: Icon,
    componentProps: {
      icon: 'project',
      size: 20,
    },
  },
}

const GridItem = (props) => {
  const {data, onClick} = props
  const {type, name} = data
  const {Component, componentProps} = iconMap[type]

  const handleOnClick = useCallback(
    (value) => {
      if (type !== 'file') {
        onClick(value)
      }
    },
    [type]
  )
  return (
    <ToolTip content={<ToolTipContent item={data} />}>
      <Root onClick={handleOnClick}>
        <div style={{width: 24, height: 24}}>
          {Component && <Component {...componentProps} />}
        </div>
        <NameContainer>{name}</NameContainer>
      </Root>
    </ToolTip>
  )
}

export default GridItem

const Root = styled.div`
  cursor: default;
  width: 192px;
  padding: 8px;
  display: flex;
  overflow: hidden;
  gap: 8px;
  align-items: center;

  &:hover {
    background: ${({theme}) => theme.palette.neutral[100]};
  }
`
