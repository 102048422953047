import styled from 'styled-components'
import {createTransitions} from '../../helpers'

const Root = styled.div`
    width: 36px;
    height: 20px;
    background:${({$value,theme}) => ($value ? theme.palette.green[400] : theme.palette.neutral[400])};
    display: flex;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: ${createTransitions(['background'])};
`

const Spacer = styled.div`
  flex: ${({$value}) => ($value ? 1 : 0)};
    transition: ${createTransitions(['flex'])};
`

const Switch = ({value = true, onClick}) => {
  return (
    <Root $value={value} onClick={() => onClick(!value)}>
      <Spacer $value={value} />
      <div
        style={{
          height: 14,
          width: 14,
          background: 'white',
          borderRadius: '50%',
        }}
      ></div>
    </Root>
  )
}

export default Switch
