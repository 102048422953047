import {Button, Input, TextArea} from 'frontcore'
import Form from '../../../../../components/form'
import React, {memo} from 'react'
import JobFormLayout from './jobFormLayout'
import ToolTip from '../../../../../components/toolTip'

const schema = (data, options) => {
  const {language} = options

  const placeHolderMap = {
    SCALA:
      'Full class name of the job (e.g. org.apache.spark.examples.SparkPi)',
    PYTHON:
      'File and class name (e.g., load_data_to_bronze.RawDataToBronze)',
  }

  return {
    fields: [
      {
        section: 1,
        id: 'clazz',
        defaultValue: '',
        name: 'Name',
        Component: Input,
        componentProps: {
          topLabelProps: {
            children: 'Class',
          },
          placeHolder: placeHolderMap[language],
          fullWidth: true,
          autoFocus: true,
        },
        validators: [],
      },
      {
        section: 1,
        id: 'config',
        defaultValue: '{\n\n}',
        name: 'Name',
        Component: TextArea,
        componentProps: {
          topLabelProps: {
            children: 'Parameters',
          },
          fullWidth: true,
          autoFocus: true,
          multiLine: true,
          fullHeight: true,
        },
        validators: [
          (value) => {
            let result
            try {
              JSON.parse(value)
            } catch ({name, message}) {
              result = name + message
              return result
            }
            return ''
          },
        ],
      },
    ],
    buttons: [
      {
        id: 'submit',
        // TODO: workaround
        // To react to shift + enter,
        // i query button by id and then
        // click it by calling button.click() method
        Component: memo(args => (
          <ToolTip 
            content={(
              <p style={{ padding: '0 16px' }}>
                Press <b>Shift+Enter</b>
                to execute the service
              </p>
            )}
            config={{
              position: 'bottom_right',
            }}
          >
            <div id='button-execute-job-container'>
              <Button {...args}/>
            </div>
          </ToolTip>
        )),
        componentProps: {
          tabIndex: -1,
          children: 'Execute',
          type: 'submit',
          color: 'primary',
        },
      },
    ],
  }
}

const ExecuteJobForm = ({onSubmit, language}) => {
  return (
    <Form
      schema={schema}
      options={{language}}
      LayoutComponent={JobFormLayout}
      onSubmit={onSubmit}
    />
  )
}

export default ExecuteJobForm
