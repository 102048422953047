import {Tabs} from 'frontcore'
import Line from '../../../../../components/line'
import React, {useState} from 'react'
import BatchRunHistory from '../BatchRunHistory'
import Raw from '../Raw'
import {namespace} from 'd3'
import BatchLatestRun from '../BatchLatestRun'


const TabComponent = ({active, type, value, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
  </div>
)

const BatchDetails = ({namespace}) => {
  const [tab, setTab] = useState('raw')

  return (
    <>
      <div style={{padding: '8px 8px 0 8px'}}>
        <Tabs
          value={tab}
          onChange={setTab}
          options={[
            {
              id: 'latest_run',
              Component: TabComponent,
              componentProps: {
                label: 'Latest Run',
                value: 'latest_run',
              },
            },
            {
              id: 'run_history',
              Component: TabComponent,
              componentProps: {
                label: 'Run History',
                value: 'run_history',
              },
            },
            {
              id: 'raw',
              Component: TabComponent,
              componentProps: {
                label: 'Raw',
                value: 'raw',
              },
            },
          ]}
        />
      </div>
      <Line />
      <div
        style={{
          overflow: 'hidden',
          flex: 1,
        }}
      >
        {tab === 'latest_run' && <BatchLatestRun/>}
        {tab === 'run_history' && <BatchRunHistory/>}
        {tab === 'raw' && <Raw />}
      </div>
    </>
  )
}

export default BatchDetails
