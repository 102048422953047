import {isEmpty, path, pathOr, sortBy} from 'ramda'
import { memo, useMemo } from 'react'
import {useSelector} from 'react-redux'
import {EMPTY_ARRAY} from '../../../constants'
import styled from 'styled-components'
import NoResultFound from '../../../components/NoResultFound'
import {skeletonCss, VirtualizedList} from 'frontcore'
import Query from './Query'
import {QuerySelect} from '../../../reducers/sql/sidebar'

const Root = styled.div`
  flex: 1;
`

const Pending = styled.div`
  width: 100%; height: 100%;
  ${skeletonCss}
`

const SidebarQueries = () => {
  const queries = useSelector(pathOr(EMPTY_ARRAY, ['sqlViewer', 'sidebar', 'queries']))
  const pending = useSelector(path(['sqlViewer', 'sidebar', 'data', 'queries', 'pending']))

  const rawSearch = useSelector(path(['sqlViewer', 'sidebar', 'search']))
  const search = useMemo(() => {
    return rawSearch.trim().toLowerCase()
  }, [rawSearch])

  const queryType = useSelector(path(['sqlViewer', 'sidebar', 'querySelect']))

  const queriesWithSearch = useMemo(() => {
    const sortFn = sortBy(query => {
      const lowkeyName = query.name?.toLowerCase()
      return lowkeyName?.startsWith(search) ? -1 : 0
    })
    return sortFn(queries).filter(({ local }) => {
      if (queryType === QuerySelect.all) return true
      return (local && queryType === QuerySelect.local) ||
        (!local && queryType === QuerySelect.remote)
    })
  }, [queries, search, queryType])

  if (pending) return <Pending $skeleton />

  if (isEmpty(queriesWithSearch)) {
    return (
      <Root>
        <NoResultFound
          title='No Queries Found'
          text="There are no queries found. Maybe they're still pending"
        />
      </Root>
    )
  }

  return (
    <Root>
      <VirtualizedList
        items={queriesWithSearch}
        itemHeight={32}
        gap={0}
        ItemComponent={Query}
        itemComponentProps={{ search }}
      />
    </Root>
  )
}

export default memo(SidebarQueries)
