import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  flatten,
  map,
  pathOr,
  pipe,
  prop,
  startsWith,
  uniq,
} from 'ramda'
import '@xyflow/react/dist/style.css'
import './index.css'
import ElkGraph from './elkGraph'
import {
  EMPTY_ARRAY,
  HORIZONTAL,
  VERTICAL,
} from '../../../constants'
import './batch-node.css'
import React, {useCallback, useEffect, useMemo} from 'react'
import Details from './Details'

import {formatUrlParam} from '../helpers'
import {fork} from 'fluture'
import {useDispatch, useSelector} from 'react-redux'
import getTable from '../../../api/lineage/getTable'
import useFuture from '../../../hooks/useFuture'
import {setSelectedNode} from '../../../reducers/lineage'
import Line from '../../../components/line'
import DetailsInfo from '../../WorkloadsView/detailsInfo'
import {Button, Icon, Portal, ResizeLayout} from 'frontcore'
import ViewNotAvailable from '../../../components/viewNotAvailable'
import {ILUM_LINEAGE_ENABLED} from '../../../helpers/runtimeEnv'

const TableGraphView = (props) => {
  const params = useParams()
  const nodeType = props?.nodeType || params?.nodeType
  const nodeId = props?.nodeId || params?.nodeId
  const namespace = props?.namespace || params?.namespace
  const dispatch = useDispatch()

  const getTableFuture = useFuture(getTable)

  const navigate = useNavigate()
  useEffect(() => {
    fork((message) => {})(() => {})(
      getTableFuture({
        params: [
          {
            key: 'nodeType',
            value: nodeType,
          },
          {
            key: 'namespace',
            value: formatUrlParam(namespace),
          },
          {
            key: 'nodeId',
            value: formatUrlParam(nodeId),
          },
          {
            key: 'depth',
            value: 5,
          },
        ],
      })
    )
  }, [namespace, nodeType, nodeId])

  const model = useSelector(prop('lineage'))

  const extendedData = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, [
          'data',
          'table',
          'response',
          'graph',
        ])
      )(model),
    [model]
  )

  const pending = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, ['data', 'table', 'pending'])
      )(model),
    [model]
  )

  const extendedNodes = pipe(
    map(({data, id, inEdges, outEdges}) => ({
      id,
      type: data?.type,
      position: {x: 0, y: 0},
      isConnectable: false,
      data: {
        ...data,
        inEdges,
        outEdges,
        isConnectable: false,
      },
    }))
  )(extendedData)

  const extendedEdges = pipe(
    map(({inEdges, outEdges}) => {
      return [...inEdges, ...outEdges]
    }),
    flatten,
    uniq,
    map(({origin, destination}) => ({
      id: 'e' + origin + '-' + destination,
      source: origin,
      target: destination,
      type: 'step',
      animated: true,
    }))
  )(extendedData)

  const handleOnNodeClick = useCallback((event, {data}) => {
    dispatch(setSelectedNode(data))
  }, [])

  const {pathname} = useLocation()

  return ILUM_LINEAGE_ENABLED.toLowerCase() === 'true' ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: 24,
          paddingBottom: 8,
          height: 40,
          paddingLeft: 24,
          alignItems: 'center',
        }}
      >
        {startsWith('/ilum-lineage', pathname) && (
          <Button
            size={'small'}
            variant={'text'}
            StartComponent={Icon}
            startComponentProps={{
              icon: 'arrow-left',
              size: 18,
            }}
            tabIndex={-1}
            justifyContent={'center'}
            onClick={() => {
              navigate(-1)
            }}
          >
            Back to List
          </Button>
        )}
        <DetailsInfo
          title={'Table Flow'}
          skeleton={false}
          data={[
            {
              key: 'Node ID',
              value: nodeId,
            },
            {
              key: 'Node Type',
              value: nodeType,
            },
            {
              key: 'Namespace',
              value: namespace,
            },
          ]}
        />
      </div>
      <Line />
      <ResizeLayout
        configuration={{
          orientation: VERTICAL,
          firstNode: {
            measurement: 'flex',
            value: 1,
          },
          secondNode: {
            measurement: 'px',
            value: 8 * 32,
          },
        }}
        firstNode={
          !pending ? (
            <ElkGraph
              n={extendedNodes}
              e={extendedEdges}
              onNodeClick={handleOnNodeClick}
            />
          ) : (
            <div></div>
          )
        }
        secondNode={<Details namespace={namespace} />}
      />
    </div>
  ) : (
    <ViewNotAvailable name={'Lineage'} />
  )
}

export default TableGraphView
