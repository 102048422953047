import {Icon, skeletonCss} from 'frontcore'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import ToolTip from '../../../components/toolTip'
import {useSelector} from 'react-redux'
import {path} from 'ramda'
import useFuture from '../../../hooks/useFuture'
import saveSQLStatement from '../../../api/sql/saveSQLStatement'
import {fork} from 'fluture'
import {EMPTY_FUNCTION} from '../../../constants'
import styled from 'styled-components'

const Root = styled.div`
  position: relative;
  margin-right: 8px;
  height: 100%;
  padding: 0 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${skeletonCss}
`

const AUTOSAVE_TIME = 500

const EditorAutosave = () => {
  const [saved, setSaved] = useState(true)

  const chosenQuery = useSelector(path(['sqlViewer', 'sidebar', 'chosenQuery']))
  const queries = useSelector(path(['sqlViewer', 'sidebar', 'queries']))
  const queryDetails = useMemo(() => {
    return queries.find(({ id }) => {
      return id === chosenQuery
    })
  }, [chosenQuery, queries])
  const chosenEngine = useSelector(path(['sqlViewer', 'editor', 'chosenEngine']))
  const maxRows = useSelector(path(['sqlViewer', 'editor', 'maxRows']))

  const saveSQLStatementFuture = useFuture(saveSQLStatement)
  const timeout = useRef()
  useEffect(() => {
    setSaved(false)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      const future = saveSQLStatementFuture({
        params: [{ key: 'queryId', value: queryDetails.id }],
        ...queryDetails,
        engine: chosenEngine,
        maxRows,
      })
      fork(EMPTY_FUNCTION)(() => {
        setSaved(true)
      })(future)
    }, AUTOSAVE_TIME)
  }, [queryDetails, chosenEngine, maxRows])

  return (
    <ToolTip config={{ position: 'bottom_right' }} content={
      <div style={{ padding: 8 }}>
        {saved ? 'Saved automatically' : 'Not saved remotely'}
      </div>
    }>
      <Root $skeleton={!saved}>
        <Icon
          icon={saved ? 'success' : 'error-contained'}
          color={saved ? 'green' : 'red'}
          size={24}
        />
      </Root>
    </ToolTip>
  )
}

export default memo(EditorAutosave)
