import {createSlice} from '@reduxjs/toolkit'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  EMPTY_STRING,
} from '../../constants'
import {extraReducersMapper} from '../../api'
import {
  append,
  assoc,
  evolve,
  mergeLeft,
  path,
  pipe,
  reject,
  uniq,
} from 'ramda'

import getSQLStatements from '../../api/sql/getSQLStatements'
import getKyuubiTables from '../../api/sql/getTables'

const INITIAL_DATA = Object.freeze({
  response: null,
  pending: true,
  error: false,
})

export const SidebarTabs = Object.freeze({
  queries: 'Queries',
  resources: 'Resources',
})

export const QuerySelect = Object.freeze({
  all: 'All',
  local: 'Local',
  remote: 'Remote',
})

const initialState = Object.freeze({
  chosenCluster: null,
  tab: SidebarTabs.queries,
  search: EMPTY_STRING,
  querySelect: QuerySelect.all,
  queries: EMPTY_ARRAY,
  queriesBar: EMPTY_ARRAY,
  chosenQuery: null,
  schemas: EMPTY_OBJECT,
  data: {
    tables: INITIAL_DATA,
    queries: INITIAL_DATA,
  },
})

const createEmptyQuery = () => {
  return Object.freeze({
    id: `local:${Date.now()}`,
    local: true,
    name: `Query ${Date.now() % 1e3}`,
    statement: EMPTY_STRING,
  })
}

const sidebarSlice = createSlice({
  name: 'sql/sidebar',
  initialState,
  reducers: {
    sidebarSetCluster: (state, { payload }) => 
      assoc('chosenCluster', payload, state),
    sidebarSetTab: (state, {payload}) =>
      assoc('tab', payload, state),
    sidebarSetSearch: (state, {payload}) =>
      assoc('search', payload, state),
    sidebarSetQuerySelect: (state, {payload}) =>
      assoc('querySelect', payload, state),
    sidebarNewQuery: (state, {payload}) => {
      let localQuery = createEmptyQuery()
      if (payload) {
        localQuery = mergeLeft(payload, localQuery)
      }
      state.queries.unshift(localQuery)
      state.chosenQuery = localQuery.id
    },
    sidebarChooseQuery: (state, {payload}) => {
      return pipe(
        assoc('chosenQuery', payload),
        evolve({queriesBar: pipe(append(payload), uniq)})
      )(state)
    },

    sidebarMutateQuery: (state, {payload}) => {
      state.queries = state.queries.map((query) => {
        if (query.id !== payload.id) return query
        return mergeLeft(payload, query)
      })
    },
    removeQueriesBar: (state, {payload}) =>
      evolve(
        {
          queriesBar: reject((id) => id === payload),
          chosenQuery: () => path(['queriesBar', 0], state),
        },
        state
      )
    ,
    sidebarRemoveQuery: (state, {payload}) =>
      evolve(
        {
          queries: reject(({id}) => id === payload),
          queriesBar: reject((id) => id === payload),
          // BUG:
          // chosenQuery: () => path(['queriesBar', 0], state),
          chosenQuery: () => null,
        },
        state
      ),
    sidebarQueryMakeRemote: (state, {payload}) => {
      const {localId, remote} = payload
      const updatedQueries = state.queries.map((query) => {
        if (query.id !== localId) return query
        return mergeLeft({...remote, local: false}, query)
      })
      return {
        ...state,
        queries: updatedQueries,
        chosenQuery: remote.id,
      }
    },
    sidebarSetSchema: (state, {payload}) => {
      const {table, schema} = payload
      state.schemas[table] = schema
    },
  },
  extraReducers: extraReducersMapper([
    {
      asyncThunk: getKyuubiTables,
      name: 'tables',
    },
    {
      asyncThunk: getSQLStatements,
      name: 'queries',
      fulfilledFn: (payload) => (state) => {
        const localQueries = state.queries.filter(
          ({local}) => local
        )
        const remoteQueries = payload.content.map((query) =>
          Object.freeze({
            ...query,
            local: false,
          })
        )

        return {
          ...state,
          queries: [...localQueries, ...remoteQueries],
        }
      },
    },
  ]),
})

export default sidebarSlice.reducer
export const {
  removeQueriesBar,
  sidebarSetCluster,
  sidebarSetTab,
  sidebarSetSearch,
  sidebarSetQuerySelect,
  sidebarNewQuery,
  sidebarChooseQuery,
  sidebarMutateQuery,
  sidebarQueryMakeRemote,
  sidebarRemoveQuery,
  sidebarSetSchema,
} = sidebarSlice.actions
