import {ILUM_JUPYTER_PATH} from '../../helpers/runtimeEnv'

const NotebookView = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <iframe
        src={ILUM_JUPYTER_PATH}
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
        }}
      />
    </div>
  )
}

NotebookView.dispayName = 'NotebookView'

export default NotebookView
