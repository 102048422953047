import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect} from 'react'
import DetailsHeader from '../../../../components/DetailsHeader'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {fork} from 'fluture'
import getUser from '../../../../api/security/getUser'
import {useParams} from 'react-router-dom'
import {join, pathOr, toUpper} from 'ramda'
import {EMPTY_OBJECT} from '../../../../constants'
import ReactJson from 'react-json-view'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import UserIcon from '../../../../components/Icons/UserIcon'
import {Tabs} from 'frontcore'
import GroupSingleButtons from '../../Components/GroupButtons/GroupSingleButtons'
import UserSingleButtons from '../../Components/UserButtons/UserSingleButtons'
import {CountMiniature} from '../../../../components/miniatures'

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

const SecurityUserDetailsView = () => {
  const getUserFuture = useFuture(getUser)

  const {userId, clusterName} = useParams()

  const dispatch = useDispatch()

  const user = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'user',
      'response',
    ])
  )

  const {
    id,
    username,
    email,
    fullname,
    description,
    department,
    lastTimeCreated,
    enabled,
    roles,
    groups,
    permissions,
  } = user

  useEffect(() => {
    if (userId) {
      fork(() => {})(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getUserFuture({
          params: [{key: 'id', value: userId}],
        })
      )
    }
  }, [userId])

  const topContent = (
    <DetailsHeader
      icon={
        <UserIcon
          color={'#64a866'}
          size={'large'}
        >
          {toUpper(username?.substring(0, 1) || '')}
        </UserIcon>
      }
      type={email}
      name={username}
      id={id}
      pending={false}
      data={[
        {
          key: 'fullname',
          value: fullname,
        },
        {
          key: 'department',
          value: department,
        },
        {
          key: 'last Time Created',
          value: lastTimeCreated,
          Component: DateCellRow,
        },
        {
          key: 'enabled',
          value: enabled,
          Component: ({value = false}) => value.toString(),
        },
        {
          key: 'roles',
          value: roles,
          Component: ({value}) => value && join(' ', value),
        },
        {
          key: 'groups',
          value: groups,
          Component: ({value}) => value && join(' ', value),
        },
        {
          key: 'permissions',
          value: permissions,
          Component: ({value}) => value && join(' ', value),
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={'permissions'}
        options={[
          {
            id: 'permissions',
            Component: TabComponent,
            componentProps: {
              label: 'Permissions',
            },
          },
        ]}
      />
      <UserSingleButtons
        texts={true}
        getFuture={getUserFuture({
          params: [{key: 'id', value: userId}],
        })}
        pending={false}
        data={user}
        redirect={false}
      />
    </div>
  )
  const bottomContent = <ReactJson src={user} />

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityUserDetailsView
