import {isNil, path} from 'ramda'
import styled from 'styled-components'
import {memo} from 'react'
import {useSelector} from 'react-redux'
import skeletonCss from '../../../styles/skeletonCss'
import ModeTabs from './ModeTabs'
import DataList from './modes/DataList'
import EmbeddedWalker from './modes/EmbeddedWalker'
import Logs from './modes/Logs'
import NoResultFound from '../../../components/NoResultFound'
import {Modes} from '../../../reducers/sql/results'
import Statistics from './modes/Statistics'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; height: 100%;
  ${skeletonCss}
`

const InnerContainer = styled.div`
  border-top: 1px solid #ddd;
  flex: 1;
  position: relative;
`

const Centered = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogsContainer = styled.div`
  padding: 16px;
  overflow: auto;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    margin-bottom: 8px;
    color: red;
  }
  p {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
    font-family: monospace;
  }
`

const Results = () => {
  const {response, pending, error} = useSelector(path(['sqlViewer', 'results', 'data', 'result']))
  const errorMessage = useSelector(path(['sqlViewer', 'results', 'errorMessage']))
  const mode = useSelector(path(['sqlViewer', 'results', 'mode']))

  return (
    <Root $skeleton={pending}>
    { error && (
      <LogsContainer>
        <h1>What went wrong:</h1>
        <p>
          {errorMessage}
        </p>
      </LogsContainer>
    )}
    { !error && isNil(response) && (
      <Centered>
        <NoResultFound
          title='No results found'
          text='Most likely, your query failed. Please, try again!'
        />
      </Centered>
    ) }
    { response && !pending && (
      <>
        <ModeTabs />
        <InnerContainer>
          {mode === Modes.list && <DataList />}
          {mode === Modes.walker && <EmbeddedWalker />}
          {mode === Modes.stats && <Statistics />}
          {mode === Modes.logs && <Logs />}
        </InnerContainer>
      </>
    ) }
    </Root>
  )
}

export default memo(Results)
