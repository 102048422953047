import {useListRefreshFuture} from '../hooks'
import ListLayout from '../../../../components/layouts/listLayout'
import Filters from '../../../WorkloadsView/filters'
import React from 'react'
import ActivitiesList from './ActivitiesList'
import getActivities from '../../../../api/security/getActivities'
import usePending from "../../../../hooks/usePending";

const SecurityActivitiesListView = () => {
    const listHandler = useListRefreshFuture(
        getActivities,
        'security',
        'activities'
    )

    const pending = usePending(
        ['security', 'data'],
        ['activities']
    )


    return (
        <ListLayout
            batchButtonsNode={<div></div>}
            filtersNode={
                <Filters
                    name={'groups'}
                    schema={[]}
                    pending={pending}
                />
            }
            contentNode={
                <ActivitiesList
                    pending={pending}
                    listHandler={listHandler}
                />
            }
        />
    )
}

export default SecurityActivitiesListView
