import {useNavigate} from 'react-router-dom'
import FormLayout2 from '../../../../components/tabsFormLayout'
import React, {useCallback} from 'react'
import Form from '../../../../components/form'
import schema from './schema'
import useFuture from '../../../../hooks/useFuture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {fork} from 'fluture'
import {createRole} from '../../../../api/security/createRole'
import useFetch from '../../../../hooks/useFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import getPermissionsFetch from '../../../../api/security/getPermissionsFetch'
import {assoc, evolve, map, pipe, prop} from 'ramda'

const SecurityCreateRoleView = () => {
  const navigate = useNavigate()

  const createRoleFuture = useFuture(createRole)

  const { createNotification } = useNotificationContext()

  const {data: permissions} = useFetch(
    getPermissionsFetch,
    singleCallFetchOptions,
  )

  const handleOnSubmit = useCallback((fields) => {

    const result = pipe(
      evolve({
        permissions: map(prop('name')),

      }),
      assoc('state', 'ENABLED')
    )(fields)
    fork(({message}) => {
      createNotification({
        message,
        autoHide: false,
        variant: 'error',
      })
    })(() => {
      navigate(-1)
      createNotification({
        message: 'Role created successfully',
        autoHide: true,
        variant: 'success',
      })
    })(createRoleFuture(result))
  }, [])

  return <Form
    autocomplete={'off'}
    options={{
      pending: false,
      navigate,
      permissions
    }}
    LayoutComponent={FormLayout2}
    layoutComponentProps={{
      title: 'NEW ROLE',
    }}
    schema={schema}
    onSubmit={handleOnSubmit}
  />
}

export default SecurityCreateRoleView;