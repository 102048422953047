import Form from '../../../../components/form'
import TabsFormLayout from '../../../../components/tabsFormLayout'
import schema from './schema'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import {useNavigate, useParams} from 'react-router-dom'
import usePending from '../../../../hooks/usePending'
import {EMPTY_OBJECT} from '../../../../constants'
import {fork} from 'fluture'
import {
  evolve,
  forEach,
  isNil,
  pipe,
  reduce,
  toLower,
  toPairs,
} from 'ramda'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {useDispatch} from 'react-redux'

import getService from '../../../../api/workloads/services/getService'
import nestMemorySettings from '../../memorySettings/nesting'
import {convertObjectToArray, isArray} from '../../../../helpers'
import editService from '../../../../api/workloads/services/editService'

const WorkloadsEditServiceView = () => {
  const editServiceFuture = useFuture(editService)
  const {createNotification} = useNotificationContext()

  const {serviceId} = useParams()
  const navigate = useNavigate()

  const pending = usePending(
    ['workloads', 'data'],
    ['createService', 'getService']
  )

  const dispatch = useDispatch()

  const [data, setData] = useState(EMPTY_OBJECT)

  const getServiceFuture = useFuture(getService)

  useEffect(() => {
    fork(() => {})((data = EMPTY_OBJECT) => {
      const result = evolve(
        {
          language: toLower,
          kind: toLower,
          tags: (object) => convertObjectToArray(object, 'key', 'value'),
          jobConfig: (object) => convertObjectToArray(object, 'key', 'value'),
        },
        data
      )
      setData(result)
      dispatch(
        setBreadCrumbs({
          group: {
            id: data.id,
            name: data.name,
          },
        })
      )
    })(
      getServiceFuture({
        params: [{key: 'id', value: serviceId}],
      })
    )
  }, [serviceId])

  const handleOnSubmit = useCallback((params) => {
    const result = pipe(
      nestMemorySettings,
      toPairs,
      reduce((acc, [fieldName, fieldValue]) => {
        if (isNil(fieldValue)) {
          return acc
        }
        if (isArray(fieldValue)) {
          forEach(([key, value]) => {
            acc.append(fieldName, key, value)
          }, fieldValue)
        } else {
          acc.append(fieldName, fieldValue)
        }
        return acc
      }, new FormData())
    )(params)
    result.params = [{ key: 'id', value: serviceId }]
    fork(({message}) => {
      createNotification({
        title: 'Failed to Edit Service',
        message,
        autoHide: false,
        variant: 'error',
      })
    })(() => {
      navigate(-1)
      createNotification({
        message: 'Service edited successfully',
        autoHide: true,
        variant: 'success',
      })
    })(editServiceFuture(result))
  }, [serviceId])

  return (
    <Form
      options={{pending, navigate}}
      LayoutComponent={TabsFormLayout}
      schema={schema}
      initialData={data}
      onSubmit={handleOnSubmit}
      layoutComponentProps={{
        title: 'EDIT SERVICE',
      }}
    />
  )
}

export default WorkloadsEditServiceView
