import React, {memo, useEffect} from 'react'
import ExplorerWindow from './explorer'
import {VirtualizedList, ResizeLayout} from 'frontcore'
import {
  EMPTY_ARRAY,
  EMPTY_FUNCTION,
  HORIZONTAL,
} from '../../constants'
import Line from '../../components/line'
import ListButton from './ListButton'
import {useSelector} from 'react-redux'
import {isEmpty, pathOr} from 'ramda'
import useFuture from '../../hooks/useFuture'
import getClusters from '../../api/data/getClusters'
import {fork} from 'fluture'
import MenuHeader from './flatTree/MenuHeader'
import {flattenTree} from './helpers'
import getMetaStores from '../../api/table/getMetaStores'
import {ILUM_SQL_ENABLED} from '../../helpers/runtimeEnv'
import ViewNotAvailable from '../../components/viewNotAvailable'

const TableExplorerView = () => {
  const getMetaStoresFuture = useFuture(getMetaStores)

  const getClustersFuture = useFuture(getClusters)

  const tree = useSelector(
    pathOr(EMPTY_ARRAY, ['data', 'tree'])
  )

  const data = flattenTree(tree)

  useEffect(() => {
    if (!isEmpty(data)) {
      return
    }
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getMetaStoresFuture({})
    )
  }, [])

  useEffect(() => {
    fork(EMPTY_FUNCTION)(EMPTY_FUNCTION)(
      getClustersFuture({})
    )
  }, [])

  return (
    <div
      style={{
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <ResizeLayout
          configuration={{
            orientation: HORIZONTAL,
            firstNode: {
              measurement: 'px',
              value: 32 * 8,
              max: 32 * 16,
            },
            secondNode: {
              measurement: 'flex',
              value: 1,
            },
          }}
          firstNode={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <MenuHeader />
              <Line />
              <div style={{display: 'flex', flex: 1}}>
                <VirtualizedList
                  items={data}
                  itemHeight={32}
                  gap={0}
                  ItemComponent={ListButton}
                />
              </div>
            </div>
          }
          secondNode={<ExplorerWindow />}
        />
      </div>
    </div>
  )
}

export default memo(() => {
  return ILUM_SQL_ENABLED?.toLowerCase() === 'true' ? (
    <TableExplorerView />
  ) : (
    <ViewNotAvailable name={'Data Explorer'} />
  )
})
